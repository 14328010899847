import { useCallback, useContext } from 'react'

import { ProductType } from '../catalog/products/ProductType'
import { StoreDispatchContext } from '../../../services/storeService/storeService'
import { LanguageContext } from '../../../language/LanguageContext'

export default function Counter({product}: {product: ProductType}) {

    const { t } = useContext(LanguageContext)

    const { addToCart, getProductQuantity } = useContext(StoreDispatchContext)
    const add = useCallback(() => {
        addToCart(product, 1)
    },[])
    const del = useCallback(() => {
        addToCart(product, -1)
    },[])

    const quantity = getProductQuantity(product.id)

    return (
        <>
            <button onClick={del} title={t("putAway")}>-</button>
                {quantity}
            <button onClick={add} title={t("addMore")}>+</button>
        </>
    )

}