import Modal from 'react-bootstrap/Modal'
import { memo, useContext, useEffect, useState } from 'react'

import FileUploadForm from './form'
import AttachIcon from '../icons/attachIcon'
import Success from '../../../pageElements/Success/success'
import UpdateIcon from '../../icons/updateIcon'
import { Pa321Context, Pa321DispatchContext } from '../../../../services/pa321Service/pa321Service'
import { LanguageContext } from '../../../../language/LanguageContext'

type FileUploadProps = {
    caseId: number,
    messageId?: string,
    text?: string,
    doAfter?: () => void,
}

function FileUpload({ caseId, messageId, text, doAfter }: FileUploadProps) {

    const { t } = useContext(LanguageContext)

    const [isOpened, setIsOpened] = useState<boolean>(false)

    const { getCase, resetStatus } = useContext(Pa321DispatchContext)
    const state = useContext(Pa321Context)
    const { status } = state.file

    const wait = process.env.REACT_APP_WHEEL_WAIT_S ? parseInt(process.env.REACT_APP_WHEEL_WAIT_S) : 3

    const closeModal = () => {
        setIsOpened(false)
    }

    useEffect(() => {
        if (isOpened&&status !== "idle") {
            resetStatus("file")
        }
    }, [isOpened])
    
    useEffect(() => {
        if (status === "success") {
            setTimeout(() => {
                resetStatus("file")
                closeModal()
                getCase(caseId)
                if (typeof doAfter === "function") doAfter()
            }, wait * 1000)
        }
    }, [status])

    return (
        <>
            <button title={t("attachFile")} onClick={() => setIsOpened(true)}>
                <AttachIcon active={true} />
            </button>
            <Modal show={isOpened}
                onHide={closeModal}
                centered size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        {`${t("attachFile")} ${text || ""}`}
                        {status === "loading" && <UpdateIcon className="loading" />}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {status==="success" ? <Success duration={wait} /> : <FileUploadForm caseId={caseId} messageId={messageId} />}
                </Modal.Body>
            </Modal>
        </>
    )
}

export default memo(FileUpload)