import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { ChangeEvent, FormEvent, memo, useContext, useEffect, useState } from 'react'

import PlaneIcon from './planeIcon'
import { Pa321Context, Pa321DispatchContext } from '../../../services/pa321Service/pa321Service'
import { LanguageContext } from '../../../language/LanguageContext'

type MessageFormProps = {
    caseId: number,
}

type FormErrorsType = {
    message?: string[],
}

function MessageForm({ caseId }: MessageFormProps) {

    const { t } = useContext(LanguageContext)

    const { createMessage, listenMessages } = useContext(Pa321DispatchContext)
    const { messageForm } = useContext(Pa321Context)
    const { status, formErrors } = messageForm

    const [validated, setValidated] = useState<boolean>(false)

    const [message, setMessage] = useState<string>('')
    const messageMinLength = 5

    const onSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        let form = event.currentTarget
        if (form.checkValidity()) {
            setValidated(false)
            createMessage(message, caseId)
        } else {
            setValidated(true)
        }
    }

    useEffect(() => {
        if (status === "success") {
            clearForm()
            listenMessages(caseId)
        }
    }, [status])

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        setMessage(e.target.value)
    }

    const clearForm = () => {
        setMessage('')
    }

    return (
        <Form noValidate validated={validated} onSubmit={onSubmit} className="message-form" >
            <Form.Group
                className="message-form-input"
                controlId="message">
                <Form.Control
                    name="message"
                    required
                    as="textarea"
                    minLength={messageMinLength}
                    value={message}
                    autoFocus
                    onChange={onChange}
                    title={t("message")}
                    isInvalid={(formErrors && formErrors.message) ? true : false}
                />
                <Form.Control.Feedback type="invalid">
                    {formErrors?.message?.map((e: string) => t(e)).join("; ") || t("minimum_length_is", messageMinLength)}
                </Form.Control.Feedback>
            </Form.Group>
            <Button variant='light' size='lg'
                type="submit"
                style={{ maxHeight: "9vh", padding: "0 0 0 0" }}
                disabled={status === "loading"}
                title={t("send")}>
                <PlaneIcon />
            </Button>
        </Form >
    )
}

export default memo(MessageForm)