import { ReactNode, useContext } from 'react'

import { LanguageContext } from '../../language/LanguageContext'

export default function ErrorAlert({ message, className, children } : { message: string, className?: string, children?: ReactNode }) {

    const { t } = useContext(LanguageContext)

    return (
        <div className={`alert alert-danger ${className}`}>
            <p>{t(message)}</p>
            {children}
        </div>
    )
}