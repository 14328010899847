import { useNavigate } from 'react-router-dom'
import { useContext } from 'react'

import { ProductType } from '../ProductType'
import { LanguageContext } from '../../../../../language/LanguageContext'
import { slice } from '../../../../../helpers/textFormatHelpers'
import AddToCart from '../../../cart/addToCart'
import VendorLink from '../../vendors/vendorLink'

type ProductProps = {
    product: ProductType,
    view: "tile" | "inline",
}

export default function Product({ product, view }: ProductProps) {

    const { t } = useContext(LanguageContext)

    const navigate = useNavigate()
    const onClick = () => navigate(`/store/catalog/product/${product.id}`)


    const tile = ({ id, name, price, vendor, category, description, image }: ProductType) => {
        return (
            <div className="product tile">
                <div className="image" tabIndex={0} role='button' onClick={onClick}>
                    <img src={image} alt={name} />
                </div>
                <div className="info">
                    <div className="fw-bold" tabIndex={0} role='button' onClick={onClick}>{name}</div>
                    <div className="product-vendor">{t("vendor")}:&nbsp;<VendorLink id={vendor.id} name={vendor.name} /></div>
                </div>
                <div className="purchase">
                    <div>{t("price")}:&nbsp;<span className="fw-bold">{price}</span></div>
                    <AddToCart product={product} />
                </div>
            </div>
        )
    }

    const inline = ({ id, name, price, vendor, category, description, image }: ProductType) => {
        return (
            <div className="product inline">
                <div className="image" tabIndex={0} role='button' onClick={onClick}>
                    <img src={image} alt={name} />
                </div>
                <div className="info">
                    <div className="fw-bold" tabIndex={0} role='button' onClick={onClick}>{name}</div>
                    <div className="details">
                        <div className="product-vendor">{t("vendor")}:&nbsp;<VendorLink id={vendor.id} name={vendor.name} /></div>
                        <div className="description">{slice(description, 150)}</div>
                    </div>
                </div>
                <div className="purchase">
                    <div>{t("price")}:&nbsp;<span className="fw-bold">{price}</span></div>
                    <AddToCart product={product} />
                </div>

            </div>
        )
    }

    return view === "tile" ? tile(product) : inline(product)

}