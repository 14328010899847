import { ItemType } from './items/ItemType'
import { FileType } from '../files/FileType'

export enum OrderStatuses {
    new = "new",
    inwork = "inwork",
    completed = "completed",
    cancelled = "cancelled",
}

export type OrderType = {
    id: number,
    status: keyof typeof OrderStatuses,
    quantity: number,
    price: string,
    start_date: string,
    cases?: number[],
    files?: FileType[],
    items: ItemType[],
}