import { useContext } from 'react'

import { OrderType } from '../OrderType'
import ItemsList from '../items/list'
import Slash from '../../../pageElements/Slash/slash'
import Files from '../../files/modal'
import Case from '../../cases/views/modalView'
import NewCase from '../../cases/actions/new'
import { LanguageContext } from '../../../../language/LanguageContext'

type OrderProps = {
    active: boolean,
    setActive: (id: number | undefined) => void
} & OrderType

export default function Order({ id, status, quantity, price, start_date, cases, files = [], items, active, setActive }: OrderProps) {

    const { t } = useContext(LanguageContext)

    const openOrder = () => {
        if (!active) setActive(id)
    }

    return (
        <div className={`order ${active ? "active" : ""}`} onClick={openOrder} >
            <div className="order-header"
                tabIndex={0}
                onKeyDown={e => {
                    if (e.code === "Space") {
                        e.preventDefault()
                        openOrder()
                    }
                }} >
                <div className="status">
                    <div className={"ellipse-status " + status} />
                    <div>{t(status + "OrderStatus")}</div>
                </div><Slash />
                <div className="fw-bold">{id}</div><Slash />
                <div>{t("total_quantity")}: <span className="fw-bold">{quantity}</span></div><Slash />
                <div>{t("total_price")}: <span className="fw-bold">{price}</span></div><Slash />
                <div className="fst-italic muted">{start_date}</div>
            </div>
            <div className="related">
                {t("relatedCases")}: {cases?.map((caseId, i) => (<><Case key={caseId+"-"+i} id={caseId} /><Slash key={`s-${i}-${caseId}`} /></>))}
                {status !== "cancelled" && <NewCase orderId={id} className="new-related-case" text={t("newCase")} />}
            </div>
            {active &&
                <div className="order-body">
                    <div className="items">
                        <div className="status text-compact">{t("goods")}</div>
                        <ItemsList orderId={id} items={items} freeSpace={200} />
                    </div>
                    <div className="order-files">
                        <Files active={files.length > 0} files={files} text={` - ${t("order")} #${id}`} />
                    </div>
                </div>
            }
        </div>
    )
}