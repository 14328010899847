import { useParams } from 'react-router-dom'

export default function useParamsNumber() {
    let parseError: string | undefined = undefined
    const { id } = useParams()
    const parsedId = Number(id)
    if(isNaN(parsedId)){
        parseError = "invalidId"
    }
    return { id: parsedId, parseError }
}