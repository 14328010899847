const cartLabels = {
    cart: "Корзина",
    addToCart: "В корзину",
    addMore: "Еще",
    putAway: "Меньше",
    cartEmpty: "Корзина пуста",
    sum: "Сумма",
    cartItemRemoveConfirm: "Вы уверены, что хотите удалить этот элемент?",
    remove: "Удалить",
}

export default cartLabels