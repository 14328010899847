import { Link } from 'react-router-dom'
import { Modal } from 'react-bootstrap'
import { useContext, useEffect, useState } from 'react'

import { stubCase } from '../stubCase'
import { CaseType } from '../CaseType'
import Slash from '../../../pageElements/Slash/slash'
import Loader from '../../../pageElements/Loader/Loader'
import MessagesList from '../../messages/list'
import Files from '../../files/modal'
import { Pa321Context, Pa321DispatchContext } from '../../../../services/pa321Service/pa321Service'
import { LanguageContext } from '../../../../language/LanguageContext'

export default function Case({ id }: { id: number }) {

    const { t } = useContext(LanguageContext)

    const { getCase } = useContext(Pa321DispatchContext)
    const { caseItem } = useContext(Pa321Context)
    const { data } = caseItem
    const loading = caseItem.status === "loading"

    const [isOpened, setIsOpened] = useState<boolean>(false)

    useEffect(() => {
        if (isOpened) {
            getCase(id)
        }
    }, [isOpened, id])

    const view = (data: CaseType) => {
        const { id, status, start_date, subject, description, files } = data
        return (
            <div className="case" >
                <div className="case-header">
                    <div className="status">
                        <div className={"ellipse-status " + status} />
                        <div>{t(status + "CaseStatus")}</div>
                    </div><Slash />
                    <div className="text-break fw-bold">{subject}</div><Slash />
                    <div className="fst-italic muted">{start_date}</div>
                    {(typeof files !== "undefined" && files.length > 0) && <><Slash /><Files active={true} files={files} text={` - ${t("case")} #${id}`} /></>}
                    <Slash /> <Link to={`/cases/${id}`}>{t("detailView")}</Link>
                </div>
                <div className="case-content">
                    {description}
                </div>
                <MessagesList //Todo remove stub inside
                    active={isOpened}
                    caseId={id}
                    caseMessages={data.messages}
                    readonly={true}
                />
            </div>
        )
    }

    return (
        <>
            <button className="related-link" onClick={() => setIsOpened(true)} title={t("case") + " #" + id}>{id}</button>
            <Modal show={isOpened} onHide={() => setIsOpened(false)} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        {t("case")} #{data?.id}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loading ? <Loader elements={[3, 1, 1, 1]} /> :
                        (typeof data !== "undefined" ? view(data) : view(stubCase)) //todo remove stub when orders are ready
                    }
                </Modal.Body>
            </Modal>
        </>
    )
}

