import { forwardRef, useContext } from 'react'

import UpdateIcon from '../icons/updateIcon'
import CrossIcon from '../icons/crossIcon'
import { LanguageContext } from '../../../language/LanguageContext'
import { Filter } from '../filter/filter'

type OrdersPanelProps = {
    total?: number
}

const OrdersPanel = forwardRef<HTMLDivElement, OrdersPanelProps>(({ total }, ref ) => {
    const { t } = useContext(LanguageContext)

    return (
        <div className="panel" ref={ref}>
            <div className="text-center text-colored">{t("orders")} {total?total:""}</div>
            <div className="panel-item-list">
                <div className="panel-item">
                    <div>{t("cancel")}</div>
                    <CrossIcon />
                </div>                
                <Filter entity="orders" />
                <div className="panel-item">
                    <div>{t("update")}</div>
                    <UpdateIcon />
                </div>
            </div>
        </div>
    )
})

export default OrdersPanel