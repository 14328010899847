const loginLabels = {
    login: "Login",
    login_link: "Go to Login page",
    welcome: "Welcome! Log In to continue",
    password: "Password",
    password_required: "Please, enter the password",
    email: "Email",
    email_required: "Please, enter the email",
    userNotFound: "User not found",
    passwordIncorrect: "Incorrect password",
}

export default loginLabels