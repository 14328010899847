import { useContext } from 'react'

import { MessageType } from './MessageType'
import Files from '../files/modal'
import FileUpload from '../files/actions/new'
import { LanguageContext } from '../../../language/LanguageContext'
import { slice } from '../../../helpers/textFormatHelpers'
import { dateToString as d } from '../../../helpers/dateHelpers'

type MessageProps = {
    caseId: number,
    readonly: boolean,
} & MessageType


export default function Message({ id, author, created_at, message, is_for_user, is_case_message, files = [], caseId, readonly }: MessageProps) {

    const { t } = useContext(LanguageContext)
    const text = ` - ${t("toMessage")} ${slice(message, 20)}`
    return (
        <>
            <div className={`message-item ${is_for_user ? "answer" : ""}`} >
                <div className="message-header">
                    <div>{author}</div>
                    <div>{d(created_at)}</div>
                </div>
                <div className="message-body">
                    <div className="message-content">{message}</div>
                    {!readonly && !(is_for_user || is_case_message) &&
                        <FileUpload key={`file-upload-message-${id}`} caseId={caseId} messageId={id} text={text} />
                    }
                    {files.length > 0 && <Files active={true} files={files} text={text} />}
                </div>
            </div>
        </>
    )
}