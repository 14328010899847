const filterLabels = {
    applyFilter: "Apply",
    saveFilter: "Save filter configuration",
    resetFilter: "Reset",
    status: "status",   
    start_date: "start date", 
    from: "from",
    to: "to",
}

export default filterLabels