import { Outlet } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import Menu from '../components/pa321/menu/menu'

export default function Pa321Page() {

  return (
    <>
      <Helmet>
        <title>Pa321</title>
      </Helmet>
      <div className="mainPage">
        <Menu />
        <div className="content">
          <Outlet />          
        </div>
      </div>
    </>
  )
}