import Button from 'react-bootstrap/Button'
import { useContext } from 'react'

import { Pa321Context, Pa321DispatchContext } from '../../../services/pa321Service/pa321Service'
import { LanguageContext } from '../../../language/LanguageContext'

export default function DemoUser() {
    const demoEmail = process.env.REACT_APP_DEMO_EMAIL
    const demoPassword = process.env.REACT_APP_DEMO_PASSWORD

    const { t } = useContext(LanguageContext)

    const { login } = useContext(Pa321DispatchContext)
    const state = useContext(Pa321Context)
    const { status } = state.login

    if (demoEmail && demoEmail.length && demoPassword)
        return (
            <Button
                title={t("login_demoUser")}
                onClick={() => login(demoEmail, demoPassword)}
                disabled={status === "loading"}>
                {t("login_demoUser")}
            </Button>
        )

    return <></>
}