export default function OkIcon() {
    return (
        <svg
            width="3rem"
            height="3rem"
            viewBox="0 0 1024 1024"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M512 512m-448 0a448 448 0 1 0 896 0 448 448 0 1 0-896 0Z" className="path-fill-colored" />
            <path d="M738.133333 311.466667L448 601.6l-119.466667-119.466667-59.733333 59.733334 179.2 179.2 349.866667-349.866667z" fill="white" />
        </svg>
    )
}