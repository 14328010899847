export default function SearchIcon() {
  return (
    <svg
      height="32"
      viewBox="0 0 32 32"
      width="32"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.18 20.5942C15.6463 23.4244 10.4738 23.2017 7.19805 19.926C3.68333 16.4113 3.68333 10.7128 7.19805 7.19805C10.7128 3.68333 16.4113 3.68333 19.926 7.19805C23.2017 10.4738 23.4244 15.6463 20.5942 19.18L27.4684 26.0542C27.8589 26.4447 27.8589 27.0779 27.4684 27.4684C27.0779 27.8589 26.4447 27.8589 26.0542 27.4684L19.18 20.5942ZM8.61226 18.5118C5.87859 15.7781 5.87859 11.3459 8.61226 8.61226C11.3459 5.87859 15.7781 5.87859 18.5118 8.61226C21.2434 11.3439 21.2454 15.7716 18.5178 18.5057C18.5158 18.5077 18.5137 18.5097 18.5117 18.5117C18.5097 18.5137 18.5077 18.5157 18.5057 18.5178C15.7716 21.2454 11.3439 21.2434 8.61226 18.5118Z"
        fillRule="evenodd"
      />
    </svg>
  )
}