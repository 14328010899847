import { Modal } from 'react-bootstrap'
import { useContext } from 'react'

import ErrorAlert from './errorAlert'
import GoBackButton from '../pageElements/GoBackButton/goBackButton'
import { Pa321Context } from '../../services/pa321Service/pa321Service'

export default function ErrorModal() {

    const { error } = useContext(Pa321Context)

    if (!error) return <></>
    return (
        <Modal show={typeof error !== "undefined"} backdrop="static" size="lg">
            <Modal.Header>
                <Modal.Title>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ErrorAlert message={error} />
                <GoBackButton />
            </Modal.Body>
        </Modal>
    )
}