const errorLabels = {
    status500: "Внутренняя ошибка сервера",
    status404: "Страница не найдена",
    status400: "Неправильный запрос",
    status403: "Нет доступа",

    loadFileError: "Файл не может быть загружен",

    minimum_length_is: (n: number) => "Минимальная длина " + n + " симв.",

}

export default errorLabels