import { forwardRef, useContext } from 'react'

import NewCase from './actions/new'
import UpdateIcon from '../icons/updateIcon'
import { Filter } from '../filter/filter'
import { Pa321DispatchContext } from '../../../services/pa321Service/pa321Service'
import { LanguageContext } from '../../../language/LanguageContext'

type CasesPanelProps = {
    total: number,
    loading: boolean,
}

const CasesPanel = forwardRef<HTMLDivElement, CasesPanelProps>(({total, loading}, ref) => { //todo what filter do we need?
    
    const { t } = useContext(LanguageContext)
    const { getCases  } = useContext(Pa321DispatchContext)

    return (
        <div className="panel" ref={ref}>
            <div className="text-center text-colored" >
                {t("cases")} {total ? total : ""}  {" "}
                {loading && <UpdateIcon className="loading" />}                
            </div>
            <div className="panel-item-list">
               <NewCase />
                <Filter entity="cases" />
                <button className="panel-item" title={t("updateCases")} onClick={getCases}>
                    <div>{t("update")}</div>
                    <UpdateIcon />
                </button>
            </div>           
        </div>
    )
})

export default CasesPanel