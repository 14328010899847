import { useNavigate } from 'react-router-dom'
import { memo, useContext, useState } from 'react'

import { CaseType } from '../CaseType'
import Slash from '../../../pageElements/Slash/slash'
import { LanguageContext } from '../../../../language/LanguageContext'
import { slice } from '../../../../helpers/textFormatHelpers'
import { dateToString as d } from '../../../../helpers/dateHelpers'

export const Case = memo(function Case({ id, status, subject, start_date, description, order }: CaseType) {

    const { t } = useContext(LanguageContext)

    const navigate = useNavigate();

    const [active, setActive] = useState<boolean>(false)

    const maxTextLength = 256

    return (
        <div
            className={`case ${active ? "active" : ""}`}
            tabIndex={0}
            role="button"
            onClick={() => navigate(`/cases/${id}`)}
            onFocus={() => setActive(true)}
            onBlur={() => setActive(false)}
            onMouseEnter={() => setActive(true)}
            onMouseLeave={() => setActive(false)}>
            <div className="case-header">
                <div className="status">
                    <div className={"ellipse-status " + status} />
                    <div>{t(status+"CaseStatus")}</div>
                </div><Slash />
                <div className="fw-bold">{id}</div><Slash />
                <div className="text-break fw-bold">{subject}</div><Slash />
                <div className="fst-italic muted">{d(start_date)}</div>
            </div>
            <div className="case-body">                
                <div className="case-info">
                    <div className="related">{t("order").toLowerCase()}:
                        {order ? <div>{order}</div> : <span className="muted">{t("none")}</span>}
                    </div>                    
                </div>
                <div className="case-content">
                    <div className="case-box">
                        {active ? description : slice(description, maxTextLength)}
                    </div>
                </div>
            </div>
        </div>
    )
})