import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { useContext } from 'react'

import RegistrationForm from './form'
import { Pa321Context } from '../../services/pa321Service/pa321Service'
import { LanguageContext } from '../../language/LanguageContext'
import UpdateIcon from '../../components/pa321/icons/updateIcon'

export default function RegistrationPage() {

    const { t } = useContext(LanguageContext)

    const { registration } = useContext(Pa321Context)
    const { status } = registration

    return (
        <>
            <Helmet>
                <title>Pa321-{t("registration")}</title>
            </Helmet>
            <div className="container single-block" >
                <div className="row">
                    <h3>{t("registration")}{' '}{status==="loading" && <UpdateIcon className="loading" />}</h3>
                </div>
                <div className="row">
                    <RegistrationForm />
                </div>
                <div className="row mt-3">
                    <Link title={t("login_link")} to="/login">{t("login_link")}</Link>
                </div>
            </div>
        </>
    )
}