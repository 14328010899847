export default function reducer(state: any, action: { type: any; payload: any }) { //todo

    switch (action.type) {        
        case "SET_STATUS":
            return {
                ...state,
                [action.payload.key]: {
                    ...state[action.payload.key],
                    status: action.payload.status,
                }
            }    
        case "SET":
            return {
                ...state,
                [action.payload.key]: {
                    ...state[action.payload.key],
                    data: action.payload.data,
                    status: "idle",
                }
            }
        case "SET_FORM_ERRORS":
            return {
                ...state,
                [action.payload.key]: {
                    ...state[action.payload.key],
                    formErrors: action.payload.formErrors,
                    status: (typeof action.payload.formErrors === "undefined"|| action.payload.formErrors.length === 0) ? "success" : "error",
                }
            }
        case "UPDATE":
            return {
                ...state,
                [action.payload.key]: {
                    ...state[action.payload.key],
                    data: action.payload.data,
                }
            }
        default:
            throw Error('Unknown action: ' + action.type);
    }
}