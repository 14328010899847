import { CSSProperties, useCallback, useEffect, useRef, useState } from 'react'

export default function useFreeSpace() {
    const containerRef = useRef<HTMLDivElement>(null)
    const innerRefs = useRef<Array<HTMLDivElement | null>>([])

    const calcFreeSpace = useCallback(() => {
        if (containerRef.current) {
            const containerComputedStyle = getComputedStyle(containerRef.current)
            const innerOffsetHeight = (innerRefs.current) ? innerRefs.current.reduce((acc, el) => acc + (el ? el.offsetHeight : 0), 0) : 0
            return parseInt(containerComputedStyle.height)
                - (parseInt(containerComputedStyle.paddingTop) || 0)
                - (parseInt(containerComputedStyle.paddingBottom) || 0)
                - (parseInt(containerComputedStyle.gap) || 0)
                - innerOffsetHeight
        }
        return 0
    }, [])

    const [freeSpace, setFreeSpace] = useState(calcFreeSpace())

    const getFreeSpace = useCallback(() => {
        setFreeSpace(calcFreeSpace())
    }, [])

    useEffect(() => {
        window.addEventListener("resize", getFreeSpace)
        return () => window.removeEventListener("resize", getFreeSpace)
    }, [])

    const getListStyle = useCallback((fS?: number) => {
        const _freeSpace = (typeof fS === "number") ? fS : freeSpace
        const scroll: CSSProperties = {
            overflow: "overlay",
            overscrollBehavior: "contain",
        }
        const height: CSSProperties = (typeof _freeSpace === "number" && _freeSpace > 0) ? { maxHeight: _freeSpace, minHeight: _freeSpace } : {}
        return { ...scroll, ...height }
    }, [freeSpace])

    return {
        freeSpace,
        containerRef,
        innerRefs,
        getFreeSpace,
        getListStyle,
    }
}