import { VendorType } from "./VendorType";

const vendorsStub: VendorType[] = [
    {
        id: "1",
        name: "Microsoft",
        description: "Microsoft Corporation",
        image: "https://upload.wikimedia.org/wikipedia/commons/thumb/4/44/Microsoft_logo.svg/2560px-Microsoft_logo.svg.png"
    },
    {
        id: "2",
        name: "Apple",
        description: "Apple Inc.",
        image: "https://upload.wikimedia.org/wikipedia/commons/f/fa/Apple_logo_black.svg"
    },
    {
        id: "3",
        name: "Samsung",
        description: "Samsung Electronics",
        image: "https://cdn.worldvectorlogo.com/logos/samsung-electronics.svg"
    },
    {
        id: "4",
        name: "Xiaomi",
        description: "Xiaomi Inc.",
        image: "https://серебро.рф/img/placeholder.png"
    },
    {
        id: "5",
        name: "Huawei",
        description: "Huawei Technologies Co. Ltd.",
        image: "https://серебро.рф/img/placeholder.png"
    },
    {
        id: "6",
        name: "Lenovo",
        description: "Lenovo Group Limited",
        image: "https://серебро.рф/img/placeholder.png"
    },
    {
        id: "7",
        name: "Asus",
        description: "ASUSTeK COMPUTER INC.",
        image: "https://серебро.рф/img/placeholder.png"
    },
    {
        id: "8",
        name: "Toshiba",
        description: "Toshiba Corporation",
        image: "https://серебро.рф/img/placeholder.png"
    },
    {
        id: "9",
        name: "Dell",
        description: "Dell Inc.",
        image: "https://серебро.рф/img/placeholder.png"
    },
    {
        id: "10",
        name: "HP",
        description: "Hewlett-Packard Company",
        image: "https://серебро.рф/img/placeholder.png"
    },
    {
        id: "11",
        name: "Acer",
        description: "Acer Inc.",
        image: "https://серебро.рф/img/placeholder.png"
    },
    {
        id: "12",
        name: "Razer",
        description: "Razer Inc.",
        image: "https://серебро.рф/img/placeholder.png"
    },
    {
        id: "13",
        name: "LG",
        description: "LG Electronics",
        image: "https://серебро.рф/img/placeholder.png"
    },
    {
        id: "14",
        name: "Sony",
        description: "Sony Corporation",
        image: "https://серебро.рф/img/placeholder.png"
    },
    {
        id: "15",
        name: "Nikon",
        description: "Nikon Corporation",
        image: "https://серебро.рф/img/placeholder.png"
    },
    {
        id: "16",
        name: "Canon",
        description: "Canon Inc.",
        image: "https://серебро.рф/img/placeholder.png"
    },  
    {
        id: "17",
        name: "Aceline",
        description: "Aceline Corporation",
        image: "https://brandwiki.ru/up/brands/product/200501_8897.webp"        
    },   
]

export default vendorsStub