import { MessageType } from '../messages/MessageType'
import { FileType } from '../files/FileType'

export enum CaseStatuses {
    new = "new",
    inwork = "inwork",
    completed = "completed",
}

export type CaseType = {
    id: number,
    status: keyof typeof CaseStatuses,
    subject: string,
    start_date: string,
    description: string,
    order: number | undefined,
    files?: FileType[],
    messages?: MessageType[],
}