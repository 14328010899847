import './style.scss'

export type LoaderProps = {
    elements?: number[],
    variant?: "fat"|"simple"|"tile",
    count?: number,
}

export default function Loader({elements, variant="simple", count=5 } : LoaderProps) {
    const LoaderElement = ({className} : {className?: string}) => <div className={`loader-element ${className}`}>&nbsp;</div>
    
    if(variant==="tile"){
        return (
            <>
                {[...Array(count)].map((_, i) => <LoaderElement className={variant} key={i} />)}
            </>
        )
    }


    return (
        <div className="loader-container">
        {
            elements?.map((e,i) => {
                return (
                    <div className='loader-row' key={`r${i}`}>
                        {[...Array(e)].map((_, j) => <LoaderElement className={variant} key={`r${i}-c${j}`} />)}
                    </div>
                )
            })
        }
        </div>
    )
}