import { Modal } from 'react-bootstrap'
import { useContext, useState } from 'react'

import FilesList from './list'
import FilesIcon from './icons/filesIcon'
import { FileType } from './FileType'
import { LanguageContext } from '../../../language/LanguageContext'

function Files({ files, active, text }: {files: FileType[], active: boolean, text?: string}) {

    const { t } = useContext(LanguageContext)

    const [isOpened, setIsOpened] = useState<boolean>(false)

    const iconProps = active ? { onClick: () => setIsOpened(true), title: t("viewFiles") } : { disabled : true }
    
    return (
        <>
            <button {...iconProps}>
                <FilesIcon active={active} count={files.length} />
            </button>

            <Modal show={isOpened} onHide={() => setIsOpened(false)} centered size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        {t("attachedFiles")} {text}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FilesList files={files} />             
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Files