import { useContext } from 'react'

import Item from './view'
import { ItemType } from './ItemType'
import { LanguageContext } from '../../../../language/LanguageContext'
import useFreeSpace from '../../../../hooks/useFreeSpace'

export default function ItemsList({ orderId, items, freeSpace }: { orderId: number, items: ItemType[], freeSpace?: number }) {

    const { t } = useContext(LanguageContext)

    const {getListStyle} = useFreeSpace()
    
    return (
        <>
            <div className="table-row fw-bold">
                <div>{t("name")}</div>
                <div>{t("quantity")}</div>
                <div>{t("price")}</div>
            </div>
            <div style={getListStyle(freeSpace)} >
                {
                    items?.map((item: ItemType) => <Item key={item.id} {...item} />)
                }
            </div>
        </>
    )
}