import { NavLink } from 'react-router-dom'
import { useCallback, useContext, useEffect } from 'react'

import Counter from './counter'
import { CartItemType } from './CartType'
import TrashIcon from '../icons/trashIcon'
import { StoreContext, StoreDispatchContext } from '../../../services/storeService/storeService'
import { LanguageContext } from '../../../language/LanguageContext'
import useFreeSpace from '../../../hooks/useFreeSpace'

export default function Cart() {

    const { cart } = useContext(StoreContext)
    const { t } = useContext(LanguageContext)

    const { containerRef, innerRefs, getFreeSpace, getListStyle } = useFreeSpace()

    useEffect(() => {
        getFreeSpace()
    }, [cart])

    const sum = useCallback(() => {
        let currency = "€"
        return cart.reduce((acc: number, item: CartItemType) => {
            currency = item.product.price.replace(/\d/g, "")
            return acc + parseInt(item.product.price) * item.quantity
        }, 0).toString() + currency
    }, [cart])

    return (
        <div className="cart" ref={containerRef}>
            <div className="header" ref={el => innerRefs.current[0] = el}>
                <h3>{t("cart")}</h3>
            </div>
            {cart.length ?
                <>
                    <div className="cart-items" style={getListStyle()} >
                        {cart.map((item: CartItemType) => <CartItem key={item.product.id} {...item} />)}
                    </div>
                    <div className="sum" ref={el => innerRefs.current[1] = el}>
                        {t("sum")}:&nbsp;&nbsp;{sum()}
                    </div>
                </>
                :
                <div className="cart-empty">
                    {t("cartEmpty")}
                </div>
            }
        </div>
    )
}

function CartItem({ product, quantity }: CartItemType) {
    const { removeFromCart } = useContext(StoreDispatchContext)
    const { t } = useContext(LanguageContext)
    const onTrashClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        const confirm = window.confirm(t("cartItemRemoveConfirm"))
        if (!confirm) return
        removeFromCart(product.id)
    }
    const currency = product.price.replace(/\d/g, "")
    const sum = quantity * parseInt(product.price)
    return (
        <div className="cart-item">
            <div className="info">
                <div className="image"><img src={product.image} alt={product.name} /></div>
                <div className="name"><NavLink to={`/store/catalog/product/${product.id}`} >{product.name}</NavLink></div>
            </div>
            <div className="purchase">
                <div><button title={t("remove")} onClick={onTrashClick}><TrashIcon /></button></div>
                <div className="quantity"><Counter product={product} /></div>
                <div className="price">{sum.toString() + currency}</div>
            </div>
        </div>
    )
}