const registrationLabels = {
    registration_link: "Регистрация",
    registration: "Регистрация",
    firstName: "Имя",
    firstName_required: "Имя обязательно",
    lastName: "Фамилия",
    lastName_required: "Фамилия обязательна",
    register: "Зарегистрироваться",
    userAlreadyExists: "Пользователь с такой почтой уже существует. Хотите <a href='/login'>войти</a>?",
}

export default registrationLabels