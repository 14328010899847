import { Modal } from 'react-bootstrap'
import { useContext, useEffect, useState } from 'react'

import CaseForm from './form'
import Success from '../../../pageElements/Success/success'
import UpdateIcon from '../../icons/updateIcon'
import PlusIcon from '../../icons/plusIcon'
import { Pa321Context, Pa321DispatchContext } from '../../../../services/pa321Service/pa321Service'
import { LanguageContext } from '../../../../language/LanguageContext'

type NewCaseProps = {
    className?: string,
    text?: string,
    orderId?: number,
}

export default function NewCase({ className = "panel-item", text = "new", orderId }: NewCaseProps) {

    const { t } = useContext(LanguageContext)

    const [isOpened, setIsOpened] = useState<boolean>(false)

    const { getCases, resetStatus } = useContext(Pa321DispatchContext)
    const { caseForm } = useContext(Pa321Context)
    const { status } = caseForm

    const wait = process.env.REACT_APP_WHEEL_WAIT_S ? parseInt(process.env.REACT_APP_WHEEL_WAIT_S) : 3

    const closeModal = () => {
        setIsOpened(false)        
    }

    useEffect(() => {
        if (isOpened&&status !== "idle") {
            resetStatus("caseForm")
        }
    }, [isOpened])

    useEffect(() => {
        if (status === "success") {
            setTimeout(() => {
                resetStatus("caseForm")
                closeModal()
                getCases()
            }, wait * 1000)
        }
    }, [status])

    return (
        <>
            <button className={className} onClick={() => {
                setIsOpened(true)
            }} title={t("newCase")}>
                <div>{t(text)}</div>
                <div><PlusIcon /></div>
            </button>
            <Modal show={isOpened} onHide={closeModal} centered size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        {t("caseSubject")} {t("and")} {t("caseDescription").toLowerCase()}
                        {status === "loading" && <UpdateIcon className="loading" />}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {status === "success" ? <Success duration={wait} /> : <CaseForm orderId={orderId} />}
                </Modal.Body>
            </Modal>
        </>
    )
}