import { ProductType } from './ProductType'

const productsStub: ProductType[] = [
    {
        id: "1",
        name: "Мышь проводная Aceline CM-408BU черный",
        price: "99$",
        vendor: {
            id: "17",
            name: "Aceline",
            description: "",
        },
        category: {
            id: "211",
            name: "Мыши проводные",
        },
        description: "Компактная мышь проводная Aceline CM-408BU выполнена в черном корпусе обтекаемой формы, что делает ее удобной для каждого вне зависимости от того, какой рукой удобнее всего пользоваться. В основе манипулятора лежит светодиодный датчик с поддержкой максимального разрешения 800 dpi: этот показатель остается неизменным, что делает мышь незаменимой для решения повседневных задач. ",
        image: "https://c.dns-shop.ru/thumb/st1/fit/wm/0/0/1a8ccf53d30f8a7a97edceeeb8b6fa97/c1b3c7d7fbb3d37773e698751f0b7090bff2375cc1a057d1247c6c7cec3242f4.jpg.webp",
    },
    {
        id: "2",
        name: "Мышь проводная Microsoft CM-408BU белый",
        price: "99$",
        category: {
            id: "211",
            name: "Мыши проводные",
        },
        vendor: {
            id: "1",
            name: "Microsoft",
            description: "",
        },
        description: "Просто мышь",
        image: "https://c.dns-shop.ru/thumb/st1/fit/wm/0/0/1a8ccf53d30f8a7a97edceeeb8b6fa97/c1b3c7d7fbb3d37773e698751f0b7090bff2375cc1a057d1247c6c7cec3242f4.jpg.webp",

    },
    {
        id: "3",
        name: "Мышь проводная Aceline CM-408BU черный",
        price: "99$",
        vendor: {
            id: "17",
            name: "Aceline",
            description: "",
        },
        category: {
            id: "211",
            name: "Мыши проводные",
        },
        description: "Компактная мышь проводная Aceline CM-408BU выполнена в черном корпусе обтекаемой формы, что делает ее удобной для каждого вне зависимости от того, какой рукой удобнее всего пользоваться. В основе манипулятора лежит светодиодный датчик с поддержкой максимального разрешения 800 dpi: этот показатель остается неизменным, что делает мышь незаменимой для решения повседневных задач. ",
        image: "https://c.dns-shop.ru/thumb/st1/fit/wm/0/0/1a8ccf53d30f8a7a97edceeeb8b6fa97/c1b3c7d7fbb3d37773e698751f0b7090bff2375cc1a057d1247c6c7cec3242f4.jpg.webp",
    },
    {
        id: "4",
        name: "Мышь проводная Microsoft CM-408BU белый",
        price: "99$",
        category: {
            id: "211",
            name: "Мыши проводные",
        },
        vendor: {
            id: "1",
            name: "Microsoft",
            description: "",
        },
        description: "Просто мышь",
        image: "https://c.dns-shop.ru/thumb/st1/fit/wm/0/0/1a8ccf53d30f8a7a97edceeeb8b6fa97/c1b3c7d7fbb3d37773e698751f0b7090bff2375cc1a057d1247c6c7cec3242f4.jpg.webp",

    },
    {
        id: "5",
        name: "Мышь проводная Aceline CM-408BU черный",
        price: "99$",
        vendor: {
            id: "17",
            name: "Aceline",
            description: "",
        },
        category: {
            id: "211",
            name: "Мыши проводные",
        },
        description: "Компактная мышь проводная Aceline CM-408BU выполнена в черном корпусе обтекаемой формы, что делает ее удобной для каждого вне зависимости от того, какой рукой удобнее всего пользоваться. В основе манипулятора лежит светодиодный датчик с поддержкой максимального разрешения 800 dpi: этот показатель остается неизменным, что делает мышь незаменимой для решения повседневных задач. ",
        image: "https://c.dns-shop.ru/thumb/st1/fit/wm/0/0/1a8ccf53d30f8a7a97edceeeb8b6fa97/c1b3c7d7fbb3d37773e698751f0b7090bff2375cc1a057d1247c6c7cec3242f4.jpg.webp",
    },
    {
        id: "6",
        name: "Мышь проводная Microsoft CM-408BU белый",
        price: "99$",
        category: {
            id: "211",
            name: "Мыши проводные",
        },
        vendor: {
            id: "1",
            name: "Microsoft",
            description: "",
        },
        description: "Просто мышь",
        image: "https://c.dns-shop.ru/thumb/st1/fit/wm/0/0/1a8ccf53d30f8a7a97edceeeb8b6fa97/c1b3c7d7fbb3d37773e698751f0b7090bff2375cc1a057d1247c6c7cec3242f4.jpg.webp",

    },
    {
        id: "7",
        name: "Мышь проводная Aceline CM-408BU черный",
        price: "99$",
        vendor: {
            id: "17",
            name: "Aceline",
            description: "",
        },
        category: {
            id: "211",
            name: "Мыши проводные",
        },
        description: "Компактная мышь проводная Aceline CM-408BU выполнена в черном корпусе обтекаемой формы, что делает ее удобной для каждого вне зависимости от того, какой рукой удобнее всего пользоваться. В основе манипулятора лежит светодиодный датчик с поддержкой максимального разрешения 800 dpi: этот показатель остается неизменным, что делает мышь незаменимой для решения повседневных задач. ",
        image: "https://c.dns-shop.ru/thumb/st1/fit/wm/0/0/1a8ccf53d30f8a7a97edceeeb8b6fa97/c1b3c7d7fbb3d37773e698751f0b7090bff2375cc1a057d1247c6c7cec3242f4.jpg.webp",
    },
    {
        id: "8",
        name: "Мышь проводная Microsoft CM-408BU белый",
        price: "99$",
        category: {
            id: "211",
            name: "Мыши проводные",
        },
        vendor: {
            id: "1",
            name: "Microsoft",
            description: "",
        },
        description: "Просто мышь",
        image: "https://c.dns-shop.ru/thumb/st1/fit/wm/0/0/1a8ccf53d30f8a7a97edceeeb8b6fa97/c1b3c7d7fbb3d37773e698751f0b7090bff2375cc1a057d1247c6c7cec3242f4.jpg.webp",

    },
    {
        id: "9",
        name: "Мышь проводная Aceline CM-408BU черный",
        price: "99$",
        vendor: {
            id: "17",
            name: "Aceline",
            description: "",
        },
        category: {
            id: "211",
            name: "Мыши проводные",
        },
        description: "Компактная мышь проводная Aceline CM-408BU выполнена в черном корпусе обтекаемой формы, что делает ее удобной для каждого вне зависимости от того, какой рукой удобнее всего пользоваться. В основе манипулятора лежит светодиодный датчик с поддержкой максимального разрешения 800 dpi: этот показатель остается неизменным, что делает мышь незаменимой для решения повседневных задач. ",
        image: "https://c.dns-shop.ru/thumb/st1/fit/wm/0/0/1a8ccf53d30f8a7a97edceeeb8b6fa97/c1b3c7d7fbb3d37773e698751f0b7090bff2375cc1a057d1247c6c7cec3242f4.jpg.webp",
    },
    {
        id: "10",
        name: "Мышь проводная Microsoft CM-408BU белый",
        price: "99$",
        category: {
            id: "211",
            name: "Мыши проводные",
        },
        vendor: {
            id: "1",
            name: "Microsoft",
            description: "",
        },
        description: "Просто мышь",
        image: "https://c.dns-shop.ru/thumb/st1/fit/wm/0/0/1a8ccf53d30f8a7a97edceeeb8b6fa97/c1b3c7d7fbb3d37773e698751f0b7090bff2375cc1a057d1247c6c7cec3242f4.jpg.webp",

    },
    {
        id: "11",
        name: "Мышь проводная Aceline CM-408BU черный",
        price: "99$",
        vendor: {
            id: "17",
            name: "Aceline",
            description: "",
        },
        category: {
            id: "211",
            name: "Мыши проводные",
        },
        description: "Компактная мышь проводная Aceline CM-408BU выполнена в черном корпусе обтекаемой формы, что делает ее удобной для каждого вне зависимости от того, какой рукой удобнее всего пользоваться. В основе манипулятора лежит светодиодный датчик с поддержкой максимального разрешения 800 dpi: этот показатель остается неизменным, что делает мышь незаменимой для решения повседневных задач. ",
        image: "https://c.dns-shop.ru/thumb/st1/fit/wm/0/0/1a8ccf53d30f8a7a97edceeeb8b6fa97/c1b3c7d7fbb3d37773e698751f0b7090bff2375cc1a057d1247c6c7cec3242f4.jpg.webp",
    },
    {
        id: "12",
        name: "Мышь проводная Microsoft CM-408BU белый",
        price: "99$",
        category: {
            id: "211",
            name: "Мыши проводные",
        },
        vendor: {
            id: "1",
            name: "Microsoft",
            description: "",
        },
        description: "Просто мышь",
        image: "https://c.dns-shop.ru/thumb/st1/fit/wm/0/0/1a8ccf53d30f8a7a97edceeeb8b6fa97/c1b3c7d7fbb3d37773e698751f0b7090bff2375cc1a057d1247c6c7cec3242f4.jpg.webp",

    },
    {
        id: "13",
        name: "Мышь проводная Aceline CM-408BU черный",
        price: "99$",
        vendor: {
            id: "17",
            name: "Aceline",
            description: "",
        },
        category: {
            id: "211",
            name: "Мыши проводные",
        },
        description: "Компактная мышь проводная Aceline CM-408BU выполнена в черном корпусе обтекаемой формы, что делает ее удобной для каждого вне зависимости от того, какой рукой удобнее всего пользоваться. В основе манипулятора лежит светодиодный датчик с поддержкой максимального разрешения 800 dpi: этот показатель остается неизменным, что делает мышь незаменимой для решения повседневных задач. ",
        image: "https://c.dns-shop.ru/thumb/st1/fit/wm/0/0/1a8ccf53d30f8a7a97edceeeb8b6fa97/c1b3c7d7fbb3d37773e698751f0b7090bff2375cc1a057d1247c6c7cec3242f4.jpg.webp",
    },
    {
        id: "14",
        name: "Мышь проводная Microsoft CM-408BU белый",
        price: "99$",
        category: {
            id: "211",
            name: "Мыши проводные",
        },
        vendor: {
            id: "1",
            name: "Microsoft",
            description: "",
        },
        description: "Просто мышь",
        image: "https://c.dns-shop.ru/thumb/st1/fit/wm/0/0/1a8ccf53d30f8a7a97edceeeb8b6fa97/c1b3c7d7fbb3d37773e698751f0b7090bff2375cc1a057d1247c6c7cec3242f4.jpg.webp",

    },
    {
        id: "15",
        name: "Мышь проводная Aceline CM-408BU черный",
        price: "99$",
        vendor: {
            id: "17",
            name: "Aceline",
            description: "",
        },
        category: {
            id: "211",
            name: "Мыши проводные",
        },
        description: "Компактная мышь проводная Aceline CM-408BU выполнена в черном корпусе обтекаемой формы, что делает ее удобной для каждого вне зависимости от того, какой рукой удобнее всего пользоваться. В основе манипулятора лежит светодиодный датчик с поддержкой максимального разрешения 800 dpi: этот показатель остается неизменным, что делает мышь незаменимой для решения повседневных задач. ",
        image: "https://c.dns-shop.ru/thumb/st1/fit/wm/0/0/1a8ccf53d30f8a7a97edceeeb8b6fa97/c1b3c7d7fbb3d37773e698751f0b7090bff2375cc1a057d1247c6c7cec3242f4.jpg.webp",
    },
    {
        id: "16",
        name: "Мышь проводная Microsoft CM-408BU белый",
        price: "99$",
        category: {
            id: "211",
            name: "Мыши проводные",
        },
        vendor: {
            id: "1",
            name: "Microsoft",
            description: "",
        },
        description: "Просто мышь",
        image: "https://c.dns-shop.ru/thumb/st1/fit/wm/0/0/1a8ccf53d30f8a7a97edceeeb8b6fa97/c1b3c7d7fbb3d37773e698751f0b7090bff2375cc1a057d1247c6c7cec3242f4.jpg.webp",

    }

]

export default productsStub