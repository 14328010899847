const orderLabels = {
    order: "Заказ",
    orders: "Заказы",
    cancel: "Отменить",

    newOrderStatus: "новый",
    cancelledOrderStatus: "отменен",
    inworkOrderStatus: "в работе", 

    quantity: "количество",
    total_quantity: "товаров",
    price: "цена",
    total_price: "сумма",
    relatedCases: "связанные обращения",
    goods: "товары",
    name: "наименование",
}

export default orderLabels