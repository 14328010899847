const registrationLabels = {
    registration_link: "Registration",
    registration: "Registration",
    firstName: "First name",
    firstName_required: "First name is required",
    lastName: "Last name",
    lastName_required: "Last name is required",
    register: "Register",
    userAlreadyExists: "User with this email already exists. Do you want to <a href='/login'>login</a> instead?",
}

export default registrationLabels