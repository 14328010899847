import { useContext } from 'react'

import LogoutIcon from './logoutIcon'
import { LanguageContext } from '../../../../language/LanguageContext'
import { AuthContext } from '../../../../Auth'

export default function Logout({ style }: { style?: React.CSSProperties }) {

    const { t } = useContext(LanguageContext)

    const auth = useContext(AuthContext)

    return (
        <button className="menu-item" onClick={auth.onLogout} title={t("logout")}>
            <LogoutIcon />
            <div style={style} >{t("logout")}</div>
        </button>
    )
}