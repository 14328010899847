import { Tab, Tabs } from 'react-bootstrap'
import { useContext, useEffect, useLayoutEffect, useState } from 'react'

import { CaseType } from '../CaseType'
import CloseCase from '../actions/close'
import Slash from '../../../pageElements/Slash/slash'
import Loader from '../../../pageElements/Loader/Loader'
import GoBackButton from '../../../pageElements/GoBackButton/goBackButton'
import ViewOrder from '../../orders/views/modalView'
import MessagesList from '../../messages/list'
import FilesList from '../../files/list'
import FileUpload from '../../files/actions/new'
import ErrorAlert from '../../../errors/errorAlert'
import { Pa321Context, Pa321DispatchContext } from '../../../../services/pa321Service/pa321Service'
import { LanguageContext } from '../../../../language/LanguageContext'
import useParamsNumber from '../../../../hooks/useParamsNumber'
import useFreeSpace from '../../../../hooks/useFreeSpace'
import { dateToString as d } from '../../../../helpers/dateHelpers'

export default function Case() {
    const { id, parseError } = useParamsNumber()

    const { t } = useContext(LanguageContext)

    const { getCase } = useContext(Pa321DispatchContext)
    const { caseItem } = useContext(Pa321Context)
    const { data } = caseItem
    const loading = caseItem.status==="loading"

    const [tabKey, setTabKey] = useState<string>("messages")
    const { freeSpace, containerRef, innerRefs, getFreeSpace } = useFreeSpace()
    const tabHeight = 80 //todo custom tabs + calcFreespace

    useEffect(() => {
        if (id) getCase(id)
    }, [])

    useLayoutEffect(() => {
        getFreeSpace()
    }, [data])

    const view = (data: CaseType) => {       
        
        const { status, start_date, subject, files, order } = data
        const readonly = (status === "completed")

        return (
            <>
                <div className="case-header" ref={el => innerRefs.current[0] = el}>
                    <div className="status">
                        <div className={"ellipse-status " + status} />
                        <div>{t(status + "CaseStatus")}</div>
                    </div><Slash />
                    <div className="fw-bold">{id}</div><Slash />
                    <div className="text-break fw-bold">{subject}</div><Slash />
                    <div className="fst-italic muted">{d(start_date)}</div>
                </div>
                <div className="case-tabs">
                    <div className="case-info" ref={el => innerRefs.current[1] = el} >
                        <div className="related">{t("order").toLowerCase()}:
                            {order ? <ViewOrder id={order} /> : <span className="muted">{t("none")}</span>}
                        </div>
                        {!readonly &&
                            <>
                                <FileUpload key={`c-${id}`} caseId={id} doAfter={() => setTabKey("files")} />
                                <CloseCase id={id} />
                            </>}
                    </div>
                    <Tabs activeKey={tabKey} onSelect={(k) => k && setTabKey(k)} justify>
                        <Tab eventKey="messages" title={t("messages")}>
                            <MessagesList
                                active={(tabKey === "messages")}
                                caseId={id}
                                readonly={readonly}
                                freeSpace={freeSpace - tabHeight}
                            />
                        </Tab>
                        <Tab eventKey="files" title={t("files")}>
                            <FilesList
                                files={files || []}
                                freeSpace={freeSpace - tabHeight}
                            />
                        </Tab>
                    </Tabs>
                </div>
            </>
        )
    }

    return (
        <div className="case-page" ref={containerRef}>
            {(typeof parseError !== "undefined") ? <ErrorAlert message={parseError} ><GoBackButton /></ErrorAlert> :
                loading ? <Loader key="case-loader" elements={[4, 1, 2, 1]} /> :
                    (typeof data !== "undefined" && view(data))
            }
        </div>
    )
}