import './style.scss'

import OkIcon from './okIcon'

export default function Success({duration} : {duration: number}) {
    return (
        <div className="success-container">
            <div className="success-block" >
                <div className="success-block-before" style={{animationDuration: `${duration}s`}}></div>
                <OkIcon />
                <div className="success-block-after"></div>
            </div>
        </div>
    )
}