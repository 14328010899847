export default function CaseIcon() {
  return (
    <svg
      height="2rem"
      width="2rem"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.66646 8.84138V7.28168C9.66646 6.12669 10.5115 5.14549 11.6537 4.97416L13.2802 4.73018C15.0832 4.45973 16.9164 4.45973 18.7194 4.73018L20.3459 4.97416C21.4881 5.14549 22.3331 6.12669 22.3331 7.28168V8.84138L24.6185 9.02586C26.3684 9.1671 27.7957 10.4846 28.0764 12.2176C28.6966 16.0476 28.6966 19.9525 28.0764 23.7826C27.7957 25.5155 26.3684 26.8331 24.6185 26.9743L22.1226 27.1758C18.0474 27.5047 13.9522 27.5047 9.87696 27.1758L7.38107 26.9743C5.63122 26.8331 4.20384 25.5155 3.9232 23.7826C3.30295 19.9525 3.30295 16.0476 3.9232 12.2176C4.20384 10.4846 5.63122 9.1671 7.38107 9.02586L9.66646 8.84138ZM13.5769 6.70805C15.1832 6.46711 16.8164 6.46711 18.4227 6.70805L20.0492 6.95203C20.2124 6.97651 20.3331 7.11668 20.3331 7.28168V8.70115C17.4466 8.53652 14.553 8.53652 11.6665 8.70115V7.28168C11.6665 7.11668 11.7872 6.97651 11.9503 6.95203L13.5769 6.70805ZM10.0379 10.8179C14.006 10.4976 17.9935 10.4976 21.9617 10.8179L24.4576 11.0194C25.2898 11.0865 25.9686 11.7131 26.1021 12.5373C26.1852 13.0505 26.2565 13.5652 26.3161 14.0809C19.8126 17.2833 12.187 17.2833 5.6835 14.0809C5.74303 13.5652 5.81436 13.0505 5.89748 12.5373C6.03094 11.7131 6.70979 11.0865 7.54199 11.0194L10.0379 10.8179ZM5.50498 16.209C12.1708 19.2406 19.8287 19.2406 26.4946 16.209C26.6217 18.6305 26.4909 21.0622 26.1021 23.4629C25.9686 24.287 25.2898 24.9136 24.4576 24.9808L21.9617 25.1823C17.9935 25.5026 14.006 25.5026 10.0379 25.1823L7.54198 24.9808C6.70978 24.9136 6.03094 24.287 5.89748 23.4629C5.50871 21.0622 5.37788 18.6305 5.50498 16.209Z"
        fillRule="evenodd"
      />
    </svg>
  )
}