import { useContext } from 'react'
import { Helmet } from 'react-helmet'
import { LanguageContext } from '../../language/LanguageContext'

function SettingsPage() {

    const { t } = useContext(LanguageContext)
    
    return (
        <>
            <Helmet>
                <title>Pa321-{t("settings")}</title>
            </Helmet>
            <div className="" >
                <h3>{t("settings")}</h3>
                <p>{t("settings_text")}</p>
            </div>
        </>
    )
}

export default SettingsPage