import { Button, Form } from 'react-bootstrap'
import { FormEvent, useContext, useState } from 'react'

import { Pa321Context, Pa321DispatchContext } from '../../services/pa321Service/pa321Service'
import { LanguageContext } from '../../language/LanguageContext'
import parse from 'html-react-parser'

type FormErrorsType = {
    firstName?: string[],
    lastName?: string[],
    email?: string[],
    password?: string[],
}

export default function RegistrationForm() {

    const { t } = useContext(LanguageContext)

    const { register } = useContext(Pa321DispatchContext)
    const { registration } = useContext(Pa321Context)
    const { status, formErrors } = registration

    const [validated, setValidated] = useState<boolean>(false)

    const [firstName, setFirstName] = useState<string>('')
    const [lastName, setLastName] = useState<string>('')
    const [email, setEmail] = useState<string>('')
    const [password, setPassword] = useState<string>('')

    //todo min len

    const onSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        let form = event.currentTarget
        if (form.checkValidity()) {
            setValidated(false)
            register(firstName, lastName, email, password)
        } else {
            setValidated(true)
        }
    }

    return (
        <Form noValidate validated={validated} onSubmit={onSubmit}>
            <Form.Group controlId="formFirstName" className="mt-3">
                <Form.Label>{t("firstName")}</Form.Label>
                <Form.Control
                    name="firstName"
                    required
                    autoFocus
                    value={firstName}
                    onChange={e => setFirstName(e.target.value)}
                    isInvalid={(formErrors && formErrors.firstName) ? true : false}
                />
                <Form.Control.Feedback type="invalid">
                    {formErrors?.firstName?.map((e: string) => t(e)).join("; ") || t("firstName_required")}
                </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="formLastName" className="mt-3">
                <Form.Label>{t("lastName")}</Form.Label>
                <Form.Control
                    name="lastName"
                    required
                    value={lastName}
                    onChange={e => setLastName(e.target.value)}
                    isInvalid={(formErrors && formErrors.lastName) ? true : false}
                />
                <Form.Control.Feedback type="invalid">
                    {formErrors?.lastName?.map((e: string) => t(e)).join("; ") || t("lastName_required")}
                </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="formEmail" className="mt-3">
                <Form.Label>{t("email")}</Form.Label>
                <Form.Control
                    type="email"
                    name="email"
                    required
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    isInvalid={(formErrors && formErrors.email) ? true : false}
                />
                <Form.Control.Feedback type="invalid">
                    {formErrors?.email?.map((e: string) => parse(t(e))) || t("email_required")}
                </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="formPassword" className="mt-3">
                <Form.Label>{t("password")}</Form.Label>
                <Form.Control
                    type="password"
                    name="password"
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    isInvalid={(formErrors && formErrors.password) ? true : false}
                />
                <Form.Control.Feedback type="invalid">
                    {formErrors?.password?.map((e: string) => t(e)).join("; ") || t("password_required")}
                </Form.Control.Feedback>
            </Form.Group>
            <Button className="mt-3" variant="success" type="submit" title={t("register")} disabled={status==="loading"}>
                {t("register")}
            </Button>
        </Form>
    )
}