const errorLabels = {
    status500: "Internal Server Error",
    status404: "Page not found",
    status400: "Bad request",
    status403: "Access denied",

    loadFileError: "Failed to load file",

    minimum_length_is: (n: number) => "Minimum length is " + n,
}

export default errorLabels