import { AllowedFilterTypes } from './FilterProvider';
import { FilterType } from './FilterType'

type ActionType = "SET" | "RESET"
export type PayloadType = {
    key: keyof FilterType<AllowedFilterTypes>,
    value: any
}

export default function reducer(state: FilterType<AllowedFilterTypes>, action: { type: ActionType; payload: PayloadType, resetState?: FilterType<AllowedFilterTypes> }) {

    switch (action.type) {
        case "SET":
            return {
                ...state,
                [action.payload.key]: action.payload.value
            }
        case "RESET":
            return { 
                ...state,
                ...action.resetState,
            }            
        default:
            throw Error('Unknown action: ' + action.type);
    }
}