import '../scss/language.scss'

import { createContext, useCallback, useState } from 'react'

import ru from './labels/ru'
import en from './labels/en'
import { ChildrenAsProps } from '../ChildrenAsProps'

const APP_LANGUAGES = {
    en: "English",
    ru: "Русский",
}

type LanguageType = keyof typeof APP_LANGUAGES

type LanguageContextType = {
    t: (key: string, number?: number) => string
    language: LanguageType,
    SelectLanguage: () => JSX.Element
}

export const LanguageContext = createContext<LanguageContextType>({} as LanguageContextType)

export function LanguageProvider({ children }: ChildrenAsProps) {
    const [language, setLanguage] = useState<LanguageType>(getLanguageFromStorage())

    function getLanguageFromStorage() {
        let lang = localStorage.getItem("language")
        if (!lang) {
            lang = "en"
            localStorage.setItem("language", lang)
        }
        return lang as LanguageType
    }

    function saveLanguageToStorage(lang: LanguageType) {
        localStorage.setItem("language", lang)
    }

    const SelectLanguage = () => {
        const onSelectLanguage = useCallback((option: string) => {
            try {
                let lang = option as LanguageType
                saveLanguageToStorage(lang)
                setLanguage(lang)
            } catch (e) {
                console.error(e)
            }
        },[])
        
        return (
            <select value={language} name="language-select" className="language-select" aria-label="Select language" onChange={(e) => onSelectLanguage(e.target.value)}>
                {Object.keys(APP_LANGUAGES).map((key) => <option key={key} value={key}>{APP_LANGUAGES[key as keyof typeof APP_LANGUAGES]}</option>)}
            </select>
        )
    }

    const t = useCallback((label: string, number?: number) => {
        const allLabels: Record<LanguageType, any> = { en, ru }

        const labels = allLabels[language]
        if (typeof number==="number") {
            return (labels[label] && typeof labels[label] === "function") ? labels[label](number) : label+number
        }
        const regex = /([a-zA-Z\s]+) (\d+)/
        const matches = label.match(regex);
        if (matches) {
            const name = matches[1].toLowerCase().trim().replaceAll(" ", "_")
            const _number = parseInt(matches[2])
            return (typeof labels[name] === "function") ? labels[name](_number) : name
        } 
        return (labels[label] && typeof labels[label] === "string") ? labels[label] : label
    },[language])

    return (
        <LanguageContext.Provider value={{ t, language, SelectLanguage }}>
            {children}
        </LanguageContext.Provider>
    )
}