import { createContext, useCallback, useReducer } from 'react'

import reducer from './reducer'
import { ProductType } from '../../components/store/catalog/products/ProductType'
import { CartItemType, CartType } from '../../components/store/cart/CartType'

type StoreContextType = {
    cart: CartType,
}

type StoreDispatchType = {
    setCart: (cart: CartType) => void,
    addToCart: (product: ProductType, quantity: number) => void,
    removeFromCart: (productId: ProductType["id"]) => void,
    getTotalQuantity: () => number,
    getProductQuantity: (productId: ProductType["id"]) => number,
}

export const StoreContext = createContext<StoreContextType>({} as StoreContextType)
export const StoreDispatchContext = createContext<StoreDispatchType>({} as StoreDispatchType)

export function StoreProvider({ children }: any) {

    const loadCart = useCallback(() => {
        const storedCart = localStorage.getItem("cart")
        return storedCart ? JSON.parse(storedCart) : []
    }, [])
    const initState: StoreContextType = {
        cart: loadCart()
    }

    const [state, dispatch] = useReducer(reducer, initState)

    const setCart = useCallback((cart: CartType) => {
        dispatch({ type: "SET", payload: cart })
    }, [])

    const addToCart = useCallback((product: ProductType, quantity: number) => {
        const cart: CartType = state.cart || []
        const i = cart.findIndex((item: CartItemType) => item.product.id === product.id)
        if (i !== -1) {
            cart[i].quantity = cart[i] ? cart[i].quantity + quantity : quantity
            if (cart[i].quantity === 0) {
                cart.splice(i, 1)
            }
        } else {
            cart.push({ product, quantity })
        }
        dispatch({ type: "SET", payload: cart })

    }, [state])


    const removeFromCart = useCallback((productId: ProductType["id"]) => {
        const cart: CartType = state.cart || []
        const i = cart.findIndex((item: CartItemType) => item.product.id === productId)
        if (i!==-1) {
            cart.splice(i, 1)
        } 
        dispatch({ type: "SET", payload: cart })
    }, [state])

    const getTotalQuantity = useCallback(() => {
        let total = 0
        state.cart?.forEach((item: CartItemType) => {
            total += item.quantity
        })
        return total
    }, [state])

    const getProductQuantity = useCallback((productId: ProductType["id"]) => {
        let quantity = 0
        state.cart?.forEach((item: CartItemType) => {
            if (item.product.id === productId) quantity = item.quantity
        })
        return quantity
    }, [state])

    const actions = {
        setCart,
        addToCart,
        removeFromCart,
        getTotalQuantity,
        getProductQuantity,
    }

    return (
        <StoreContext.Provider value={state}>
            <StoreDispatchContext.Provider value={actions}>
                {children}
            </StoreDispatchContext.Provider>
        </StoreContext.Provider>
    )
}