import { useContext, useState } from 'react'

import { ProductType } from '../ProductType'
import productStub from '../productsStub'
import VendorLink from '../../vendors/vendorLink'
import AddToCart from '../../../cart/addToCart'
import { LanguageContext } from '../../../../../language/LanguageContext'

export default function Product() {

    const { t } = useContext(LanguageContext)

    const [product, setProduct] = useState<ProductType>(productStub[0])

    return (
        <div className="product-detailView" >
            <div className="product-overview">
                <div className="product-image">
                    <img src={product.image} alt={product.name} />
                </div>
                <div className="product-info">
                    <div className="fw-bold">{product.name}</div>
                    <VendorLink id={product.vendor.id} name={product.vendor.name} />
                    <div>{t("price")+": "}<span className="fw-bold">{product.price}</span></div>
                    <AddToCart product={product} />
                </div>
            </div>
            <div className="product-details">
                <div className="product-description">{product.description}</div>
            </div>
        </div>
    )
}