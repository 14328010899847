import { isRouteErrorResponse, useRouteError } from 'react-router-dom'
import { useContext } from 'react'

import ErrorAlert from './errorAlert'
import { LanguageContext } from '../../language/LanguageContext'

const Error = () => {

    const { t } = useContext(LanguageContext)

    const error = useRouteError()

    const view = (error: any) => {
        if (isRouteErrorResponse(error)) {
            return (<div>
                <h1>Oops!</h1>
                <h2>{error.status}</h2>
                <p>{error.statusText}</p>
                {error.data?.message && <p>{error.data.message}</p>}
            </div>)
        } else {
            return <ErrorAlert message={error.message} />
        }
    }

    return (
        <div className="container single-block">
            <div><a href="/">{t("toHome")}</a></div>
            {view(error)}
        </div>
    )
}

export default Error