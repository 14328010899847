import { Button, Form } from 'react-bootstrap'
import { FormEvent, useContext, useState } from 'react'

import DemoUser from './demoUser/demoUser'
import { Pa321Context, Pa321DispatchContext } from '../../services/pa321Service/pa321Service'
import { LanguageContext } from '../../language/LanguageContext'

type FormErrorsType = {
    email?: string[],
    password?: string[],
}

export default function LoginForm() {

    const { t } = useContext(LanguageContext)

    const { login } = useContext(Pa321DispatchContext)
    const state = useContext(Pa321Context)
    const { status, formErrors } = state.login

    const [email, setEmail] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [validated, setValidated] = useState<boolean>(false)

    const onSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        let form = event.currentTarget
        if (form.checkValidity()) {
            setValidated(false)
            login(email, password)
        } else {
            setValidated(true)
        }
    }

    return (
        <Form noValidate validated={validated} onSubmit={onSubmit}>
            <div className="row">
                <Form.Group className="mb-3" controlId="formEmail">
                    <Form.Label>{t("email")}</Form.Label>
                    <Form.Control
                        type="email"
                        name="email"
                        required
                        autoFocus
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        isInvalid={(formErrors && formErrors.email) ? true : false}
                        aria-describedby="emailText"
                    />
                    <Form.Control.Feedback type="invalid">
                        {formErrors?.email?.map((e: string) => t(e)).join("; ") || t("email_required")}
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formPassword">
                    <Form.Label>{t("password")}</Form.Label>
                    <Form.Control
                        type="password"
                        name="password"
                        required
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        isInvalid={(formErrors && formErrors.password) ? true : false}
                    />
                    <Form.Control.Feedback type="invalid">
                        {formErrors?.password?.map((e: string) => t(e)).join("; ") || t("password_required")}
                    </Form.Control.Feedback>
                </Form.Group>
                <div className="row gy-1">
                    <div className="col">
                        <Button variant="success" type="submit" title={t("login")} disabled={status==="loading"} >
                            {t("login")}
                        </Button>
                    </div>
                    <div className="col">
                        <DemoUser />
                    </div>
                </div>
            </div >
        </Form>
    )
}