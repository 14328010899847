const caseLabels = {
    case: "Case",
    cases: "Cases",
    updateCases: "Update cases",
    closeCase: "Close case",
    closeCaseConfirm: "Do you really want to close this case?",
    newCase: "New case",

    caseSubject: "Subject",
    caseDescription: "Description",

    noCases: "There are no cases yet. Create one!",
    noFilteredCases: "There are no filtered cases",
    detailView: "Go to detail view",

    newCaseStatus: "new",
    completedCaseStatus: "completed",
    inworkCaseStatus: "in work", 
}

export default caseLabels