import { CategoryType } from "./CategoryType";

const categoriesStub: CategoryType[] = [
    {
        id: "104",
        name: "Компьютеры",
        children: [
            {
                id: "201",
                name: "Мыши",
                children: [
                    {
                        id: "211",
                        name: "Мыши проводные",
                    },
                    {
                        id: "212",
                        name: "Мыши беспроводные",
                    },
                ]
            },
            {
                id: "202",
                name: "Клавиатуры",
            },
            {
                id: "203",
                name: "Мониторы",
            },
        ]
    },
    {
        id: "105",
        name: "Ноутбуки",
    },
    {
        id: "106",
        name: "Комплектующие",
        children: [
            {
                id: "301",
                name: "Процессоры",
            },
            {
                id: "302",
                name: "Материнские платы",
            },
            {
                id: "303",
                name: "Видеокарты",
            },
        ]
    },
    {
        id: "107",
        name: "Сотовые телефоны",
        children: [
            {
                id: "401",
                name: "Смартфоны",
            },
            {
                id: "402",
                name: "IPhone",
            },
            {
                id: "403",
                name: "Кнопочные телефоны",
            },
        ]
    },
    {
        id: "108",
        name: "Планшеты",
    },
    {
        id: "109",
        name: "ПО",
        children: [
            {
                id: "501",
                name: "Офисные программы",
            },
            {
                id: "502",
                name: "Компьютерные программы",
                children: [
                    {
                        id: "511",
                        name: "Windows",
                    },
                    {
                        id: "512",
                        name: "Linux",
                    },
                ]
            },
        ]
    },
    {
        id: "110",
        name: "Аксессуары",
    },
]

export default categoriesStub