import { useContext } from 'react'

import CrossIcon from '../../icons/crossIcon'
import { Pa321Context, Pa321DispatchContext } from '../../../../services/pa321Service/pa321Service'
import { LanguageContext } from '../../../../language/LanguageContext'

export default function CloseCase({ id }: { id: number }) {
    
    const { t } = useContext(LanguageContext)

    const { closeCase } = useContext(Pa321DispatchContext)
    const { caseItem } = useContext(Pa321Context)
    const { status } = caseItem

    const confirmMessage = t("closeCaseConfirm")

    const onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        const confirm = window.confirm(confirmMessage)
        if (!confirm) return
        closeCase(id)
    }

    return (
        <button className="case-close-btn" title={t("closeCase")} onClick={onClick} disabled={status==="loading"}>
            <div>{t("close")}</div>
            <CrossIcon />
        </button>
    )
}