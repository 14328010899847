import { useNavigate } from 'react-router-dom'
import { Offcanvas } from 'react-bootstrap'
import { useContext, useEffect, useState } from 'react'

import PersonIcon from '../icons/personIcon'
import HomeIcon from '../icons/homeIcon'
import BasketIcon from '../icons/basketIcon'
import CategoryTree from '../catalog/categories/tree'
import MarketIcon from '../../pa321/menu/icons/marketIcon'
import { StoreDispatchContext } from '../../../services/storeService/storeService'
import { LanguageContext } from '../../../language/LanguageContext'
import useFreeSpace from '../../../hooks/useFreeSpace'

export default function Sidebar() {
    const navigate = useNavigate()
    const { t } = useContext(LanguageContext)

    const { containerRef, innerRefs, freeSpace, getFreeSpace, getListStyle } = useFreeSpace()

    const { getTotalQuantity } = useContext(StoreDispatchContext)

    useEffect(() => {
        getFreeSpace()
    }, [])

    const [show, setShow] = useState(false)

    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)

    const totalQuantity = getTotalQuantity()
    return (
        <div className="catalog-sidebar" ref={containerRef}>
            <div className="catalog-sidebar-header" ref={el => innerRefs.current[0] = el}>
                <div><button onClick={() => navigate("/store/cart")} title={t("cart")} ><BasketIcon />{totalQuantity || ""}</button></div>
                <div className="d-lg-none"><button onClick={handleShow} title={t("catalog")} ><MarketIcon /></button></div>
                <div><button onClick={() => navigate("/store")} title={t("vendors")} ><HomeIcon /></button></div>
                <div><button onClick={() => navigate("/cases")} title={t("toHome")} ><PersonIcon /></button></div>
            </div>
            <Offcanvas show={show} onHide={handleClose} responsive="lg" placement="start">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>{t("categories")}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <CategoryTree style={show ? {} : getListStyle(freeSpace)} closeOffcanvas={show ? handleClose : undefined} />
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    )
}