const messageLabels = {
    message: "Message",
    messages: "Messages",
    toMessage: "To message",
    viewMessages: "View messages",
    forCase: "Messages for case",
    send: "Send",
    scrollDown: "Scroll down",
}

export default messageLabels