const filterLabels = {
    applyFilter: "Применить",
    saveFilter: "Сохранить настройки фильтра",
    resetFilter: "Сбросить",
    status: "статус",
    start_date: "дата начала",
    from: "от",
    to: "до",
}

export default filterLabels