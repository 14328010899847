import { useContext, useEffect, useState } from 'react'

import Vendor from './views/shortView'
import { VendorType } from './VendorType'
import vendorsStub from './vendorsStub'
import { LanguageContext } from '../../../../language/LanguageContext'
import useFreeSpace from '../../../../hooks/useFreeSpace'

export default function Vendors() {

    const { t } = useContext(LanguageContext)
    const { containerRef, innerRefs, getFreeSpace, getListStyle } = useFreeSpace()

    const [vendors, setVendors] = useState<VendorType[]>(vendorsStub)

    useEffect(() => {
        getFreeSpace()
    }, [vendors])

    return (
        <div className="vendors-page" ref={containerRef}>
            <div className="header" ref={el=> innerRefs.current[0] = el}>
                {t("vendorsHeaderText")}
            </div>
            <div className="vendors" style={getListStyle()}>
                {
                    vendors.map((vendor: VendorType) => (
                        <Vendor key={vendor.id} {...vendor} />
                    ))
                }
            </div>
        </div>
    )
}