const cartLabels = {
    cart: "Cart",
    addToCart: "Add to cart",
    addMore: "Add more",
    putAway: "Put away",    
    cartEmpty: "Cart is empty",
    sum: "Sum",
    cartItemRemoveConfirm: "Are you sure you want to remove this item from the cart?",
    remove: "Remove",
}

export default cartLabels