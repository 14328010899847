import { useContext, useEffect, useState } from 'react'

import Order from './views/detailView'
import { stubOrders } from './stubOrders'
import OrdersPanel from './panel'
import { OrderType } from './OrderType'
import useFreeSpace from '../../../hooks/useFreeSpace'
import { FilterDispatchContext, FilterContext } from '../filter/FilterProvider'

function Orders() {

  const dumbData: OrderType[] = stubOrders

  const [orders, setOrders] = useState(dumbData)
  const [activeOrderId, setActiveOrderId] = useState<number | undefined>(undefined)
  const { containerRef, innerRefs, getFreeSpace, getListStyle } = useFreeSpace()

  const [filterredData, setFilterredData] = useState<OrderType[]>(orders)
  const { filterApplied, filterData } = useContext(FilterDispatchContext)
  const { process } = useContext(FilterContext)

  useEffect(() => {
    getFreeSpace()
  }, [orders])

  useEffect(() => {
    if (process === "apply") {
        setFilterredData(orders.filter(filterData)) 
        filterApplied()
    }
}, [process, orders]) 

  return (
    <div className="orders" ref={containerRef}>
      <OrdersPanel total={orders.length} ref={el => innerRefs.current[0] = el} />
      <div className="orders-list" style={getListStyle()}>
        {
          filterredData?.map((order: OrderType) => (
            <Order
              key={order.id}
              {...order}
              active={order.id === activeOrderId}
              setActive={setActiveOrderId}
            />
          ))
        }
      </div>
    </div>
  )
}

export default Orders