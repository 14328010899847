import { useContext } from 'react'

import Counter from './counter'
import BasketIcon from '../icons/basketIcon'
import { ProductType } from '../catalog/products/ProductType'
import { StoreDispatchContext } from '../../../services/storeService/storeService'
import { LanguageContext } from '../../../language/LanguageContext'

export default function AddToCart({ product, showIcon=true }: { product: ProductType, showIcon?: boolean }) {

    const { t } = useContext(LanguageContext)

    const { addToCart, getProductQuantity } = useContext(StoreDispatchContext)

    const quantity = getProductQuantity(product.id)

    if(quantity > 0) {
        return <div className="add-to-cart-btn"><Counter product={product} /><BasketIcon /></div>             
    } else {
        return <button className="add-to-cart-btn" onClick={() => addToCart(product, 1)} title={t("addToCart")}><span className="btn-text">{t("addToCart")}</span><BasketIcon /></button>
    }
} 