import { OrderType } from './OrderType'

export const stubOrders: OrderType[] = [
    {
        "id": 1,
        "status": "inwork",
        "quantity": 9,
        "price": "$955.04",
        "start_date": "10.2.2023",
        "cases" : [23, 24, 25],
        "items": [
            {
                "id": "5C59688D-81D0-8BB9-5554-53406B1A4635",
                "name": "ullamcorper magna. Sed",
                "quantity": 7,
                "price": "$76.74"
            },
            {
                "id": "E6764408-C8CD-8484-331C-34D112E05352",
                "name": "in, dolor. Fusce",
                "quantity": 7,
                "price": "$68.74"
            },
            {
                "id": "BF5C3E92-6835-2C5F-3D11-79D134082887",
                "name": "nunc. In at",
                "quantity": 9,
                "price": "$27.77"
            },
            {
                "id": "D2E6355C-7F16-DBC7-51A3-65A4D43AB9C2",
                "name": "erat vitae risus.",
                "quantity": 11,
                "price": "$59.80"
            },
            {
                "id": "87382741-6DD7-FC8E-9D2D-722DA3575BAC",
                "name": "arcu imperdiet ullamcorper.",
                "quantity": 7,
                "price": "$87.30"
            },
            {
                "id": "74BA864B-376F-F1D0-C6FB-BFDF4EEDBF92",
                "name": "sed, sapien. Nunc",
                "quantity": 9,
                "price": "$46.74"
            },
            {
                "id": "A5B42FFF-CA1A-C40E-26AC-0ED6503791BE",
                "name": "congue a, aliquet",
                "quantity": 8,
                "price": "$77.19"
            },
            {
                "id": "8E5C1332-21F4-62D5-7BCF-8BAC306702DD",
                "name": "nec tempus mauris",
                "quantity": 2,
                "price": "$84.65"
            },
            {
                "id": "5CAE2E28-B772-02EE-55F1-3C723363372B",
                "name": "iaculis nec, eleifend",
                "quantity": 14,
                "price": "$96.61"
            },
            {
                "id": "A2B55FEE-16BA-EA49-35FF-8D29B04E041A",
                "name": "lectus sit amet",
                "quantity": 6,
                "price": "$28.48"
            }
        ],
        "files": [
            {
                "id": "76684834-876E-1E99-EFC4-F4A3C88E9D39",
                "file_name": "varius.",
                "created_at": "23.8.2023",
                "note": "urna suscipit nonummy. Fusce fermentum fermentum arcu. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae Phasellus ornare. Fusce mollis."
            },
            {
                "id": "DC76A55C-E622-784D-6C0D-360CED944575",
                "file_name": "mollis.",
                "created_at": "17.11.2023",
                "note": "taciti sociosqu ad litora torquent per conubia nostra, per inceptos hymenaeos. Mauris ut quam vel sapien imperdiet ornare. In faucibus."
            },
            {
                "id": "4E4D5C8F-BF43-50A1-9649-B312C2E0E6D9",
                "file_name": "vehicula",
                "created_at": "29.3.2023",
                "note": "pharetra sed,"
            },
            {
                "id": "16AEE9D5-2432-3EC0-AEBB-EDDF3F8E9381",
                "file_name": "elit,",
                "created_at": "23.1.2024",
                "note": "Phasellus in felis. Nulla tempor augue ac ipsum. Phasellus vitae mauris sit amet lorem semper auctor."
            },
            {
                "id": "FB899D84-EBF6-6057-8FA2-17991CD75C34",
                "file_name": "penatibus",
                "created_at": "11.11.2023",
                "note": "dictum augue malesuada malesuada. Integer id magna et ipsum cursus vestibulum. Mauris magna."
            },
            {
                "id": "9F6493BD-872C-0BDB-209B-CC5A40F6807D",
                "file_name": "Sed",
                "created_at": "14.12.2023",
                "note": "ridiculus mus. Donec dignissim magna a tortor. Nunc commodo auctor"
            },
            {
                "id": "57AE999C-494A-9E7A-0149-7B82C2190258",
                "file_name": "sodales.",
                "created_at": "6.10.2023",
                "note": "Donec non justo. Proin non massa non ante"
            },
            {
                "id": "916743BA-38F3-4084-C192-EA42C62485A9",
                "file_name": "a",
                "created_at": "27.2.2023",
                "note": "commodo hendrerit."
            },
            {
                "id": "CD42ED21-2CB1-C5B9-8CB2-5DCA47DD72D7",
                "file_name": "nec",
                "created_at": "19.8.2023",
                "note": "eu, ultrices sit amet, risus. Donec nibh enim, gravida sit amet, dapibus id, blandit"
            },
            {
                "id": "F0E22F0A-17C2-D4AA-82C8-1B93FDD1B58B",
                "file_name": "et",
                "created_at": "13.7.2023",
                "note": "enim. Mauris quis turpis vitae purus gravida sagittis."
            },
            {
                "id": "9B834872-2D9F-C4E3-F2C7-C0DA025CA538",
                "file_name": "erat",
                "created_at": "16.12.2023",
                "note": "nisi. Mauris nulla. Integer urna."
            },
            {
                "id": "C8A5BDA6-E5F6-28EA-5BAE-AA2E649D4B64",
                "file_name": "ut,",
                "created_at": "31.1.2024",
                "note": "Mauris vel turpis. Aliquam adipiscing lobortis risus. In mi pede, nonummy ut, molestie in, tempus eu,"
            },
            {
                "id": "03BBBEB5-21D4-66AA-E6EE-6F374B68912B",
                "file_name": "sem,",
                "created_at": "12.1.2024",
                "note": "ante, iaculis nec, eleifend non, dapibus rutrum, justo. Praesent luctus. Curabitur egestas nunc sed"
            },
            {
                "id": "6FBA3707-23BD-37AA-31B6-2A1164A1E753",
                "file_name": "ac,",
                "created_at": "31.10.2023",
                "note": "neque pellentesque massa lobortis ultrices. Vivamus rhoncus. Donec est. Nunc ullamcorper, velit in"
            },
            {
                "id": "BBCF7DB7-8893-A9DE-985A-56BE8D32646B",
                "file_name": "mauris.",
                "created_at": "17.12.2023",
                "note": "luctus ut, pellentesque eget, dictum placerat, augue. Sed molestie. Sed id risus quis diam luctus lobortis. Class aptent taciti sociosqu ad litora torquent"
            }
        ]
    },
    {
        "id": 2,
        "status": "cancelled",
        "quantity": 11,
        "price": "$716.18",
        "start_date": "8.6.2024",
        "cases": [20, 19, 111],
        "items": [
            {
                "id": "5C59688D-81D0-8BB9-5554-53406B1A4635",
                "name": "ullamcorper magna. Sed",
                "quantity": 7,
                "price": "$76.74"
            },
            {
                "id": "E6764408-C8CD-8484-331C-34D112E05352",
                "name": "in, dolor. Fusce",
                "quantity": 7,
                "price": "$68.74"
            },
            {
                "id": "BF5C3E92-6835-2C5F-3D11-79D134082887",
                "name": "nunc. In at",
                "quantity": 9,
                "price": "$27.77"
            },
            {
                "id": "D2E6355C-7F16-DBC7-51A3-65A4D43AB9C2",
                "name": "erat vitae risus.",
                "quantity": 11,
                "price": "$59.80"
            },
            {
                "id": "87382741-6DD7-FC8E-9D2D-722DA3575BAC",
                "name": "arcu imperdiet ullamcorper.",
                "quantity": 7,
                "price": "$87.30"
            },
            {
                "id": "74BA864B-376F-F1D0-C6FB-BFDF4EEDBF92",
                "name": "sed, sapien. Nunc",
                "quantity": 9,
                "price": "$46.74"
            },
            {
                "id": "A5B42FFF-CA1A-C40E-26AC-0ED6503791BE",
                "name": "congue a, aliquet",
                "quantity": 8,
                "price": "$77.19"
            },
            {
                "id": "8E5C1332-21F4-62D5-7BCF-8BAC306702DD",
                "name": "nec tempus mauris",
                "quantity": 2,
                "price": "$84.65"
            },
            {
                "id": "5CAE2E28-B772-02EE-55F1-3C723363372B",
                "name": "iaculis nec, eleifend",
                "quantity": 14,
                "price": "$96.61"
            },
            {
                "id": "A2B55FEE-16BA-EA49-35FF-8D29B04E041A",
                "name": "lectus sit amet",
                "quantity": 6,
                "price": "$28.48"
            }
        ],
        "files": [
            {
                "id": "76684834-876E-1E99-EFC4-F4A3C88E9D39",
                "file_name": "varius.",
                "created_at": "23.8.2023",
                "note": "urna suscipit nonummy. Fusce fermentum fermentum arcu. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae Phasellus ornare. Fusce mollis."
            },
            {
                "id": "DC76A55C-E622-784D-6C0D-360CED944575",
                "file_name": "mollis.",
                "created_at": "17.11.2023",
                "note": "taciti sociosqu ad litora torquent per conubia nostra, per inceptos hymenaeos. Mauris ut quam vel sapien imperdiet ornare. In faucibus."
            },
            {
                "id": "4E4D5C8F-BF43-50A1-9649-B312C2E0E6D9",
                "file_name": "vehicula",
                "created_at": "29.3.2023",
                "note": "pharetra sed,"
            },
            {
                "id": "16AEE9D5-2432-3EC0-AEBB-EDDF3F8E9381",
                "file_name": "elit,",
                "created_at": "23.1.2024",
                "note": "Phasellus in felis. Nulla tempor augue ac ipsum. Phasellus vitae mauris sit amet lorem semper auctor."
            },
            {
                "id": "FB899D84-EBF6-6057-8FA2-17991CD75C34",
                "file_name": "penatibus",
                "created_at": "11.11.2023",
                "note": "dictum augue malesuada malesuada. Integer id magna et ipsum cursus vestibulum. Mauris magna."
            },
            {
                "id": "9F6493BD-872C-0BDB-209B-CC5A40F6807D",
                "file_name": "Sed",
                "created_at": "14.12.2023",
                "note": "ridiculus mus. Donec dignissim magna a tortor. Nunc commodo auctor"
            },
            {
                "id": "57AE999C-494A-9E7A-0149-7B82C2190258",
                "file_name": "sodales.",
                "created_at": "6.10.2023",
                "note": "Donec non justo. Proin non massa non ante"
            },
            {
                "id": "916743BA-38F3-4084-C192-EA42C62485A9",
                "file_name": "a",
                "created_at": "27.2.2023",
                "note": "commodo hendrerit."
            },
            {
                "id": "CD42ED21-2CB1-C5B9-8CB2-5DCA47DD72D7",
                "file_name": "nec",
                "created_at": "19.8.2023",
                "note": "eu, ultrices sit amet, risus. Donec nibh enim, gravida sit amet, dapibus id, blandit"
            },
            {
                "id": "F0E22F0A-17C2-D4AA-82C8-1B93FDD1B58B",
                "file_name": "et",
                "created_at": "13.7.2023",
                "note": "enim. Mauris quis turpis vitae purus gravida sagittis."
            },
            {
                "id": "9B834872-2D9F-C4E3-F2C7-C0DA025CA538",
                "file_name": "erat",
                "created_at": "16.12.2023",
                "note": "nisi. Mauris nulla. Integer urna."
            },
            {
                "id": "C8A5BDA6-E5F6-28EA-5BAE-AA2E649D4B64",
                "file_name": "ut,",
                "created_at": "31.1.2024",
                "note": "Mauris vel turpis. Aliquam adipiscing lobortis risus. In mi pede, nonummy ut, molestie in, tempus eu,"
            },
            {
                "id": "03BBBEB5-21D4-66AA-E6EE-6F374B68912B",
                "file_name": "sem,",
                "created_at": "12.1.2024",
                "note": "ante, iaculis nec, eleifend non, dapibus rutrum, justo. Praesent luctus. Curabitur egestas nunc sed"
            },
            {
                "id": "6FBA3707-23BD-37AA-31B6-2A1164A1E753",
                "file_name": "ac,",
                "created_at": "31.10.2023",
                "note": "neque pellentesque massa lobortis ultrices. Vivamus rhoncus. Donec est. Nunc ullamcorper, velit in"
            },
            {
                "id": "BBCF7DB7-8893-A9DE-985A-56BE8D32646B",
                "file_name": "mauris.",
                "created_at": "17.12.2023",
                "note": "luctus ut, pellentesque eget, dictum placerat, augue. Sed molestie. Sed id risus quis diam luctus lobortis. Class aptent taciti sociosqu ad litora torquent"
            }
        ]
    },
    {
        "id": 3,
        "status": "inwork",
        "quantity": 10,
        "price": "$924.44",
        "start_date": "14.6.2024",
        "cases":[16, 15, 14],
        "items": [
            {
                "id": "5C59688D-81D0-8BB9-5554-53406B1A4635",
                "name": "ullamcorper magna. Sed",
                "quantity": 7,
                "price": "$76.74"
            },
            {
                "id": "E6764408-C8CD-8484-331C-34D112E05352",
                "name": "in, dolor. Fusce",
                "quantity": 7,
                "price": "$68.74"
            },
            {
                "id": "BF5C3E92-6835-2C5F-3D11-79D134082887",
                "name": "nunc. In at",
                "quantity": 9,
                "price": "$27.77"
            },
            {
                "id": "D2E6355C-7F16-DBC7-51A3-65A4D43AB9C2",
                "name": "erat vitae risus.",
                "quantity": 11,
                "price": "$59.80"
            },
            {
                "id": "87382741-6DD7-FC8E-9D2D-722DA3575BAC",
                "name": "arcu imperdiet ullamcorper.",
                "quantity": 7,
                "price": "$87.30"
            },
            {
                "id": "74BA864B-376F-F1D0-C6FB-BFDF4EEDBF92",
                "name": "sed, sapien. Nunc",
                "quantity": 9,
                "price": "$46.74"
            },
            {
                "id": "A5B42FFF-CA1A-C40E-26AC-0ED6503791BE",
                "name": "congue a, aliquet",
                "quantity": 8,
                "price": "$77.19"
            },
            {
                "id": "8E5C1332-21F4-62D5-7BCF-8BAC306702DD",
                "name": "nec tempus mauris",
                "quantity": 2,
                "price": "$84.65"
            },
            {
                "id": "5CAE2E28-B772-02EE-55F1-3C723363372B",
                "name": "iaculis nec, eleifend",
                "quantity": 14,
                "price": "$96.61"
            },
            {
                "id": "A2B55FEE-16BA-EA49-35FF-8D29B04E041A",
                "name": "lectus sit amet",
                "quantity": 6,
                "price": "$28.48"
            }
        ],
        "files": [
            {
                "id": "76684834-876E-1E99-EFC4-F4A3C88E9D39",
                "file_name": "varius.",
                "created_at": "23.8.2023",
                "note": "urna suscipit nonummy. Fusce fermentum fermentum arcu. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae Phasellus ornare. Fusce mollis."
            },
            {
                "id": "DC76A55C-E622-784D-6C0D-360CED944575",
                "file_name": "mollis.",
                "created_at": "17.11.2023",
                "note": "taciti sociosqu ad litora torquent per conubia nostra, per inceptos hymenaeos. Mauris ut quam vel sapien imperdiet ornare. In faucibus."
            },
            {
                "id": "4E4D5C8F-BF43-50A1-9649-B312C2E0E6D9",
                "file_name": "vehicula",
                "created_at": "29.3.2023",
                "note": "pharetra sed,"
            },
            {
                "id": "16AEE9D5-2432-3EC0-AEBB-EDDF3F8E9381",
                "file_name": "elit,",
                "created_at": "23.1.2024",
                "note": "Phasellus in felis. Nulla tempor augue ac ipsum. Phasellus vitae mauris sit amet lorem semper auctor."
            },
            {
                "id": "FB899D84-EBF6-6057-8FA2-17991CD75C34",
                "file_name": "penatibus",
                "created_at": "11.11.2023",
                "note": "dictum augue malesuada malesuada. Integer id magna et ipsum cursus vestibulum. Mauris magna."
            },
            {
                "id": "9F6493BD-872C-0BDB-209B-CC5A40F6807D",
                "file_name": "Sed",
                "created_at": "14.12.2023",
                "note": "ridiculus mus. Donec dignissim magna a tortor. Nunc commodo auctor"
            },
            {
                "id": "57AE999C-494A-9E7A-0149-7B82C2190258",
                "file_name": "sodales.",
                "created_at": "6.10.2023",
                "note": "Donec non justo. Proin non massa non ante"
            },
            {
                "id": "916743BA-38F3-4084-C192-EA42C62485A9",
                "file_name": "a",
                "created_at": "27.2.2023",
                "note": "commodo hendrerit."
            },
            {
                "id": "CD42ED21-2CB1-C5B9-8CB2-5DCA47DD72D7",
                "file_name": "nec",
                "created_at": "19.8.2023",
                "note": "eu, ultrices sit amet, risus. Donec nibh enim, gravida sit amet, dapibus id, blandit"
            },
            {
                "id": "F0E22F0A-17C2-D4AA-82C8-1B93FDD1B58B",
                "file_name": "et",
                "created_at": "13.7.2023",
                "note": "enim. Mauris quis turpis vitae purus gravida sagittis."
            },
            {
                "id": "9B834872-2D9F-C4E3-F2C7-C0DA025CA538",
                "file_name": "erat",
                "created_at": "16.12.2023",
                "note": "nisi. Mauris nulla. Integer urna."
            },
            {
                "id": "C8A5BDA6-E5F6-28EA-5BAE-AA2E649D4B64",
                "file_name": "ut,",
                "created_at": "31.1.2024",
                "note": "Mauris vel turpis. Aliquam adipiscing lobortis risus. In mi pede, nonummy ut, molestie in, tempus eu,"
            },
            {
                "id": "03BBBEB5-21D4-66AA-E6EE-6F374B68912B",
                "file_name": "sem,",
                "created_at": "12.1.2024",
                "note": "ante, iaculis nec, eleifend non, dapibus rutrum, justo. Praesent luctus. Curabitur egestas nunc sed"
            },
            {
                "id": "6FBA3707-23BD-37AA-31B6-2A1164A1E753",
                "file_name": "ac,",
                "created_at": "31.10.2023",
                "note": "neque pellentesque massa lobortis ultrices. Vivamus rhoncus. Donec est. Nunc ullamcorper, velit in"
            },
            {
                "id": "BBCF7DB7-8893-A9DE-985A-56BE8D32646B",
                "file_name": "mauris.",
                "created_at": "17.12.2023",
                "note": "luctus ut, pellentesque eget, dictum placerat, augue. Sed molestie. Sed id risus quis diam luctus lobortis. Class aptent taciti sociosqu ad litora torquent"
            }
        ]
    },
    {
        "id": 4,
        "status": "cancelled",
        "quantity": 1,
        "price": "$875.07",
        "start_date": "11.7.2023",
        "items": [
            {
                "id": "5C59688D-81D0-8BB9-5554-53406B1A4635",
                "name": "ullamcorper magna. Sed",
                "quantity": 7,
                "price": "$76.74"
            },
            {
                "id": "E6764408-C8CD-8484-331C-34D112E05352",
                "name": "in, dolor. Fusce",
                "quantity": 7,
                "price": "$68.74"
            },
            {
                "id": "BF5C3E92-6835-2C5F-3D11-79D134082887",
                "name": "nunc. In at",
                "quantity": 9,
                "price": "$27.77"
            },
            {
                "id": "D2E6355C-7F16-DBC7-51A3-65A4D43AB9C2",
                "name": "erat vitae risus.",
                "quantity": 11,
                "price": "$59.80"
            },
            {
                "id": "87382741-6DD7-FC8E-9D2D-722DA3575BAC",
                "name": "arcu imperdiet ullamcorper.",
                "quantity": 7,
                "price": "$87.30"
            },
            {
                "id": "74BA864B-376F-F1D0-C6FB-BFDF4EEDBF92",
                "name": "sed, sapien. Nunc",
                "quantity": 9,
                "price": "$46.74"
            },
            {
                "id": "A5B42FFF-CA1A-C40E-26AC-0ED6503791BE",
                "name": "congue a, aliquet",
                "quantity": 8,
                "price": "$77.19"
            },
            {
                "id": "8E5C1332-21F4-62D5-7BCF-8BAC306702DD",
                "name": "nec tempus mauris",
                "quantity": 2,
                "price": "$84.65"
            },
            {
                "id": "5CAE2E28-B772-02EE-55F1-3C723363372B",
                "name": "iaculis nec, eleifend",
                "quantity": 14,
                "price": "$96.61"
            },
            {
                "id": "A2B55FEE-16BA-EA49-35FF-8D29B04E041A",
                "name": "lectus sit amet",
                "quantity": 6,
                "price": "$28.48"
            }
        ],
        "files": [
            {
                "id": "76684834-876E-1E99-EFC4-F4A3C88E9D39",
                "file_name": "varius.",
                "created_at": "23.8.2023",
                "note": "urna suscipit nonummy. Fusce fermentum fermentum arcu. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae Phasellus ornare. Fusce mollis."
            },
            {
                "id": "DC76A55C-E622-784D-6C0D-360CED944575",
                "file_name": "mollis.",
                "created_at": "17.11.2023",
                "note": "taciti sociosqu ad litora torquent per conubia nostra, per inceptos hymenaeos. Mauris ut quam vel sapien imperdiet ornare. In faucibus."
            },
            {
                "id": "4E4D5C8F-BF43-50A1-9649-B312C2E0E6D9",
                "file_name": "vehicula",
                "created_at": "29.3.2023",
                "note": "pharetra sed,"
            },
            {
                "id": "16AEE9D5-2432-3EC0-AEBB-EDDF3F8E9381",
                "file_name": "elit,",
                "created_at": "23.1.2024",
                "note": "Phasellus in felis. Nulla tempor augue ac ipsum. Phasellus vitae mauris sit amet lorem semper auctor."
            },
            {
                "id": "FB899D84-EBF6-6057-8FA2-17991CD75C34",
                "file_name": "penatibus",
                "created_at": "11.11.2023",
                "note": "dictum augue malesuada malesuada. Integer id magna et ipsum cursus vestibulum. Mauris magna."
            },
            {
                "id": "9F6493BD-872C-0BDB-209B-CC5A40F6807D",
                "file_name": "Sed",
                "created_at": "14.12.2023",
                "note": "ridiculus mus. Donec dignissim magna a tortor. Nunc commodo auctor"
            },
            {
                "id": "57AE999C-494A-9E7A-0149-7B82C2190258",
                "file_name": "sodales.",
                "created_at": "6.10.2023",
                "note": "Donec non justo. Proin non massa non ante"
            },
            {
                "id": "916743BA-38F3-4084-C192-EA42C62485A9",
                "file_name": "a",
                "created_at": "27.2.2023",
                "note": "commodo hendrerit."
            },
            {
                "id": "CD42ED21-2CB1-C5B9-8CB2-5DCA47DD72D7",
                "file_name": "nec",
                "created_at": "19.8.2023",
                "note": "eu, ultrices sit amet, risus. Donec nibh enim, gravida sit amet, dapibus id, blandit"
            },
            {
                "id": "F0E22F0A-17C2-D4AA-82C8-1B93FDD1B58B",
                "file_name": "et",
                "created_at": "13.7.2023",
                "note": "enim. Mauris quis turpis vitae purus gravida sagittis."
            },
            {
                "id": "9B834872-2D9F-C4E3-F2C7-C0DA025CA538",
                "file_name": "erat",
                "created_at": "16.12.2023",
                "note": "nisi. Mauris nulla. Integer urna."
            },
            {
                "id": "C8A5BDA6-E5F6-28EA-5BAE-AA2E649D4B64",
                "file_name": "ut,",
                "created_at": "31.1.2024",
                "note": "Mauris vel turpis. Aliquam adipiscing lobortis risus. In mi pede, nonummy ut, molestie in, tempus eu,"
            },
            {
                "id": "03BBBEB5-21D4-66AA-E6EE-6F374B68912B",
                "file_name": "sem,",
                "created_at": "12.1.2024",
                "note": "ante, iaculis nec, eleifend non, dapibus rutrum, justo. Praesent luctus. Curabitur egestas nunc sed"
            },
            {
                "id": "6FBA3707-23BD-37AA-31B6-2A1164A1E753",
                "file_name": "ac,",
                "created_at": "31.10.2023",
                "note": "neque pellentesque massa lobortis ultrices. Vivamus rhoncus. Donec est. Nunc ullamcorper, velit in"
            },
            {
                "id": "BBCF7DB7-8893-A9DE-985A-56BE8D32646B",
                "file_name": "mauris.",
                "created_at": "17.12.2023",
                "note": "luctus ut, pellentesque eget, dictum placerat, augue. Sed molestie. Sed id risus quis diam luctus lobortis. Class aptent taciti sociosqu ad litora torquent"
            }
        ]
    },
    {
        "id": 5,
        "status": "new",
        "quantity": 5,
        "price": "$775.94",
        "start_date": "16.5.2023",
        "items": [
            {
                "id": "5C59688D-81D0-8BB9-5554-53406B1A4635",
                "name": "ullamcorper magna. Sed",
                "quantity": 7,
                "price": "$76.74"
            },
            {
                "id": "E6764408-C8CD-8484-331C-34D112E05352",
                "name": "in, dolor. Fusce",
                "quantity": 7,
                "price": "$68.74"
            },
            {
                "id": "BF5C3E92-6835-2C5F-3D11-79D134082887",
                "name": "nunc. In at",
                "quantity": 9,
                "price": "$27.77"
            },
            {
                "id": "D2E6355C-7F16-DBC7-51A3-65A4D43AB9C2",
                "name": "erat vitae risus.",
                "quantity": 11,
                "price": "$59.80"
            },
            {
                "id": "87382741-6DD7-FC8E-9D2D-722DA3575BAC",
                "name": "arcu imperdiet ullamcorper.",
                "quantity": 7,
                "price": "$87.30"
            },
            {
                "id": "74BA864B-376F-F1D0-C6FB-BFDF4EEDBF92",
                "name": "sed, sapien. Nunc",
                "quantity": 9,
                "price": "$46.74"
            },
            {
                "id": "A5B42FFF-CA1A-C40E-26AC-0ED6503791BE",
                "name": "congue a, aliquet",
                "quantity": 8,
                "price": "$77.19"
            },
            {
                "id": "8E5C1332-21F4-62D5-7BCF-8BAC306702DD",
                "name": "nec tempus mauris",
                "quantity": 2,
                "price": "$84.65"
            },
            {
                "id": "5CAE2E28-B772-02EE-55F1-3C723363372B",
                "name": "iaculis nec, eleifend",
                "quantity": 14,
                "price": "$96.61"
            },
            {
                "id": "A2B55FEE-16BA-EA49-35FF-8D29B04E041A",
                "name": "lectus sit amet",
                "quantity": 6,
                "price": "$28.48"
            }
        ],
        "files": [
            {
                "id": "76684834-876E-1E99-EFC4-F4A3C88E9D39",
                "file_name": "varius.",
                "created_at": "23.8.2023",
                "note": "urna suscipit nonummy. Fusce fermentum fermentum arcu. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae Phasellus ornare. Fusce mollis."
            },
            {
                "id": "DC76A55C-E622-784D-6C0D-360CED944575",
                "file_name": "mollis.",
                "created_at": "17.11.2023",
                "note": "taciti sociosqu ad litora torquent per conubia nostra, per inceptos hymenaeos. Mauris ut quam vel sapien imperdiet ornare. In faucibus."
            },
            {
                "id": "4E4D5C8F-BF43-50A1-9649-B312C2E0E6D9",
                "file_name": "vehicula",
                "created_at": "29.3.2023",
                "note": "pharetra sed,"
            },
            {
                "id": "16AEE9D5-2432-3EC0-AEBB-EDDF3F8E9381",
                "file_name": "elit,",
                "created_at": "23.1.2024",
                "note": "Phasellus in felis. Nulla tempor augue ac ipsum. Phasellus vitae mauris sit amet lorem semper auctor."
            },
            {
                "id": "FB899D84-EBF6-6057-8FA2-17991CD75C34",
                "file_name": "penatibus",
                "created_at": "11.11.2023",
                "note": "dictum augue malesuada malesuada. Integer id magna et ipsum cursus vestibulum. Mauris magna."
            },
            {
                "id": "9F6493BD-872C-0BDB-209B-CC5A40F6807D",
                "file_name": "Sed",
                "created_at": "14.12.2023",
                "note": "ridiculus mus. Donec dignissim magna a tortor. Nunc commodo auctor"
            },
            {
                "id": "57AE999C-494A-9E7A-0149-7B82C2190258",
                "file_name": "sodales.",
                "created_at": "6.10.2023",
                "note": "Donec non justo. Proin non massa non ante"
            },
            {
                "id": "916743BA-38F3-4084-C192-EA42C62485A9",
                "file_name": "a",
                "created_at": "27.2.2023",
                "note": "commodo hendrerit."
            },
            {
                "id": "CD42ED21-2CB1-C5B9-8CB2-5DCA47DD72D7",
                "file_name": "nec",
                "created_at": "19.8.2023",
                "note": "eu, ultrices sit amet, risus. Donec nibh enim, gravida sit amet, dapibus id, blandit"
            },
            {
                "id": "F0E22F0A-17C2-D4AA-82C8-1B93FDD1B58B",
                "file_name": "et",
                "created_at": "13.7.2023",
                "note": "enim. Mauris quis turpis vitae purus gravida sagittis."
            },
            {
                "id": "9B834872-2D9F-C4E3-F2C7-C0DA025CA538",
                "file_name": "erat",
                "created_at": "16.12.2023",
                "note": "nisi. Mauris nulla. Integer urna."
            },
            {
                "id": "C8A5BDA6-E5F6-28EA-5BAE-AA2E649D4B64",
                "file_name": "ut,",
                "created_at": "31.1.2024",
                "note": "Mauris vel turpis. Aliquam adipiscing lobortis risus. In mi pede, nonummy ut, molestie in, tempus eu,"
            },
            {
                "id": "03BBBEB5-21D4-66AA-E6EE-6F374B68912B",
                "file_name": "sem,",
                "created_at": "12.1.2024",
                "note": "ante, iaculis nec, eleifend non, dapibus rutrum, justo. Praesent luctus. Curabitur egestas nunc sed"
            },
            {
                "id": "6FBA3707-23BD-37AA-31B6-2A1164A1E753",
                "file_name": "ac,",
                "created_at": "31.10.2023",
                "note": "neque pellentesque massa lobortis ultrices. Vivamus rhoncus. Donec est. Nunc ullamcorper, velit in"
            },
            {
                "id": "BBCF7DB7-8893-A9DE-985A-56BE8D32646B",
                "file_name": "mauris.",
                "created_at": "17.12.2023",
                "note": "luctus ut, pellentesque eget, dictum placerat, augue. Sed molestie. Sed id risus quis diam luctus lobortis. Class aptent taciti sociosqu ad litora torquent"
            }
        ]
    },
    {
        "id": 6,
        "status": "inwork",
        "quantity": 5,
        "price": "$734.15",
        "start_date": "31.8.2021",
        "items": [
            {
                "id": "5C59688D-81D0-8BB9-5554-53406B1A4635",
                "name": "ullamcorper magna. Sed",
                "quantity": 7,
                "price": "$76.74"
            },
            {
                "id": "E6764408-C8CD-8484-331C-34D112E05352",
                "name": "in, dolor. Fusce",
                "quantity": 7,
                "price": "$68.74"
            },
            {
                "id": "BF5C3E92-6835-2C5F-3D11-79D134082887",
                "name": "nunc. In at",
                "quantity": 9,
                "price": "$27.77"
            },
            {
                "id": "D2E6355C-7F16-DBC7-51A3-65A4D43AB9C2",
                "name": "erat vitae risus.",
                "quantity": 11,
                "price": "$59.80"
            },
            {
                "id": "87382741-6DD7-FC8E-9D2D-722DA3575BAC",
                "name": "arcu imperdiet ullamcorper.",
                "quantity": 7,
                "price": "$87.30"
            },
            {
                "id": "74BA864B-376F-F1D0-C6FB-BFDF4EEDBF92",
                "name": "sed, sapien. Nunc",
                "quantity": 9,
                "price": "$46.74"
            },
            {
                "id": "A5B42FFF-CA1A-C40E-26AC-0ED6503791BE",
                "name": "congue a, aliquet",
                "quantity": 8,
                "price": "$77.19"
            },
            {
                "id": "8E5C1332-21F4-62D5-7BCF-8BAC306702DD",
                "name": "nec tempus mauris",
                "quantity": 2,
                "price": "$84.65"
            },
            {
                "id": "5CAE2E28-B772-02EE-55F1-3C723363372B",
                "name": "iaculis nec, eleifend",
                "quantity": 14,
                "price": "$96.61"
            },
            {
                "id": "A2B55FEE-16BA-EA49-35FF-8D29B04E041A",
                "name": "lectus sit amet",
                "quantity": 6,
                "price": "$28.48"
            }
        ],
        "files": [
            {
                "id": "76684834-876E-1E99-EFC4-F4A3C88E9D39",
                "file_name": "varius.",
                "created_at": "23.8.2023",
                "note": "urna suscipit nonummy. Fusce fermentum fermentum arcu. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae Phasellus ornare. Fusce mollis."
            },
            {
                "id": "DC76A55C-E622-784D-6C0D-360CED944575",
                "file_name": "mollis.",
                "created_at": "17.11.2023",
                "note": "taciti sociosqu ad litora torquent per conubia nostra, per inceptos hymenaeos. Mauris ut quam vel sapien imperdiet ornare. In faucibus."
            },
            {
                "id": "4E4D5C8F-BF43-50A1-9649-B312C2E0E6D9",
                "file_name": "vehicula",
                "created_at": "29.3.2023",
                "note": "pharetra sed,"
            },
            {
                "id": "16AEE9D5-2432-3EC0-AEBB-EDDF3F8E9381",
                "file_name": "elit,",
                "created_at": "23.1.2024",
                "note": "Phasellus in felis. Nulla tempor augue ac ipsum. Phasellus vitae mauris sit amet lorem semper auctor."
            },
            {
                "id": "FB899D84-EBF6-6057-8FA2-17991CD75C34",
                "file_name": "penatibus",
                "created_at": "11.11.2023",
                "note": "dictum augue malesuada malesuada. Integer id magna et ipsum cursus vestibulum. Mauris magna."
            },
            {
                "id": "9F6493BD-872C-0BDB-209B-CC5A40F6807D",
                "file_name": "Sed",
                "created_at": "14.12.2023",
                "note": "ridiculus mus. Donec dignissim magna a tortor. Nunc commodo auctor"
            },
            {
                "id": "57AE999C-494A-9E7A-0149-7B82C2190258",
                "file_name": "sodales.",
                "created_at": "6.10.2023",
                "note": "Donec non justo. Proin non massa non ante"
            },
            {
                "id": "916743BA-38F3-4084-C192-EA42C62485A9",
                "file_name": "a",
                "created_at": "27.2.2023",
                "note": "commodo hendrerit."
            },
            {
                "id": "CD42ED21-2CB1-C5B9-8CB2-5DCA47DD72D7",
                "file_name": "nec",
                "created_at": "19.8.2023",
                "note": "eu, ultrices sit amet, risus. Donec nibh enim, gravida sit amet, dapibus id, blandit"
            },
            {
                "id": "F0E22F0A-17C2-D4AA-82C8-1B93FDD1B58B",
                "file_name": "et",
                "created_at": "13.7.2023",
                "note": "enim. Mauris quis turpis vitae purus gravida sagittis."
            },
            {
                "id": "9B834872-2D9F-C4E3-F2C7-C0DA025CA538",
                "file_name": "erat",
                "created_at": "16.12.2023",
                "note": "nisi. Mauris nulla. Integer urna."
            },
            {
                "id": "C8A5BDA6-E5F6-28EA-5BAE-AA2E649D4B64",
                "file_name": "ut,",
                "created_at": "31.1.2024",
                "note": "Mauris vel turpis. Aliquam adipiscing lobortis risus. In mi pede, nonummy ut, molestie in, tempus eu,"
            },
            {
                "id": "03BBBEB5-21D4-66AA-E6EE-6F374B68912B",
                "file_name": "sem,",
                "created_at": "12.1.2024",
                "note": "ante, iaculis nec, eleifend non, dapibus rutrum, justo. Praesent luctus. Curabitur egestas nunc sed"
            },
            {
                "id": "6FBA3707-23BD-37AA-31B6-2A1164A1E753",
                "file_name": "ac,",
                "created_at": "31.10.2023",
                "note": "neque pellentesque massa lobortis ultrices. Vivamus rhoncus. Donec est. Nunc ullamcorper, velit in"
            },
            {
                "id": "BBCF7DB7-8893-A9DE-985A-56BE8D32646B",
                "file_name": "mauris.",
                "created_at": "17.12.2023",
                "note": "luctus ut, pellentesque eget, dictum placerat, augue. Sed molestie. Sed id risus quis diam luctus lobortis. Class aptent taciti sociosqu ad litora torquent"
            }
        ]
    },
    {
        "id": 7,
        "status": "inwork",
        "quantity": 3,
        "price": "$557.76",
        "start_date": "2.6.2023",
        "items": [
            {
                "id": "5C59688D-81D0-8BB9-5554-53406B1A4635",
                "name": "ullamcorper magna. Sed",
                "quantity": 7,
                "price": "$76.74"
            },
            {
                "id": "E6764408-C8CD-8484-331C-34D112E05352",
                "name": "in, dolor. Fusce",
                "quantity": 7,
                "price": "$68.74"
            },
            {
                "id": "BF5C3E92-6835-2C5F-3D11-79D134082887",
                "name": "nunc. In at",
                "quantity": 9,
                "price": "$27.77"
            },
            {
                "id": "D2E6355C-7F16-DBC7-51A3-65A4D43AB9C2",
                "name": "erat vitae risus.",
                "quantity": 11,
                "price": "$59.80"
            },
            {
                "id": "87382741-6DD7-FC8E-9D2D-722DA3575BAC",
                "name": "arcu imperdiet ullamcorper.",
                "quantity": 7,
                "price": "$87.30"
            },
            {
                "id": "74BA864B-376F-F1D0-C6FB-BFDF4EEDBF92",
                "name": "sed, sapien. Nunc",
                "quantity": 9,
                "price": "$46.74"
            },
            {
                "id": "A5B42FFF-CA1A-C40E-26AC-0ED6503791BE",
                "name": "congue a, aliquet",
                "quantity": 8,
                "price": "$77.19"
            },
            {
                "id": "8E5C1332-21F4-62D5-7BCF-8BAC306702DD",
                "name": "nec tempus mauris",
                "quantity": 2,
                "price": "$84.65"
            },
            {
                "id": "5CAE2E28-B772-02EE-55F1-3C723363372B",
                "name": "iaculis nec, eleifend",
                "quantity": 14,
                "price": "$96.61"
            },
            {
                "id": "A2B55FEE-16BA-EA49-35FF-8D29B04E041A",
                "name": "lectus sit amet",
                "quantity": 6,
                "price": "$28.48"
            }
        ],
        "files": [
            {
                "id": "76684834-876E-1E99-EFC4-F4A3C88E9D39",
                "file_name": "varius.",
                "created_at": "23.8.2023",
                "note": "urna suscipit nonummy. Fusce fermentum fermentum arcu. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae Phasellus ornare. Fusce mollis."
            },
            {
                "id": "DC76A55C-E622-784D-6C0D-360CED944575",
                "file_name": "mollis.",
                "created_at": "17.11.2023",
                "note": "taciti sociosqu ad litora torquent per conubia nostra, per inceptos hymenaeos. Mauris ut quam vel sapien imperdiet ornare. In faucibus."
            },
            {
                "id": "4E4D5C8F-BF43-50A1-9649-B312C2E0E6D9",
                "file_name": "vehicula",
                "created_at": "29.3.2023",
                "note": "pharetra sed,"
            },
            {
                "id": "16AEE9D5-2432-3EC0-AEBB-EDDF3F8E9381",
                "file_name": "elit,",
                "created_at": "23.1.2024",
                "note": "Phasellus in felis. Nulla tempor augue ac ipsum. Phasellus vitae mauris sit amet lorem semper auctor."
            },
            {
                "id": "FB899D84-EBF6-6057-8FA2-17991CD75C34",
                "file_name": "penatibus",
                "created_at": "11.11.2023",
                "note": "dictum augue malesuada malesuada. Integer id magna et ipsum cursus vestibulum. Mauris magna."
            },
            {
                "id": "9F6493BD-872C-0BDB-209B-CC5A40F6807D",
                "file_name": "Sed",
                "created_at": "14.12.2023",
                "note": "ridiculus mus. Donec dignissim magna a tortor. Nunc commodo auctor"
            },
            {
                "id": "57AE999C-494A-9E7A-0149-7B82C2190258",
                "file_name": "sodales.",
                "created_at": "6.10.2023",
                "note": "Donec non justo. Proin non massa non ante"
            },
            {
                "id": "916743BA-38F3-4084-C192-EA42C62485A9",
                "file_name": "a",
                "created_at": "27.2.2023",
                "note": "commodo hendrerit."
            },
            {
                "id": "CD42ED21-2CB1-C5B9-8CB2-5DCA47DD72D7",
                "file_name": "nec",
                "created_at": "19.8.2023",
                "note": "eu, ultrices sit amet, risus. Donec nibh enim, gravida sit amet, dapibus id, blandit"
            },
            {
                "id": "F0E22F0A-17C2-D4AA-82C8-1B93FDD1B58B",
                "file_name": "et",
                "created_at": "13.7.2023",
                "note": "enim. Mauris quis turpis vitae purus gravida sagittis."
            },
            {
                "id": "9B834872-2D9F-C4E3-F2C7-C0DA025CA538",
                "file_name": "erat",
                "created_at": "16.12.2023",
                "note": "nisi. Mauris nulla. Integer urna."
            },
            {
                "id": "C8A5BDA6-E5F6-28EA-5BAE-AA2E649D4B64",
                "file_name": "ut,",
                "created_at": "31.1.2024",
                "note": "Mauris vel turpis. Aliquam adipiscing lobortis risus. In mi pede, nonummy ut, molestie in, tempus eu,"
            },
            {
                "id": "03BBBEB5-21D4-66AA-E6EE-6F374B68912B",
                "file_name": "sem,",
                "created_at": "12.1.2024",
                "note": "ante, iaculis nec, eleifend non, dapibus rutrum, justo. Praesent luctus. Curabitur egestas nunc sed"
            },
            {
                "id": "6FBA3707-23BD-37AA-31B6-2A1164A1E753",
                "file_name": "ac,",
                "created_at": "31.10.2023",
                "note": "neque pellentesque massa lobortis ultrices. Vivamus rhoncus. Donec est. Nunc ullamcorper, velit in"
            },
            {
                "id": "BBCF7DB7-8893-A9DE-985A-56BE8D32646B",
                "file_name": "mauris.",
                "created_at": "17.12.2023",
                "note": "luctus ut, pellentesque eget, dictum placerat, augue. Sed molestie. Sed id risus quis diam luctus lobortis. Class aptent taciti sociosqu ad litora torquent"
            }
        ]
    },
    {
        "id": 8,
        "status": "new",
        "quantity": 1,
        "price": "$725.55",
        "start_date": "19.2.2023",
        "items": [
            {
                "id": "5C59688D-81D0-8BB9-5554-53406B1A4635",
                "name": "ullamcorper magna. Sed",
                "quantity": 7,
                "price": "$76.74"
            },
            {
                "id": "E6764408-C8CD-8484-331C-34D112E05352",
                "name": "in, dolor. Fusce",
                "quantity": 7,
                "price": "$68.74"
            },
            {
                "id": "BF5C3E92-6835-2C5F-3D11-79D134082887",
                "name": "nunc. In at",
                "quantity": 9,
                "price": "$27.77"
            },
            {
                "id": "D2E6355C-7F16-DBC7-51A3-65A4D43AB9C2",
                "name": "erat vitae risus.",
                "quantity": 11,
                "price": "$59.80"
            },
            {
                "id": "87382741-6DD7-FC8E-9D2D-722DA3575BAC",
                "name": "arcu imperdiet ullamcorper.",
                "quantity": 7,
                "price": "$87.30"
            },
            {
                "id": "74BA864B-376F-F1D0-C6FB-BFDF4EEDBF92",
                "name": "sed, sapien. Nunc",
                "quantity": 9,
                "price": "$46.74"
            },
            {
                "id": "A5B42FFF-CA1A-C40E-26AC-0ED6503791BE",
                "name": "congue a, aliquet",
                "quantity": 8,
                "price": "$77.19"
            },
            {
                "id": "8E5C1332-21F4-62D5-7BCF-8BAC306702DD",
                "name": "nec tempus mauris",
                "quantity": 2,
                "price": "$84.65"
            },
            {
                "id": "5CAE2E28-B772-02EE-55F1-3C723363372B",
                "name": "iaculis nec, eleifend",
                "quantity": 14,
                "price": "$96.61"
            },
            {
                "id": "A2B55FEE-16BA-EA49-35FF-8D29B04E041A",
                "name": "lectus sit amet",
                "quantity": 6,
                "price": "$28.48"
            }
        ],
        "files": [
            {
                "id": "76684834-876E-1E99-EFC4-F4A3C88E9D39",
                "file_name": "varius.",
                "created_at": "23.8.2023",
                "note": "urna suscipit nonummy. Fusce fermentum fermentum arcu. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae Phasellus ornare. Fusce mollis."
            },
            {
                "id": "DC76A55C-E622-784D-6C0D-360CED944575",
                "file_name": "mollis.",
                "created_at": "17.11.2023",
                "note": "taciti sociosqu ad litora torquent per conubia nostra, per inceptos hymenaeos. Mauris ut quam vel sapien imperdiet ornare. In faucibus."
            },
            {
                "id": "4E4D5C8F-BF43-50A1-9649-B312C2E0E6D9",
                "file_name": "vehicula",
                "created_at": "29.3.2023",
                "note": "pharetra sed,"
            },
            {
                "id": "16AEE9D5-2432-3EC0-AEBB-EDDF3F8E9381",
                "file_name": "elit,",
                "created_at": "23.1.2024",
                "note": "Phasellus in felis. Nulla tempor augue ac ipsum. Phasellus vitae mauris sit amet lorem semper auctor."
            },
            {
                "id": "FB899D84-EBF6-6057-8FA2-17991CD75C34",
                "file_name": "penatibus",
                "created_at": "11.11.2023",
                "note": "dictum augue malesuada malesuada. Integer id magna et ipsum cursus vestibulum. Mauris magna."
            },
            {
                "id": "9F6493BD-872C-0BDB-209B-CC5A40F6807D",
                "file_name": "Sed",
                "created_at": "14.12.2023",
                "note": "ridiculus mus. Donec dignissim magna a tortor. Nunc commodo auctor"
            },
            {
                "id": "57AE999C-494A-9E7A-0149-7B82C2190258",
                "file_name": "sodales.",
                "created_at": "6.10.2023",
                "note": "Donec non justo. Proin non massa non ante"
            },
            {
                "id": "916743BA-38F3-4084-C192-EA42C62485A9",
                "file_name": "a",
                "created_at": "27.2.2023",
                "note": "commodo hendrerit."
            },
            {
                "id": "CD42ED21-2CB1-C5B9-8CB2-5DCA47DD72D7",
                "file_name": "nec",
                "created_at": "19.8.2023",
                "note": "eu, ultrices sit amet, risus. Donec nibh enim, gravida sit amet, dapibus id, blandit"
            },
            {
                "id": "F0E22F0A-17C2-D4AA-82C8-1B93FDD1B58B",
                "file_name": "et",
                "created_at": "13.7.2023",
                "note": "enim. Mauris quis turpis vitae purus gravida sagittis."
            },
            {
                "id": "9B834872-2D9F-C4E3-F2C7-C0DA025CA538",
                "file_name": "erat",
                "created_at": "16.12.2023",
                "note": "nisi. Mauris nulla. Integer urna."
            },
            {
                "id": "C8A5BDA6-E5F6-28EA-5BAE-AA2E649D4B64",
                "file_name": "ut,",
                "created_at": "31.1.2024",
                "note": "Mauris vel turpis. Aliquam adipiscing lobortis risus. In mi pede, nonummy ut, molestie in, tempus eu,"
            },
            {
                "id": "03BBBEB5-21D4-66AA-E6EE-6F374B68912B",
                "file_name": "sem,",
                "created_at": "12.1.2024",
                "note": "ante, iaculis nec, eleifend non, dapibus rutrum, justo. Praesent luctus. Curabitur egestas nunc sed"
            },
            {
                "id": "6FBA3707-23BD-37AA-31B6-2A1164A1E753",
                "file_name": "ac,",
                "created_at": "31.10.2023",
                "note": "neque pellentesque massa lobortis ultrices. Vivamus rhoncus. Donec est. Nunc ullamcorper, velit in"
            },
            {
                "id": "BBCF7DB7-8893-A9DE-985A-56BE8D32646B",
                "file_name": "mauris.",
                "created_at": "17.12.2023",
                "note": "luctus ut, pellentesque eget, dictum placerat, augue. Sed molestie. Sed id risus quis diam luctus lobortis. Class aptent taciti sociosqu ad litora torquent"
            }
        ]
    },
    {
        "id": 9,
        "status": "new",
        "quantity": 8,
        "price": "$165.99",
        "start_date": "19.2.2023",
        "items": [
            {
                "id": "5C59688D-81D0-8BB9-5554-53406B1A4635",
                "name": "ullamcorper magna. Sed",
                "quantity": 7,
                "price": "$76.74"
            },
            {
                "id": "E6764408-C8CD-8484-331C-34D112E05352",
                "name": "in, dolor. Fusce",
                "quantity": 7,
                "price": "$68.74"
            },
            {
                "id": "BF5C3E92-6835-2C5F-3D11-79D134082887",
                "name": "nunc. In at",
                "quantity": 9,
                "price": "$27.77"
            },
            {
                "id": "D2E6355C-7F16-DBC7-51A3-65A4D43AB9C2",
                "name": "erat vitae risus.",
                "quantity": 11,
                "price": "$59.80"
            },
            {
                "id": "87382741-6DD7-FC8E-9D2D-722DA3575BAC",
                "name": "arcu imperdiet ullamcorper.",
                "quantity": 7,
                "price": "$87.30"
            },
            {
                "id": "74BA864B-376F-F1D0-C6FB-BFDF4EEDBF92",
                "name": "sed, sapien. Nunc",
                "quantity": 9,
                "price": "$46.74"
            },
            {
                "id": "A5B42FFF-CA1A-C40E-26AC-0ED6503791BE",
                "name": "congue a, aliquet",
                "quantity": 8,
                "price": "$77.19"
            },
            {
                "id": "8E5C1332-21F4-62D5-7BCF-8BAC306702DD",
                "name": "nec tempus mauris",
                "quantity": 2,
                "price": "$84.65"
            },
            {
                "id": "5CAE2E28-B772-02EE-55F1-3C723363372B",
                "name": "iaculis nec, eleifend",
                "quantity": 14,
                "price": "$96.61"
            },
            {
                "id": "A2B55FEE-16BA-EA49-35FF-8D29B04E041A",
                "name": "lectus sit amet",
                "quantity": 6,
                "price": "$28.48"
            }
        ],
        "files": [
            {
                "id": "76684834-876E-1E99-EFC4-F4A3C88E9D39",
                "file_name": "varius.",
                "created_at": "23.8.2023",
                "note": "urna suscipit nonummy. Fusce fermentum fermentum arcu. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae Phasellus ornare. Fusce mollis."
            },
            {
                "id": "DC76A55C-E622-784D-6C0D-360CED944575",
                "file_name": "mollis.",
                "created_at": "17.11.2023",
                "note": "taciti sociosqu ad litora torquent per conubia nostra, per inceptos hymenaeos. Mauris ut quam vel sapien imperdiet ornare. In faucibus."
            },
            {
                "id": "4E4D5C8F-BF43-50A1-9649-B312C2E0E6D9",
                "file_name": "vehicula",
                "created_at": "29.3.2023",
                "note": "pharetra sed,"
            },
            {
                "id": "16AEE9D5-2432-3EC0-AEBB-EDDF3F8E9381",
                "file_name": "elit,",
                "created_at": "23.1.2024",
                "note": "Phasellus in felis. Nulla tempor augue ac ipsum. Phasellus vitae mauris sit amet lorem semper auctor."
            },
            {
                "id": "FB899D84-EBF6-6057-8FA2-17991CD75C34",
                "file_name": "penatibus",
                "created_at": "11.11.2023",
                "note": "dictum augue malesuada malesuada. Integer id magna et ipsum cursus vestibulum. Mauris magna."
            },
            {
                "id": "9F6493BD-872C-0BDB-209B-CC5A40F6807D",
                "file_name": "Sed",
                "created_at": "14.12.2023",
                "note": "ridiculus mus. Donec dignissim magna a tortor. Nunc commodo auctor"
            },
            {
                "id": "57AE999C-494A-9E7A-0149-7B82C2190258",
                "file_name": "sodales.",
                "created_at": "6.10.2023",
                "note": "Donec non justo. Proin non massa non ante"
            },
            {
                "id": "916743BA-38F3-4084-C192-EA42C62485A9",
                "file_name": "a",
                "created_at": "27.2.2023",
                "note": "commodo hendrerit."
            },
            {
                "id": "CD42ED21-2CB1-C5B9-8CB2-5DCA47DD72D7",
                "file_name": "nec",
                "created_at": "19.8.2023",
                "note": "eu, ultrices sit amet, risus. Donec nibh enim, gravida sit amet, dapibus id, blandit"
            },
            {
                "id": "F0E22F0A-17C2-D4AA-82C8-1B93FDD1B58B",
                "file_name": "et",
                "created_at": "13.7.2023",
                "note": "enim. Mauris quis turpis vitae purus gravida sagittis."
            },
            {
                "id": "9B834872-2D9F-C4E3-F2C7-C0DA025CA538",
                "file_name": "erat",
                "created_at": "16.12.2023",
                "note": "nisi. Mauris nulla. Integer urna."
            },
            {
                "id": "C8A5BDA6-E5F6-28EA-5BAE-AA2E649D4B64",
                "file_name": "ut,",
                "created_at": "31.1.2024",
                "note": "Mauris vel turpis. Aliquam adipiscing lobortis risus. In mi pede, nonummy ut, molestie in, tempus eu,"
            },
            {
                "id": "03BBBEB5-21D4-66AA-E6EE-6F374B68912B",
                "file_name": "sem,",
                "created_at": "12.1.2024",
                "note": "ante, iaculis nec, eleifend non, dapibus rutrum, justo. Praesent luctus. Curabitur egestas nunc sed"
            },
            {
                "id": "6FBA3707-23BD-37AA-31B6-2A1164A1E753",
                "file_name": "ac,",
                "created_at": "31.10.2023",
                "note": "neque pellentesque massa lobortis ultrices. Vivamus rhoncus. Donec est. Nunc ullamcorper, velit in"
            },
            {
                "id": "BBCF7DB7-8893-A9DE-985A-56BE8D32646B",
                "file_name": "mauris.",
                "created_at": "17.12.2023",
                "note": "luctus ut, pellentesque eget, dictum placerat, augue. Sed molestie. Sed id risus quis diam luctus lobortis. Class aptent taciti sociosqu ad litora torquent"
            }
        ]
    },
    {
        "id": 10,
        "status": "inwork",
        "quantity": 14,
        "price": "$624.66",
        "start_date": "17.10.2024",
        "items": [
            {
                "id": "5C59688D-81D0-8BB9-5554-53406B1A4635",
                "name": "ullamcorper magna. Sed",
                "quantity": 7,
                "price": "$76.74"
            },
            {
                "id": "E6764408-C8CD-8484-331C-34D112E05352",
                "name": "in, dolor. Fusce",
                "quantity": 7,
                "price": "$68.74"
            },
            {
                "id": "BF5C3E92-6835-2C5F-3D11-79D134082887",
                "name": "nunc. In at",
                "quantity": 9,
                "price": "$27.77"
            },
            {
                "id": "D2E6355C-7F16-DBC7-51A3-65A4D43AB9C2",
                "name": "erat vitae risus.",
                "quantity": 11,
                "price": "$59.80"
            },
            {
                "id": "87382741-6DD7-FC8E-9D2D-722DA3575BAC",
                "name": "arcu imperdiet ullamcorper.",
                "quantity": 7,
                "price": "$87.30"
            },
            {
                "id": "74BA864B-376F-F1D0-C6FB-BFDF4EEDBF92",
                "name": "sed, sapien. Nunc",
                "quantity": 9,
                "price": "$46.74"
            },
            {
                "id": "A5B42FFF-CA1A-C40E-26AC-0ED6503791BE",
                "name": "congue a, aliquet",
                "quantity": 8,
                "price": "$77.19"
            },
            {
                "id": "8E5C1332-21F4-62D5-7BCF-8BAC306702DD",
                "name": "nec tempus mauris",
                "quantity": 2,
                "price": "$84.65"
            },
            {
                "id": "5CAE2E28-B772-02EE-55F1-3C723363372B",
                "name": "iaculis nec, eleifend",
                "quantity": 14,
                "price": "$96.61"
            },
            {
                "id": "A2B55FEE-16BA-EA49-35FF-8D29B04E041A",
                "name": "lectus sit amet",
                "quantity": 6,
                "price": "$28.48"
            }
        ],
        "files": [
            {
                "id": "76684834-876E-1E99-EFC4-F4A3C88E9D39",
                "file_name": "varius.",
                "created_at": "23.8.2023",
                "note": "urna suscipit nonummy. Fusce fermentum fermentum arcu. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae Phasellus ornare. Fusce mollis."
            },
            {
                "id": "DC76A55C-E622-784D-6C0D-360CED944575",
                "file_name": "mollis.",
                "created_at": "17.11.2023",
                "note": "taciti sociosqu ad litora torquent per conubia nostra, per inceptos hymenaeos. Mauris ut quam vel sapien imperdiet ornare. In faucibus."
            },
            {
                "id": "4E4D5C8F-BF43-50A1-9649-B312C2E0E6D9",
                "file_name": "vehicula",
                "created_at": "29.3.2023",
                "note": "pharetra sed,"
            },
            {
                "id": "16AEE9D5-2432-3EC0-AEBB-EDDF3F8E9381",
                "file_name": "elit,",
                "created_at": "23.1.2024",
                "note": "Phasellus in felis. Nulla tempor augue ac ipsum. Phasellus vitae mauris sit amet lorem semper auctor."
            },
            {
                "id": "FB899D84-EBF6-6057-8FA2-17991CD75C34",
                "file_name": "penatibus",
                "created_at": "11.11.2023",
                "note": "dictum augue malesuada malesuada. Integer id magna et ipsum cursus vestibulum. Mauris magna."
            },
            {
                "id": "9F6493BD-872C-0BDB-209B-CC5A40F6807D",
                "file_name": "Sed",
                "created_at": "14.12.2023",
                "note": "ridiculus mus. Donec dignissim magna a tortor. Nunc commodo auctor"
            },
            {
                "id": "57AE999C-494A-9E7A-0149-7B82C2190258",
                "file_name": "sodales.",
                "created_at": "6.10.2023",
                "note": "Donec non justo. Proin non massa non ante"
            },
            {
                "id": "916743BA-38F3-4084-C192-EA42C62485A9",
                "file_name": "a",
                "created_at": "27.2.2023",
                "note": "commodo hendrerit."
            },
            {
                "id": "CD42ED21-2CB1-C5B9-8CB2-5DCA47DD72D7",
                "file_name": "nec",
                "created_at": "19.8.2023",
                "note": "eu, ultrices sit amet, risus. Donec nibh enim, gravida sit amet, dapibus id, blandit"
            },
            {
                "id": "F0E22F0A-17C2-D4AA-82C8-1B93FDD1B58B",
                "file_name": "et",
                "created_at": "13.7.2023",
                "note": "enim. Mauris quis turpis vitae purus gravida sagittis."
            },
            {
                "id": "9B834872-2D9F-C4E3-F2C7-C0DA025CA538",
                "file_name": "erat",
                "created_at": "16.12.2023",
                "note": "nisi. Mauris nulla. Integer urna."
            },
            {
                "id": "C8A5BDA6-E5F6-28EA-5BAE-AA2E649D4B64",
                "file_name": "ut,",
                "created_at": "31.1.2024",
                "note": "Mauris vel turpis. Aliquam adipiscing lobortis risus. In mi pede, nonummy ut, molestie in, tempus eu,"
            },
            {
                "id": "03BBBEB5-21D4-66AA-E6EE-6F374B68912B",
                "file_name": "sem,",
                "created_at": "12.1.2024",
                "note": "ante, iaculis nec, eleifend non, dapibus rutrum, justo. Praesent luctus. Curabitur egestas nunc sed"
            },
            {
                "id": "6FBA3707-23BD-37AA-31B6-2A1164A1E753",
                "file_name": "ac,",
                "created_at": "31.10.2023",
                "note": "neque pellentesque massa lobortis ultrices. Vivamus rhoncus. Donec est. Nunc ullamcorper, velit in"
            },
            {
                "id": "BBCF7DB7-8893-A9DE-985A-56BE8D32646B",
                "file_name": "mauris.",
                "created_at": "17.12.2023",
                "note": "luctus ut, pellentesque eget, dictum placerat, augue. Sed molestie. Sed id risus quis diam luctus lobortis. Class aptent taciti sociosqu ad litora torquent"
            }
        ]
    }
]