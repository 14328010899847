const caseLabels = {
    case: "Обращение",
    cases: "Обращения",
    updateCases: "Обновить обращения",
    closeCase: "Закрыть обращение",
    closeCaseConfirm: "Вы уверены, что хотите закрыть обращение?",
    newCase: "Новое обращение",

    caseSubject: "Тема",
    caseDescription: "Описание",

    noCases: "Обращений пока нет, но вы можете создать их!",
    noFilteredCases: "Ничего не найдено",
    detailView: "Перейти в полную форму",

    newCaseStatus: "новое",
    completedCaseStatus: "закрыто",
    inworkCaseStatus: "в работе", 
}

export default caseLabels