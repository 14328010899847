import { Button, Form } from 'react-bootstrap'
import { ChangeEvent, FormEvent, useContext, useEffect, useState } from 'react'

import { Pa321Context, Pa321DispatchContext } from '../../../../services/pa321Service/pa321Service'
import { LanguageContext } from '../../../../language/LanguageContext'

type FormErrorsType = {
    file?: string[],
    note?: string[],
}

type FileUploadFormProps = {
    caseId: number,
    messageId?: string,
}

export default function FileUploadForm({ caseId, messageId }: FileUploadFormProps) {

    const { t } = useContext(LanguageContext)

    const { uploadFile } = useContext(Pa321DispatchContext)
    const state = useContext(Pa321Context)
    const { status, formErrors } = state.file

    const [validated, setValidated] = useState<boolean>(false)

    const [file, setFile] = useState<File>()
    const [note, setNote] = useState<string>('')

    const noteMinLength = 200

    const onSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        let form = event.currentTarget
        if (form.checkValidity() && file) {
            setValidated(false)
            const formData = new FormData()
            formData.append('file', file)
            formData.append('filename', file.name)
            formData.append('note', note)
            if (messageId) formData.append('commentId', messageId)
            uploadFile(caseId, formData)
        } else {
            setValidated(true)
        }
    }

    useEffect(() => {
        if (status === "success") {
            clearForm()
        }
    }, [status])

    const onSelectFile = (event: ChangeEvent<HTMLInputElement>) => {
        const { files } = event.target
        const selectedFiles = files as FileList
        setFile(selectedFiles?.[0])
    }

    const clearForm = () => {
        setFile(undefined)
        setNote('')
    }

    return (
        <Form noValidate validated={validated} onSubmit={onSubmit}>
            <Form.Group className="mb-3" controlId="file">
                <Form.Control
                    name="file"
                    required
                    size="lg"
                    type="file"
                    onChange={onSelectFile}
                    isInvalid={(formErrors && formErrors.file) ? true : false} />
                <Form.Control.Feedback type="invalid">
                    {formErrors?.file?.map((e: string) => t(e)).join("; ") || t("fileRequired")}
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="note" >
                <Form.Control
                    name="note"
                    type="text"
                    placeholder={t("note")}
                    value={note}
                    maxLength={noteMinLength}
                    onChange={e => setNote(e.target.value)}
                    isInvalid={(formErrors && formErrors.note) ? true : false} />
                <Form.Control.Feedback type="invalid">
                    {formErrors?.note?.map((e: string) => t(e)).join("; ") || t("minimum_length_is", noteMinLength)}
                </Form.Control.Feedback>
            </Form.Group>
            <Button variant="success" title={t("upload")} type="submit" disabled={status === "loading"} >
                {t("upload")}
            </Button>
        </Form>
    )
}