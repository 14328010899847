export const sortByDate = (arr:any, dateField:string, sort:string = "asc") => {
    if(!arr) return
    return arr.sort((a:any, b:any) => {
        const ad: Date = new Date(a[dateField])
        const bd: Date = new Date(b[dateField])
        if (sort === "asc")
            return +ad - +bd
        else
            return +bd - +ad
    })
}

export const dateToString = (date: string|undefined) => {
    if(!date) return
    const d = new Date(date)
    if(d.toString()!=="Invalid Date")
        return d.toLocaleString()
    else {
        //todo to log or smwr else
        return date    
    }
}