import { Modal } from 'react-bootstrap'
import { MouseEvent, useContext, useEffect, useState } from 'react'

import { stubOrders } from '../stubOrders'
import { OrderType } from '../OrderType'
import ItemsList from '../items/list'
import Slash from '../../../pageElements/Slash/slash'
import Files from '../../files/modal'
import { LanguageContext } from '../../../../language/LanguageContext'

export default function Order({ id }: { id: number }) {

    const { t } = useContext(LanguageContext)
    
    const [isOpened, setIsOpened] = useState<boolean>(false)
    const [order, setOrder] = useState<OrderType | undefined>(undefined)

    useEffect(() => {
        if (isOpened) {
            updateOrder()
        } else {
            setOrder(undefined)
        }
    }, [isOpened, id])


    const updateOrder = () => { //todo
        let founded = false
        stubOrders.forEach((order: OrderType) => {
            if (order.id === id) {
                founded = true
                setOrder(order)
            }
        })
        if (!founded) setOrder(stubOrders[0])
    }

    const view = () => {
        if (order) {
            const { id, status, start_date, quantity, price, files, items } = order
            return (
                <Modal.Body>
                    <div className="order" >
                        <div className="order-header">
                            <div className="status">
                                <div className={"ellipse-status " + status} />
                                <div>{t(status+"OrderStatus")}</div>
                            </div><Slash />
                            <div className="fw-bold">{id}</div><Slash />
                            <div>{t("total_quantity")}: <span className="fw-bold">{quantity}</span></div><Slash />
                            <div>{t("total_price")}: <span className="fw-bold">{price}</span></div><Slash />
                            <div className="fst-italic muted">{start_date}</div>
                            {files && files.length > 0 && <><Slash /><Files active={true} files={files} text={` - ${t("order")} #${id}`} /></>}
                        </div>
                        <div className="order-body">
                            <div className="items">
                                <div className="status text-compact">{t("goods")}</div>
                                <ItemsList orderId={id} items={items} />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            )
        } else return <></>
    }

    return (
        <>
            <button 
                className="related-link" 
                onClick={(e: MouseEvent<HTMLButtonElement>) => {
                    e.stopPropagation()
                    setIsOpened(true)
                }} 
                title={t("order")+" #"+id+""} 
            >{id}</button>
            <Modal show={isOpened} onHide={() => setIsOpened(false)} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        {t("order")} #{order?.id}
                    </Modal.Title>
                </Modal.Header>
                {view()}
            </Modal>
        </>

    )
}