import { createContext, useCallback, useContext, useMemo, useReducer } from 'react'

import reducer from './reducer'
import useHttp from '../../hooks/useHttp'
import { sortByDate } from '../../helpers/dateHelpers'
import { MessageType } from '../../components/pa321/messages/MessageType'
import { UserType } from '../../components/pa321/menu/user/UserType'
import { CaseType } from '../../components/pa321/cases/CaseType'
import { ChildrenAsProps } from '../../ChildrenAsProps'
import { AuthContext } from '../../Auth'

type FormErrorsType = any //todo
type StatusType = "idle" | "loading" | "success" | "error"

type StateDataType<T> = {
    data: T,
    status: StatusType,
}
type StateFormType = {
    formErrors?: FormErrorsType,
    status: StatusType,
}

type Pa321DispatchContextType = {
    login: (email: string, password: string) => void,
    register: (firstName: string, lastName: string, email: string, password: string) => void,
    getUser: () => void,
    getCases: () => void,
    getCase: (id: number) => void,
    getMessages: (caseId: number) => void,
    listenMessages: (caseId: number) => void,
    createCase: (subject: string, description: string) => void,
    closeCase: (id: number) => void,
    createMessage: (message: string, caseId: number) => void,
    uploadFile: (caseId: number, formData: FormData) => void,
    downloadFile: (id: string) => Promise<Blob>,
    resetStatus: (key: keyof Pa321ContextType) => void
}

type Pa321ContextType = {
    error: string | undefined,
    login: StateFormType,
    registration: StateFormType,
    user: StateDataType<UserType|undefined>,
    cases: StateDataType<CaseType[]|[]>,
    caseItem: StateDataType<CaseType|undefined>,
    caseForm: StateFormType,
    messages: StateDataType<MessageType[]|[]>,
    messageForm: StateFormType
    file: StateFormType,
}


export const Pa321DispatchContext = createContext<Pa321DispatchContextType>({} as Pa321DispatchContextType)
export const Pa321Context = createContext<Pa321ContextType>({} as Pa321ContextType)

export function ServiceProvider({ children }: ChildrenAsProps) {

    const arrayInitValue: StateDataType<any[]> = {data: [], status: "loading"} 
    const itemInitValue: StateDataType<any> = {data: undefined, status: "loading"} 
    const formInitValue: StateFormType = {formErrors: undefined, status: "idle"}
    
    const initialState: Pa321ContextType =
    {
        error: undefined,
        login: formInitValue,
        registration: formInitValue,
        user: itemInitValue,
        cases: arrayInitValue,
        caseItem: itemInitValue,
        caseForm: formInitValue,
        messages: arrayInitValue,
        messageForm: formInitValue,
        file: formInitValue,
    }

    const [state, dispatch] = useReducer(reducer, initialState)

    const _baseUrl = process.env.REACT_APP_BACKEND_URL

    const { onLogin } = useContext(AuthContext)
    const { error, request, download } = useHttp()

    const register = async (firstName: string, lastName: string, email: string, password: string) => {
        dispatch({ type: "SET_STATUS", payload: { key: "registration", status: "loading" } })
        await request(`${_baseUrl}/register`, "POST", JSON.stringify({ firstName, lastName, email, password })).then((data: any) => {
            if ("token" in data) {
                dispatch({ type: "SET_FORM_ERRORS", payload: { key: "registration", formErrors: undefined } })
                onLogin(data.token)
            } else {
                dispatch({ type: "SET_FORM_ERRORS", payload: { key: "registration", formErrors: data } })
            }
        })
    }

    const login = async (email: string, password: string) => {
        dispatch({ type: "SET_STATUS", payload: { key: "login", status: "loading" } })
        await request(`${_baseUrl}/login`, "POST", JSON.stringify({ email, password })).then((data: any) => {
            if ("token" in data) {
                dispatch({ type: "SET_FORM_ERRORS", payload: { key: "login", formErrors: undefined } })
                onLogin(data.token)
            } else {
                dispatch({ type: "SET_FORM_ERRORS", payload: { key: "login", formErrors: data } })
            }
        })
    }

    const getUser = async () => {
        dispatch({ type: "SET_STATUS", payload: { key: "user", status: "loading" } })
        await request(`${_baseUrl}/user`).then((data: UserType) => {
            dispatch({ type: "SET", payload: { key: "user", data: data } })
        })

    }

    const getCases = async () => {
        dispatch({ type: "SET_STATUS", payload: { key: "cases", status: "loading" } })
        await request(`${_baseUrl}/cases`).then((data: CaseType[]) => {
            const formattedData = sortByDate(data, "start_date", "desc").map((item: CaseType) => {
                item.files = sortByDate(item.files, "created_at", "desc")
                return item
            })
            dispatch({ type: "SET", payload: { key: "cases", data: formattedData } })
        })
    }

    const getCase = async (id: number) => {
        if (typeof id !== "number") { //todo
            dispatch({ type: "SET", payload: { key: "caseItem", data: undefined }})
            return
        }
        dispatch({ type: "SET_STATUS", payload: { key: "caseItem", status: "loading" } })
        await request(`${_baseUrl}/case/${id}`).then((data: CaseType) => {
            const formattedData = data
            formattedData.files = sortByDate(formattedData.files, "created_at", "desc")
            dispatch({ type: "SET", payload: { key: "caseItem", data: formattedData } })
        })
    }

    const getMessages = async (caseId: number) => {
        dispatch({ type: "SET_STATUS", payload: { key: "messages", status: "loading" } })
        await request(`${_baseUrl}/messages?caseId=${caseId}`).then((data: MessageType[]) => {
            const formattedData = sortByDate(data, "created_at").map((item: MessageType) => {
                if (typeof item.files !== "undefined") item.files = sortByDate(item.files, "created_at", "desc")
                return item
            })
            dispatch({ type: "SET", payload: { key: "messages", data: formattedData } })
        })
    }

    const listenMessages = useCallback(async (caseId: number) => {
        await request(`${_baseUrl}/messages?caseId=${caseId}`).then((data: MessageType[]) => {
            const formattedData = sortByDate(data, "created_at").map((item: MessageType) => {
                if (typeof item.files !== "undefined") item.files = sortByDate(item.files, "created_at", "desc")
                return item
            })
            dispatch({ type: "UPDATE", payload: { key: "messages", data: formattedData } })
        })
    },[])

    const createMessage = useCallback(async (message: string, caseId: number) => {
        dispatch({ type: "SET_STATUS", payload: { key: "messageForm", status: "loading" } })
        await request(`${_baseUrl}/message`, "POST", JSON.stringify({ message, caseId })).then((data: any) => { //todo form errors
            dispatch({ type: "SET_FORM_ERRORS", payload: { key: "messageForm", formErrors: data } })
        })
    }, [])

    const createCase = async (subject: string, description: string) => {
        dispatch({ type: "SET_STATUS", payload: { key: "caseForm", status: "loading" } })
        await request(`${_baseUrl}/case`, "POST", JSON.stringify({ subject, description })).then((data: any) => { //todo form errors)
            dispatch({ type: "SET_FORM_ERRORS", payload: { key: "caseForm", formErrors: data } })
        })
    }

    const closeCase = async (id: number) => {
        dispatch({ type: "SET_STATUS", payload: { key: "caseItem", status: "loading" } })
        await request(`${_baseUrl}/case/close`, "POST", JSON.stringify({ id })).then((data: any) => {
            dispatch({ type: "SET_STATUS", payload: { key: "caseItem", status: "idle" } })
            getCase(id)
        })
    }

    const uploadFile = async (caseId: number, formData: FormData) => {
        const headers = {
            'Accept': 'application/json',
        }
        dispatch({ type: "SET_STATUS", payload: { key: "file", status: "loading" } })
        await request(`${_baseUrl}/files/upload?caseId=${caseId}`, "POST", formData, headers).then((data: any) => {
            dispatch({ type: "SET_FORM_ERRORS", payload: { key: "file", formErrors: data } })
        })
    }

    const downloadFile = async (id: string) => {
        dispatch({ type: "SET_STATUS", payload: { key: "file", status: "loading" } })
        return await download(`${_baseUrl}/files/download?fileId=${id}`, "POST", JSON.stringify({ "fileId": id })).finally(() => {
            dispatch({ type: "SET_STATUS", payload: { key: "file", status: "idle" } })
        })
    }

    const resetStatus = useCallback((key: keyof Pa321ContextType) => {
        dispatch({ type: "SET_STATUS", payload: { key: key, status: "idle" } })
    },[])

    const actions: Pa321DispatchContextType = useMemo(() => ({
        login,
        register,
        getUser,
        getCases,
        getCase,
        getMessages,
        listenMessages,
        createCase,
        closeCase,
        createMessage,
        uploadFile,
        downloadFile,
        resetStatus,
    }), [])

    return (
        <Pa321Context.Provider value={{...state, ...{error}}}>
            <Pa321DispatchContext.Provider value={actions}>
                {children}
            </Pa321DispatchContext.Provider>
        </Pa321Context.Provider>
    )
}