import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { FormEvent, useContext, useEffect, useState } from 'react'

import { Pa321Context, Pa321DispatchContext } from '../../../../services/pa321Service/pa321Service'
import { LanguageContext } from '../../../../language/LanguageContext'

type FormErrorsType = {
    subject?: string[],
    description?: string[],
}

type CaseFormProps = {
    orderId?: number,
}

export default function CaseForm({ orderId }: CaseFormProps) {

    const { t } = useContext(LanguageContext)

    const { createCase } = useContext(Pa321DispatchContext)
    const { caseForm } = useContext(Pa321Context)
    const { status, formErrors } = caseForm
    const [validated, setValidated] = useState<boolean>(false)

    const [subject, setSubject] = useState<string>('')
    const [description, setDescription] = useState<string>('')

    const subjectMinLength = 5
    const descriptionMinLength = 5

    const onSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        let form = event.currentTarget
        if (form.checkValidity()) {
            setValidated(false)
            createCase(subject, description) //todo orderId
        } else {
            setValidated(true)
        }
    }

    useEffect(() => {
        if (status === "success") {
            clearForm()
        }
    }, [status])

    const clearForm = () => {
        setSubject('')
        setDescription('')
    }

    return (
        <Form noValidate validated={validated} onSubmit={onSubmit}>
            <Form.Group className="mb-3" controlId="subject">
                <Form.Control
                    name="subject"
                    required
                    size="lg"
                    type="text"
                    minLength={subjectMinLength}
                    placeholder={t("caseSubject") + "*"}
                    value={subject}
                    onChange={e => setSubject(e.target.value)}
                    title={t("caseSubject")}
                    autoFocus
                    isInvalid={(formErrors && formErrors.subject) ? true : false}
                />
                <Form.Control.Feedback type="invalid">
                    {formErrors?.subject?.map((e: string) => t(e)).join("; ") || t("minimum_length_is", subjectMinLength)}
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="description" >
                <Form.Control
                    name="description"
                    required
                    as="textarea"
                    minLength={descriptionMinLength}
                    style={{ height: '150px' }}
                    placeholder={t("caseDescription") + "*"}
                    value={description}
                    onChange={e => setDescription(e.target.value)}
                    title={t("caseDescription")}
                    isInvalid={(formErrors && formErrors.description) ? true : false}
                />
                <Form.Control.Feedback type="invalid">
                    {formErrors?.description?.map((e: string) => t(e)).join("; ") || t("minimum_length_is", descriptionMinLength)}
                </Form.Control.Feedback>
            </Form.Group>
            <Button variant="success" type="submit" title={t("create")} disabled={status === "loading"}>
                {t("create")}
            </Button>
        </Form>
    )
}