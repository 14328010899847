import { useEffect, useState } from 'react'

import { VendorType } from '../VendorType'
import vendorsStub from '../vendorsStub'
import Product from '../../products/views/shortView'
import { ProductType } from '../../products/ProductType'
import productsStub from '../../products/productsStub'
import Loader, { LoaderProps } from '../../../../pageElements/Loader/Loader'
import useParamsNumber from '../../../../../hooks/useParamsNumber'
import useFreeSpace from '../../../../../hooks/useFreeSpace'
import useChangeView from '../../../../../hooks/useChangeView/useChangeView'

export default function Vendor() {
    const { id, parseError } = useParamsNumber()

    const [loading, setLoading] = useState(true)
    const { view, SelectView } = useChangeView()
    const { containerRef, innerRefs, getFreeSpace, getListStyle } = useFreeSpace()

    const vendor: VendorType = vendorsStub.find(vendor => vendor.id === id.toString()) || vendorsStub[0]

    const products: ProductType[] = productsStub

    useEffect(() => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
        }, 2000)
    }, [id])

    useEffect(() => {
        getFreeSpace()
    }, [id, loading, view])

    const loaderProps: LoaderProps = view === "tile" ? { variant: "tile" } : { elements: [1, 1, 1, 1, 1, 1], variant: "fat" }

    return (
        <div className="page-with-products" ref={containerRef}>
            <div className="header" ref={el => innerRefs.current[0] = el}>
                <div className="vendor-info">
                    <div className="image">
                        <img src={vendor.image} alt={vendor.name} />
                    </div>
                    <div className="details">
                        <h3>#{id} {vendor.name}</h3>
                        <div className="description">{vendor.description}</div>
                    </div>
                </div>
                <div className="header-toolbar"><SelectView /></div>
            </div>
            <div className={`products ${view}s`} style={getListStyle()}>
                {loading ? <Loader {...loaderProps} /> :
                    products.map((product: ProductType) => <Product key={product.id} product={product} view={view} />)}
            </div>
        </div>
    )
}