import { useCallback, useContext, useEffect, useLayoutEffect, useRef, useState } from 'react'

import Message from './view'
import NewIcon from './newIcon'
import { MessageType } from './MessageType'
import MessageForm from './form'
import Loader from '../../pageElements/Loader/Loader'
import { Pa321Context, Pa321DispatchContext } from '../../../services/pa321Service/pa321Service'
import { LanguageContext } from '../../../language/LanguageContext'
import useFreeSpace from '../../../hooks/useFreeSpace'

type MessagesListProps = {
    active: boolean,
    caseId: number,
    caseMessages?: MessageType[],
    readonly?: boolean,
    freeSpace?: number
}

export default function MessagesList({ active, caseId, readonly = true, freeSpace }: MessagesListProps) {

    const { t } = useContext(LanguageContext)

    const { getMessages, listenMessages } = useContext(Pa321DispatchContext)
    const { messages } = useContext(Pa321Context)
    const { data, status } = messages

    const [localFreeSpace, setLocalFreeSpace] = useState<number | undefined>(undefined)
    const { getListStyle } = useFreeSpace()

    const form = useRef<HTMLDivElement | null>(null)
    const list = useRef<HTMLDivElement | null>(null)

    const [currentDataLength, setCurrentDataLength] = useState<number>(0)
    const [currentListScrollHeight , setCurrentListScrollHeight] = useState<number>(0)
    const [showNewItem, setShowNewItem] = useState<boolean>(false)

    const scrollDown = useCallback(() => {
        list?.current?.scrollTo({ top: list.current.scrollHeight, behavior: "smooth" })
    }, [])

    const updateInterval: number = process.env.REACT_APP_UPD_INTERVAL_S ? parseInt(process.env.REACT_APP_UPD_INTERVAL_S) * 1000 : 15000

    useEffect(() => {
        if (active) {
            getMessages(caseId)
            if (!readonly) {
                const interval = setInterval(() => {
                    listenMessages(caseId)
                }, updateInterval);

                return () => clearInterval(interval)
            }
        }
    }, [active, readonly])


    useEffect(() => {
        if (currentDataLength === 0) { //first render
            scrollDown()
        } else if (currentDataLength !== data?.length) { //new items arrived            
            if (list.current) {
                const userHaveScrolledUp = list.current.scrollTop < (currentListScrollHeight - list.current.clientHeight)
                const newMsgIsFromApp = data[data?.length - 1].is_for_user
                if(userHaveScrolledUp && newMsgIsFromApp) {
                    setShowNewItem(true)
                } else {
                    scrollDown()
                }            
            }
        } 
        setCurrentDataLength(data?.length || 0)
        setCurrentListScrollHeight(list.current ? list.current.scrollHeight : 0)
    }, [data])

    useLayoutEffect(() => {
        const newLocalFreeSpace = freeSpace ? freeSpace - (form.current ? form.current.offsetHeight : 0) : undefined
        setLocalFreeSpace(newLocalFreeSpace)
    }, [data, freeSpace])

    const onScroll = useCallback(() => {
        if (showNewItem&&list.current) {
            if(list.current.scrollTop === (list.current.scrollHeight - list.current.clientHeight)){
                setShowNewItem(false)
            }
        }
    }, [showNewItem])

    return (
        <>
            <div ref={list} className="messages-list" style={getListStyle(localFreeSpace)} onScroll={onScroll} >
                {(status==="loading") ? <Loader elements={[1, 1, 1, 1, 1, 1]} /> : data?.map((item: MessageType) =>
                    <Message
                        key={item.id} {...item}
                        caseId={caseId}
                        readonly={readonly}
                    />
                )}
                {showNewItem && <div className="new-message"><button className="new-message-button" onClick={scrollDown} title={t("scrollDown")}><NewIcon /></button></div>}
            </div>
            {!readonly && <div ref={form} ><MessageForm caseId={caseId} /></div>}
        </>
    )
}