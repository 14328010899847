export default function PlusIcon() {
  return (
    <svg
      height="2em"
      viewBox="0 0 32 32"
      width="2em"
      xmlns="http://www.w3.org/2000/svg">
      <path
        className="path-stroke-colored"
        d="M16 22.6666V9.33325M9.33342 16L22.6667 16"
        strokeLinecap="round" strokeWidth="2" />
    </svg>

  )
}