import { ChangeEvent, useContext, useEffect } from 'react'

import { FilterContext, FilterDispatchContext } from '../FilterProvider'
import { LanguageContext } from '../../../../language/LanguageContext'

export default function FieldFilter({name}: {name:string}) {

    const { t } = useContext(LanguageContext)
    const { setFilter } = useContext(FilterDispatchContext)
    const { field } = useContext(FilterContext) 

    useEffect(() => {
        if(!field?.find(_ => _.name === name)) setFilter("field", field ? [...field, {name, value: ""}] : [{name, value: ""}])        
    }, [field])

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        setFilter("field", field?.map(_ => _.name === name ? {name, value: e.target.value} : _))
    }
    
    return (
        <div className="filter-row">
            <div className="text-compact">{t(name).toLowerCase()+":"}</div>
            <input 
                type="text" 
                className="form-control form-control-sm"
                value={field?.find((_: { name: string }) => _.name === name)?.value||''} 
                onChange={onChange} />
        </div>
    )
}