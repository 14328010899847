import { useNavigate } from 'react-router-dom'

import { VendorType } from '../VendorType'

export default function Vendor({id, name, description, image}: VendorType) {

    const navigate = useNavigate()

    return (
        <button className="vendor tile" title={name} onClick={() => navigate(`/store/catalog/vendor/${id}`)}>
            <div className="image">
                <img src={image} alt={name} />
            </div>
            <div className="info">
                <div className="fw-bold">{name}</div>
            </div>
        </button>
    )
}