import { useContext, useState } from 'react'
import fileDownload from 'js-file-download'

import { FileType } from './FileType'
import ErrorAlert from '../../errors/errorAlert'
import { Pa321Context, Pa321DispatchContext } from '../../../services/pa321Service/pa321Service'
import { LanguageContext } from '../../../language/LanguageContext'
import { dateToString as d } from '../../../helpers/dateHelpers'

export default function File({ id, file_name, created_at, note }: FileType) {

    const { t } = useContext(LanguageContext)

    const { downloadFile } = useContext(Pa321DispatchContext)
    const { file } = useContext(Pa321Context)
    const { status } = file

    const [error, setError] = useState<string | undefined>()

    const download = () => {
        if (error) return
        downloadFile(id)
            .then(res => {
                fileDownload(res, file_name)
            })
            .catch(e => setError(e.message))
    }

    return (
        <div className="container border-bottom">
            <div className="row">
                <button className="col text-start" disabled={status==="loading"} onClick={download} title={t("downloadFile")}>
                    &darr; {file_name} {error ? <ErrorAlert message={error} /> : null}
                </button>
            </div>
            <div className="row muted">
                <div className="col">{note}</div>
                <div className="col">{d(created_at)}</div>
            </div>
        </div>
    )
}