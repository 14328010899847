import { NavLink } from 'react-router-dom'
import { Offcanvas } from 'react-bootstrap'
import { useContext, useState } from 'react'

import UserCard from './user/userCard'
import Logout from './logout/logout'
import SettingsIcon from './icons/settingsIcon'
import OrderIcon from './icons/orderIcon'
import MarketIcon from './icons/marketIcon'
import ChevronIcon from './icons/chevronIcon'
import CaseIcon from './icons/caseIcon'
import Search from '../../search/search'
import { LanguageContext } from '../../../language/LanguageContext'

export default function Menu() {
    const [show, setShow] = useState(false)

    const handleClose = () => setShow(false)

    const { t } = useContext(LanguageContext)

    const style = show ? undefined : { display: "none" }

    return (

        <div className={"menu " + (!show ? "collapsed" : "")}>
            <div className="d-lg-none">
                <button
                    title={t(show ? "closeMenu" : "openMenu")}
                    onClick={() => setShow(show => !show)}>
                    <ChevronIcon point={show ? "down" : "up"} />
                </button>
                <MenuBody variant="rowed" textStyle={{ display: "none" }} />
            </div>
            <Offcanvas className="menu-offcanvas" show={show} onHide={handleClose} responsive="lg" placement="start">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>{t("menu")}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className="menu-offcanvas-body">
                    <div className="menu-header">
                        <button
                            className="toggle-menu-button"
                            title={t(show ? "closeMenu" : "openMenu")}
                            onClick={() => setShow(show => !show)}>
                            <ChevronIcon point={show ? "left" : "right"} />
                        </button>
                        <UserCard isOpened={show} />
                    </div>
                    <MenuBody variant="columned" textStyle={style} />
                    <div className="menu-footer">
                        {t("site_footer")}
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    )
}


type MenuBodyProps = {
    textStyle?: React.CSSProperties,
    variant?: "columned" | "rowed",
}

function MenuBody({ textStyle, variant="columned" }: MenuBodyProps) {
    const { t } = useContext(LanguageContext)

    return (
        <div className={`menu-body ${variant}`}>
            <Search style={textStyle} />
            <NavLink to="/cases" className="menu-item" title={t("cases")}>
                <CaseIcon />
                <div style={textStyle} >{t("cases")}</div>
            </NavLink>
            <NavLink to="/orders" className="menu-item" title={t("orders")}>
                <OrderIcon />
                <div style={textStyle} >{t("orders")}</div>
            </NavLink>
            <NavLink to="/settings" className="menu-item" title={t("settings")}>
                <SettingsIcon />
                <div style={textStyle} >{t("settings")}</div>
            </NavLink>
            <NavLink to="/store" className="menu-item" title={t("store")}>
                <MarketIcon />
                <div style={textStyle} >{t("store")}</div>
            </NavLink>
            <Logout style={textStyle} />
        </div>
    )
}