export default function FilterIcon() {
  return (
    <svg
      height="2em"
      viewBox="0 0 32 32"
      width="2em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path-fill-colored"
        clipRule="evenodd"
        d="M17.8046 11.6667H5.33325C4.78097 11.6667 4.33325 11.219 4.33325 10.6667C4.33325 10.1144 4.78097 9.66667 5.33325 9.66667H17.8046C18.2399 8.12766 19.6549 7 21.3333 7C23.0116 7 24.4266 8.12766 24.8619 9.66667H26.6666C27.2189 9.66667 27.6666 10.1144 27.6666 10.6667C27.6666 11.219 27.2189 11.6667 26.6666 11.6667H24.8619C24.4266 13.2057 23.0116 14.3333 21.3333 14.3333C19.6549 14.3333 18.2399 13.2057 17.8046 11.6667ZM19.6666 10.6667C19.6666 9.74619 20.4128 9 21.3333 9C22.2537 9 22.9999 9.74619 22.9999 10.6667C22.9999 11.5871 22.2537 12.3333 21.3333 12.3333C20.4128 12.3333 19.6666 11.5871 19.6666 10.6667Z"
        fillRule="evenodd" />
      <path
        className="path-fill-colored"
        clipRule="evenodd"
        d="M14.1952 22.3333H26.6666C27.2189 22.3333 27.6666 21.8856 27.6666 21.3333C27.6666 20.781 27.2189 20.3333 26.6666 20.3333H14.1952C13.7599 18.7943 12.345 17.6667 10.6666 17.6667C8.98822 17.6667 7.57323 18.7943 7.13794 20.3333H5.33325C4.78097 20.3333 4.33325 20.781 4.33325 21.3333C4.33325 21.8856 4.78097 22.3333 5.33325 22.3333H7.13794C7.57323 23.8723 8.98822 25 10.6666 25C12.345 25 13.7599 23.8723 14.1952 22.3333ZM8.99992 21.3333C8.99992 20.4129 9.74611 19.6667 10.6666 19.6667C11.5871 19.6667 12.3333 20.4129 12.3333 21.3333C12.3333 22.2538 11.5871 23 10.6666 23C9.74611 23 8.99992 22.2538 8.99992 21.3333Z"
        fillRule="evenodd" />
    </svg>
  )
}