import { CaseType } from './CaseType'

export const stubCase: CaseType = {
    "id": 109090,
    "status": "new",
    "subject": "THIS IS NOT REAL CASE - subject",
    "start_date": "2024-01-10T06:04:51",
    "description": "THIS IS NOT REAL CASE - description",   
    "order": undefined,
    "files": [
        {
            "id": "e0e6d004-1422-49b1-b067-43194380fa8b",
            "file_name": "not_empty.png",
            "note": "bfvdcsxa",
            "created_at": "2024-01-10T06:05:25"
        },
        {
            "id": "4d9a6cc7-1dfd-4b0b-b6c0-6ade491088cf",
            "file_name": "empty.png",
            "note": "",
            "created_at": "2024-01-10T06:05:50"
        },
        {
            "id": "b0ffb4cd-66ef-4956-923b-ed1121443442",
            "file_name": "not_empty.png",
            "note": "",
            "created_at": "2024-01-10T06:06:16"
        },
        {
            "id": "06bb1229-984c-492d-98fc-4384051d7424",
            "file_name": "not_empty.png",
            "note": "",
            "created_at": "2024-01-10T06:06:26"
        },
    ]
}