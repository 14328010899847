export default function SettingsIcon() {
  return (
    <svg
      height="2rem"
      width="2rem"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.9318 3.44971C15.5962 3.08057 16.4042 3.08057 17.0686 3.44971L26.6686 8.78304C27.367 9.17105 27.8002 9.90722 27.8002 10.7062V21.294C27.8002 22.093 27.367 22.8292 26.6686 23.2172L17.0686 28.5505C16.4042 28.9196 15.5962 28.9196 14.9318 28.5505L5.33178 23.2172C4.63335 22.8291 4.2002 22.093 4.2002 21.294V10.7062C4.2002 9.90722 4.63336 9.17105 5.33178 8.78304L14.9318 3.44971ZM16.0973 5.19802C16.0369 5.16446 15.9635 5.16446 15.9031 5.19802L6.30307 10.5314C6.23957 10.5666 6.2002 10.6336 6.2002 10.7062V21.294C6.2002 21.3667 6.23957 21.4336 6.30307 21.4688L15.9031 26.8022C15.9635 26.8357 16.0369 26.8357 16.0973 26.8022L25.6973 21.4688C25.7608 21.4336 25.8002 21.3667 25.8002 21.294V10.7062C25.8002 10.6336 25.7608 10.5666 25.6973 10.5314L16.0973 5.19802Z"
      />
      <path
        d="M9.66686 16.0001C9.66686 12.5023 12.5024 9.66677 16.0002 9.66677C19.498 9.66677 22.3335 12.5023 22.3335 16.0001C22.3335 19.4979 19.498 22.3334 16.0002 22.3334C12.5024 22.3334 9.66686 19.4979 9.66686 16.0001ZM16.0002 11.6668C13.607 11.6668 11.6669 13.6069 11.6669 16.0001C11.6669 18.3933 13.607 20.3334 16.0002 20.3334C18.3934 20.3334 20.3335 18.3933 20.3335 16.0001C20.3335 13.6069 18.3934 11.6668 16.0002 11.6668Z"
      />
    </svg>
  )
}