import { createContext, useCallback, useReducer } from 'react'

import reducer, { PayloadType } from './reducer'
import { DateFilterType, FieldFilterType, FilterProps, FilterType } from './FilterType'
import { OrderType } from '../orders/OrderType'
import { CaseType } from '../cases/CaseType'
import { ChildrenAsProps } from '../../../ChildrenAsProps'

export type AllowedFilterTypes = CaseType | OrderType


type FilterDispatchType = {
    setFilter: (key: keyof FilterType<AllowedFilterTypes>, value: any) => void,
    resetFilter: () => void,
    applyFilter: () => void,
    filterApplied: () => void,
    filterData: (item: AllowedFilterTypes) => boolean,
    saveFilterSettings: (key: FilterProps["entity"]) => void,
    restoreFilterSettings: (key: FilterProps["entity"]) => void,
    getStoredFilter: (key: FilterProps["entity"]) => FilterType<AllowedFilterTypes>,
}

export const FilterContext = createContext<FilterType<AllowedFilterTypes>>({} as FilterType<AllowedFilterTypes>)
export const FilterDispatchContext = createContext<FilterDispatchType>({} as FilterDispatchType)

export default function FilterProvider({ children }: ChildrenAsProps) {

    const initState: FilterType<AllowedFilterTypes> = {
        process: "idle",
    }
    const [state, dispatch] = useReducer(reducer, initState)

    const setFilter = useCallback((key: keyof FilterType<AllowedFilterTypes>, value: any) => {
        dispatch({ type: "SET", payload: { key, value } })
    }, [])

    const resetFilter = useCallback(() => {
        const resetState = {
            process: "apply",
            status: undefined,
            field: undefined,
            date: undefined,
        } as FilterType<AllowedFilterTypes>
        dispatch({ type: "RESET", payload: {} as PayloadType, resetState })
    }, [])

    const applyFilter = useCallback(() => {
        dispatch({ type: "SET", payload: { key: "process", value: "apply" } })
    }, [])

    const filterApplied = useCallback(() => {
        dispatch({ type: "SET", payload: { key: "process", value: "idle" } })
    }, [])

    const filterData = useCallback((item: AllowedFilterTypes) => {
        let result: boolean = true
        if (state.status && state.status.length > 0) {
            result = state.status.indexOf(item.status) !== -1
        }
        if (!result) return result
        if (state.field && state.field.length > 0) {
            const field: FieldFilterType<typeof item>[] = state.field
            field.forEach((_: FieldFilterType<typeof item>) => {
                if (Object.keys(item).indexOf(_.name) !== -1 && _.value.length > 0) {
                    result = result && (item[_.name] == _.value)
                }
            })
        }
        if (!result) return result
        if (state.date && state.date.length > 0) {
            const date: DateFilterType<typeof item>[] = state.date
            date.forEach((_: DateFilterType<typeof item>) => {
                if ((_.from || _.to) && Object.keys(item).indexOf(_.name) !== -1 && item[_.name] !== undefined) {
                    const itemDate: Date = new Date((item[_.name] as string).split("T")[0])
                    if (_.from) {
                        result = result && (itemDate >= new Date(_.from))
                    }
                    if (_.to) {
                        result = result && (itemDate <= new Date(_.to))
                    }
                }
            })
        }
        return result
    }, [state])

    const saveFilterSettings = useCallback((key: FilterProps["entity"]) => {
        localStorage.setItem("filter_" + key, JSON.stringify(state))
    }, [state])

    const restoreFilterSettings = useCallback((key: FilterProps["entity"]) => {
        const filter = JSON.parse(localStorage.getItem("filter_" + key) || "{}")
        dispatch({ type: "RESET", payload: {} as PayloadType, resetState: { ...filter, process: "idle" } })
    }, [])

    const getStoredFilter = useCallback((key: FilterProps["entity"]) => {
        return JSON.parse(localStorage.getItem("filter_" + key) || "{}")
    }, [])

    const actions = {
        setFilter,
        resetFilter,
        applyFilter,
        filterApplied,
        filterData,
        saveFilterSettings,
        restoreFilterSettings,
        getStoredFilter,
    }

    return (
        <FilterContext.Provider value={state}>
            <FilterDispatchContext.Provider value={actions}>
                {children}
            </FilterDispatchContext.Provider>
        </FilterContext.Provider>
    )
}