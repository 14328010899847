import './style.scss'

import { OverlayTrigger, Popover } from 'react-bootstrap'
import { useContext, useEffect, useState } from 'react'

import SaveIcon from './icons/saveIcon'
import FilterIcon from './icons/filterIcon'
import { areEqual } from './helpers/objectHelpers'
import { FilterProps } from './FilterType'
import StatusFilter from './filters/status'
import FieldFilter from './filters/field'
import DateFilter from './filters/date'
import { FilterContext, FilterDispatchContext } from './FilterProvider'
import { LanguageContext } from '../../../language/LanguageContext'
import { OrderStatuses } from '../orders/OrderType'
import { CaseStatuses } from '../cases/CaseType'

type BtnProps = {
    title?: string
    onClick?: () => void,
    disabled?: boolean,
    className?: string,
}

export function Filter({ entity }: FilterProps) {

    const { t } = useContext(LanguageContext)
    const state = useContext(FilterContext)
    const { applyFilter, resetFilter, saveFilterSettings, restoreFilterSettings, getStoredFilter } = useContext(FilterDispatchContext)


    const enabledBtnProps: BtnProps = {
        title: t("saveFilter"), 
        onClick: () => {
            saveFilterSettings(entity)
            setBtnProps(disabledBtnProps)
        }
    }
    const disabledBtnProps: BtnProps = {
        disabled: true,
        className:"filter-action-button-disabled"
    } 
    const [btnProps, setBtnProps] = useState<BtnProps>(disabledBtnProps)

    useEffect(() => {
        restoreFilterSettings(entity)
    }, [])

    useEffect(() => {
        setBtnProps((areEqual(getStoredFilter(entity), state)) ? disabledBtnProps : enabledBtnProps)
    }, [state])

    const filters = () => {
        switch (entity) {
            case "cases":
                return (
                    <>
                        <StatusFilter statuses={Object.keys(CaseStatuses)} />
                        <div className="filter-separator"></div>
                        <FieldFilter name="order" />
                        <div className="filter-separator"></div>
                        <DateFilter name="start_date" />
                    </>
                )
            case "orders":
                return (
                    <>
                        <StatusFilter statuses={Object.keys(OrderStatuses)} />
                    </>
                )
        }
    }    

    return (
        <OverlayTrigger
            trigger="click"
            key="bottom"
            placement="bottom"
            overlay={
                <Popover id={`popover-positioned-bottom`}>
                    <Popover.Header as="h3">
                        <div className="filter-header">
                            <button className="filter-action-button" onClick={applyFilter} title={t("applyFilter")}>{t("applyFilter")}</button>
                            <button {...btnProps} ><SaveIcon /></button> 
                            <button className="filter-action-button" onClick={resetFilter} title={t("resetFilter")}>{t("resetFilter")}</button>
                        </div>
                    </Popover.Header>
                    <Popover.Body>
                        {filters()}
                    </Popover.Body>
                </Popover>
            }
        >
            <button className="panel-item">
                <div>{t("filter")}</div>
                <FilterIcon />
            </button>
        </OverlayTrigger>
    )
}