import { ChangeEvent, useContext, useEffect } from 'react'

import { FilterContext, FilterDispatchContext } from '../FilterProvider'
import { LanguageContext } from '../../../../language/LanguageContext'

export default function DateFilter({ name }: { name: string }) {

    const { t } = useContext(LanguageContext)
    const { setFilter } = useContext(FilterDispatchContext)
    const { date } = useContext(FilterContext)

    useEffect(() => {
        if (!date?.find(_ => _.name === name)) setFilter("date", date ? [...date, { name, from: "", to: "" }] : [{ name, from: "", to: "" }])
    }, [date])

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        setFilter("date", date?.map(_ => _.name === name ? { ..._, [e.target.name]: e.target.value } : _))
    }

    return (
        <div className="filter-row">
            <div className="text-compact">{t(name).toLowerCase()}</div>
            <div className="filter-box">
                <div className="filter-input">
                    <div>{t("from") + ":"}</div>
                    <div>
                        <input
                            value={date?.find((_: { name: string }) => _.name === name)?.from || ''}
                            onChange={onChange}
                            type="date"
                            name="from"
                            className="form-control form-control-sm" />
                    </div>
                </div>
                <div className="filter-input">
                    <div>{t("to") + ":"}</div>
                    <div>
                        <input
                            value={date?.find((_: { name: string }) => _.name === name)?.to || ''}
                            onChange={onChange}
                            type="date"
                            name="to"
                            className="form-control form-control-sm" />
                    </div>
                </div>
            </div>
        </div>
    )
}