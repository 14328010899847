import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom'
import { lazy, Suspense, useContext } from 'react'

import SettingsPage from './pages/settings/settingsPage'
import RegistrationPage from './pages/registration/registrationPage'
import Pa321Page from './pages/pa321Page'
import LoginPage from './pages/login/loginPage'
import Store from './pages/storePage'
import { LanguageContext } from './language/LanguageContext'
import FilterProvider from './components/pa321/filter/FilterProvider'
import Vendor from './components/store/catalog/vendors/views/detailView'
import Vendors from './components/store/catalog/vendors/vendors'
import Product from './components/store/catalog/products/views/detailView'
import Category from './components/store/catalog/categories/views/detailView'
import Orders from './components/pa321/orders/orders'
import Case from './components/pa321/cases/views/detailView'
import Cases from './components/pa321/cases/cases'
import UpdateIcon from './components/pa321/icons/updateIcon'
import ErrorModal from './components/errors/errorModal'
import Error from './components/errors/Error'
import { IsGuest, IsUser } from './Auth'
import { StoreProvider } from './services/storeService/storeService'
import Cart from './components/store/cart/cart'

const NotFoundPage = lazy(() => import('./pages/notFound/notFoundPage'))

function App() {

  const router = createBrowserRouter([
    {
      errorElement: <Error />,
      children: [
        {
          path: "*",
          element: <NotFoundPage />,
        },
        {
          path: "/",
          errorElement: <Error />,
          element: <><IsUser><Pa321Page /></IsUser><ErrorModal /></>,
          children: [

            {
              index: true,
              element: <FilterProvider><Cases /></FilterProvider>,
            },
            {
              path: "cases",
              element: <FilterProvider><Cases /></FilterProvider>,
            },
            {
              path: "cases/:id",
              element: <Case />
            },
            {
              path: "orders",
              element: <FilterProvider><Orders /></FilterProvider>,
            },
            {
              path: "settings",
              element: <SettingsPage />,
            },
          ]

        },
        {
          path: "/store",
          element: <StoreProvider><Store /></StoreProvider>,
          children: [
            {
              index: true,
              element: <Vendors />,
            },
            {
              path: "catalog/vendor/:id",
              element: <Vendor />
            },
            {
              path: "catalog/product/:id",
              element: <Product />,
            },
            {
              path: "catalog/category/:id",
              element: <Category />,
            },
            {
              path: "cart",
              element: <Cart />,
            },
            {
              path: "*",
              element: <NotFoundPage />,
            }
          ]
        },

        {
          element: <IsGuest><Outlet /></IsGuest>,
          children: [
            {
              path: "/registration",
              element: <RegistrationPage />,
            },
            {
              path: "/login",
              element: <LoginPage />,
            }
          ]
        }
      ]
    }
  ])

  const { SelectLanguage } = useContext(LanguageContext)

  return (
    <>
      <SelectLanguage />
      <div className="index">
        <Suspense fallback={<Loader />} >
          <RouterProvider router={router} />
        </Suspense>
      </div>
    </>
  )
}

function Loader() {
  return (
    <div className="container single-block adaptive content-centered">
      <div style={{ alignSelf: "center", scale: "5" }}>
        <UpdateIcon className="loading" />
      </div>
    </div>
  )
}

export default App