import loginLabels from "../../pages/login/labels/en"
import registrationLabels from "../../pages/registration/labels/en"
import settingsLabels from "../../pages/settings/labels/en"
import notFoundLabels from "../../pages/notFound/labels/en"
import errorLabels from "../../components/errors/labels/en"

import demoUserLabels from "../../pages/login/demoUser/labels/en"

import menuLabels from "../../components/pa321/menu/labels/en"
import logoutLabels from "../../components/pa321/menu/logout/labels/en"
import searchLabels from "../../components/search/labels/en"
import filterLabels from "../../components/pa321/filter/labels/en"

import caseLabels from "../../components/pa321/cases/labels/en"
import orderLabels from "../../components/pa321/orders/labels/en"
import fileLabels from "../../components/pa321/files/labels/en"
import messageLabels from "../../components/pa321/messages/labels/en"

import categoryLabels from "../../components/store/catalog/categories/labels/en"
import vendorLabels from "../../components/store/catalog/vendors/labels/en"
import cartLabels from "../../components/store/cart/labels/en"

import goBackButtonLabels from "../../components/pageElements/GoBackButton/labels/en"

const en = {
    site_footer: "PA 321",
    loading: "Loading",
    toHome: "Go to Home",

    and: "and",
    none: "none",
    invalidId: "Invalid id",

    new: "Add",
    filter: "Filter",
    update: "Update",
    close: "Close",
    create: "Create",

    store: "Store",
    catalog: "Catalog",

    ...loginLabels,
    ...registrationLabels,
    ...settingsLabels,
    ...notFoundLabels,
    ...errorLabels,
    ...demoUserLabels,

    ...menuLabels,
    ...logoutLabels,
    ...searchLabels,
    ...filterLabels,

    ...caseLabels,
    ...orderLabels,
    ...fileLabels,
    ...messageLabels,

    ...categoryLabels,
    ...vendorLabels,
    ...cartLabels,

    ...goBackButtonLabels,
}

export default en