const fileLabels = {
    files: "Files",
    attachedFiles: "Attached files",
    downloadFile: "Download file",
    viewFiles: "View files",
    attachFile: "Attach file",
    fileRequired: "File is required",
    upload: "Upload",
    note: "Note",
    noFiles: "No files",
}

export default fileLabels