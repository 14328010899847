export default function AttachIcon({ active } : { active: boolean }) {

  const className = `path-fill-${active ? "colored" : "muted"}`

  return (
    <svg
      height="30"
      viewBox="0 0 24 24"
      width="30"
      xmlns="http://www.w3.org/2000/svg">
      <path
        className={className}
        d="M12 21.75C8.27208 21.75 5.25 18.7279 5.25 15L5.25 7C5.25 4.37665 7.37665 2.25 10 2.25C12.6234 2.25 14.75 4.37665 14.75 7L14.75 15.1429C14.75 16.6616 13.5188 17.8929 12 17.8929C10.4812 17.8929 9.25 16.6616 9.25 15.1429L9.25 7.28571C9.25 6.8715 9.58579 6.53571 10 6.53571C10.4142 6.53571 10.75 6.8715 10.75 7.28571L10.75 15.1429C10.75 15.8332 11.3096 16.3929 12 16.3929C12.6904 16.3929 13.25 15.8332 13.25 15.1429L13.25 7C13.25 5.20507 11.7949 3.75 10 3.75C8.20507 3.75 6.75 5.20507 6.75 7L6.75 15C6.75 17.8995 9.10051 20.25 12 20.25C14.8995 20.25 17.25 17.8995 17.25 15V7.28571C17.25 6.8715 17.5858 6.53571 18 6.53571C18.4142 6.53571 18.75 6.8715 18.75 7.28571V15C18.75 18.7279 15.7279 21.75 12 21.75Z"/>
    </svg>
  )
}