import { useState } from 'react'

import { CategoryType } from './CategoryType'
import { useNavigate } from 'react-router-dom'
import categoriesStub from './categoriesStub'

export default function CategoryTree({style, closeOffcanvas}: {style?: React.CSSProperties, closeOffcanvas?: () => void}) {

    const categoriesStub1 = categoriesStub.map((item, i) => ({...item, id: (1000+i).toString()}))
    const categoriesStub2 = categoriesStub.map((item, i) => ({...item, id: (2000+i).toString()}))
    const categories: CategoryType[] = [...categoriesStub, ...categoriesStub1, ...categoriesStub2]

    return (
        <div className="category-tree" style={style}>
            {categories.map(c => <Category key={c.id} category={c} closeOffcanvas={closeOffcanvas} />)}
        </div>
    )
}

type CategoryProps = {
    category: CategoryType,
    closeOffcanvas?: () => void,
}

function Category({category, closeOffcanvas} : CategoryProps) {

    const navigate = useNavigate()
    const [isOpened, setIsOpened] = useState<boolean>(false)

    const onFinalClick = () => {
        if(typeof closeOffcanvas === "function") closeOffcanvas()
        navigate(`/store/catalog/category/${category.id}`)
    }

    if(category.children) {        
        return (
            <div className={`category-parent ${isOpened ? "opened" : ""}`} >
                <div><button onClick={() => setIsOpened(opened => !opened)} title={category.name}>{category.name}{isOpened ?  <>&nbsp;&darr;</> : <>&nbsp;&rarr;</>}</button></div>
                {category.children.map((c: CategoryType) => <Category key={c.id} category={c} />)}
            </div>
        )
    }

    return <div><button onClick={onFinalClick} >{category.name}</button></div>

}