const fileLabels = {
    files: "Файлы",
    attachedFiles: "Прикрепленные файлы",
    downloadFile: "Скачать файл",
    viewFiles: "Просмотр файлов",
    attachFile: "Прикрепить файл",
    fileRequired: "Файл обязателен",
    upload: "Загрузить",
    note: "Примечание",
    noFiles: "Нет загруженных файлов",
}

export default fileLabels