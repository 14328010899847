const orderLabels = {
    order: "Order",
    orders: "Orders",
    cancel: "Cancel",

    newOrderStatus: "new",
    cancelledOrderStatus: "cancelled",
    inworkOrderStatus: "in work", 

    quantity: "quantity",
    total_quantity: "quantity",
    price: "price",
    total_price: "price",
    relatedCases: "relates cases",
    goods: "goods",
    name: "name",
}

export default orderLabels