import { useContext } from 'react'
import { LanguageContext } from '../../language/LanguageContext'
import SearchIcon from './searchIcon'

export default function Search({style}: {style?: React.CSSProperties}) {
    const { t } = useContext(LanguageContext)
    return (
        <div className="menu-item search">
            <SearchIcon />
            <div style={style} >{t("search")}</div>
        </div>
    )
}