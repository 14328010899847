import '../scss/catalog.scss'

import { Outlet } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { useContext } from 'react'

import { LanguageContext } from '../language/LanguageContext'
import Sidebar from '../components/store/sidebar/sidebar'
import GoBackButton from '../components/pageElements/GoBackButton/goBackButton'

export default function Store() {

  const { t } = useContext(LanguageContext)

  return (
    <>
      <Helmet>
        <title>Pa321-{t("catalog")}</title>
      </Helmet>
      <div className="catalogPage">
        <Sidebar />
        <div className="catalog-content">
          <GoBackButton />
          <Outlet />
        </div>
      </div>
    </>
  )
}