const messageLabels = {
    message: "Сообщение",
    messages: "Сообщения",
    toMessage: "К сообщению",
    viewMessages: "Просмотреть сообщения",
    forCase: "Сообщения к обращению",
    send: "Отправить",
    scrollDown: "К новым сообщениям",
}

export default messageLabels