import { ItemType } from './ItemType'

export default function Item({ name, quantity, price }: ItemType) {
    return (
        <div className="table-row">
            <div>{name}</div>
            <div>{quantity}</div>
            <div>{price}</div>
        </div>
    )
}