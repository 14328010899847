import loginLabels from "../../pages/login/labels/ru"
import registrationLabels from "../../pages/registration/labels/ru"
import settingsLabels from "../../pages/settings/labels/ru"
import notFoundLabels from "../../pages/notFound/labels/ru"
import errorLabels from "../../components/errors/labels/ru"

import demoUserLabels from "../../pages/login/demoUser/labels/ru"

import menuLabels from "../../components/pa321/menu/labels/ru"
import logoutLabels from "../../components/pa321/menu/logout/labels/ru"
import searchLabels from "../../components/search/labels/ru"
import filterLabels from "../../components/pa321/filter/labels/ru"

import caseLabels from "../../components/pa321/cases/labels/ru"
import orderLabels from "../../components/pa321/orders/labels/ru"
import fileLabels from "../../components/pa321/files/labels/ru"
import messageLabels from "../../components/pa321/messages/labels/ru"

import categoryLabels from "../../components/store/catalog/categories/labels/ru"
import vendorLabels from "../../components/store/catalog/vendors/labels/ru"  
import cartLabels from "../../components/store/cart/labels/ru"  

import goBackButtonLabels from "../../components/pageElements/GoBackButton/labels/ru"

const ru = {
    site_footer: "ЛК 321",
    loading: "Загрузка",
    toHome: "Перейти на главную",  

    and: "и",
    none: "нет",
    invalidId: "Неправильный идентификатор",
    
    new: "Добавить",
    filter: "Фильтр",
    update: "Обновить",
    close: "Закрыть",
    create: "Создать",

    store: "Магазин",
    catalog: "Каталог",

    ...loginLabels,
    ...registrationLabels,
    ...settingsLabels,
    ...notFoundLabels,
    ...errorLabels,
    ...demoUserLabels,

    ...menuLabels,
    ...logoutLabels,
    ...searchLabels,
    ...filterLabels,

    ...caseLabels,
    ...orderLabels,
    ...fileLabels,
    ...messageLabels,

    ...categoryLabels,
    ...vendorLabels,
    ...cartLabels,
    
    ...goBackButtonLabels, 
} 

export default ru