export default function UpdateIcon({ className }: { className?: string }) {

  const pathStyle = {
    fill: "none",
    strokeWidth: 2
  }

  return (
    <svg
      className={className}
      height="1.8em"
      width="1.8em"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg">

      <path d="M4,12A8,8,0,0,1,18.93,8" className="path-stroke-colored" style={pathStyle} />
      <path d="M20,12A8,8,0,0,1,5.07,16" className="path-stroke-colored" style={pathStyle} />
      <polyline points="14 8 19 8 19 3" className="path-stroke-colored" style={pathStyle} />
      <polyline points="10 16 5 16 5 21" className="path-stroke-colored" style={pathStyle} />
    </svg>
  )
}
