export default function PlaneIcon() {
  return (
    <svg
      height="48"
      viewBox="0 0 48 48"
      width="48"
      xmlns="http://www.w3.org/2000/svg">
      <path
        className="path-fill-colored"
        d="M42.4856 24.8742C42.8031 24.6978 43 24.3632 43 24C43 23.6368 42.8031 23.3022 42.4856 23.1258L37.921 20.5899C28.9279 15.5938 19.4478 11.5298 9.62842 8.4612L8.29827 8.04553C7.99464 7.95064 7.66397 8.00588 7.40767 8.19432C7.15136 8.38275 7 8.68189 7 9.00001V20.5C7 21.0319 7.41632 21.4707 7.94744 21.4986L9.96672 21.6049C14.8899 21.864 19.7831 22.5318 24.5958 23.6012L25.0724 23.7072C25.1784 23.7307 25.2243 23.7741 25.2499 23.8085C25.2821 23.8519 25.3073 23.9188 25.3073 24C25.3073 24.0812 25.2821 24.1481 25.2499 24.1915C25.2243 24.2259 25.1784 24.2693 25.0724 24.2929L24.5958 24.3988C19.7831 25.4683 14.8899 26.136 9.96667 26.3951L7.94744 26.5014C7.41632 26.5293 7 26.9681 7 27.5V39C7 39.3181 7.15136 39.6173 7.40766 39.8057C7.66397 39.9941 7.99464 40.0494 8.29827 39.9545L9.62845 39.5388C19.4478 36.4702 28.9279 32.4062 37.921 27.4101L42.4856 24.8742Z"
      />
    </svg>
  )
}