export default function CrossIcon() {
  return (
    <svg
      height="2em"
      width="2em"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg">
      <path
        className="path-stroke-colored"
        d="M11.286 20.714L20.7141 11.2859M11.286 11.286L20.7141 20.7141"
        strokeLinecap="round"
        strokeWidth="2" />      
    </svg>
  )
}