export default function ChevronIcon({ point, className } : { point: "up"|"down"|"left"|"right", className?: string } ) {
  return (
    <svg
      className={`chevron-${point} ${className}`}
      fill="none"
      height="3rem"
      width="3rem"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M 30 36 L 18 24 L 30 12"
        className="path-stroke-colored"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="4" />
    </svg>
  )
}