import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { useContext } from 'react'

import LoginForm from './form'
import { Pa321Context } from '../../services/pa321Service/pa321Service'
import { LanguageContext } from '../../language/LanguageContext'
import UpdateIcon from '../../components/pa321/icons/updateIcon'

export default function LoginPage() {

    const { t } = useContext(LanguageContext)

    const { login } = useContext(Pa321Context)
    const { status } = login

    return (
        <>
            <Helmet>
                <title>Pa321-{t("login")}</title>
            </Helmet>
            <div className="container single-block">
                <div className="row">
                    <h3>{t("welcome")}{status==="loading" && <UpdateIcon className="loading" />}</h3>
                </div>
                <LoginForm />
                <div className="row mt-3">
                    <Link title={t("registration_link")} to="/registration">{t("registration_link")}</Link>
                </div>
            </div>
        </>
    )
}