import { useNavigate } from 'react-router-dom'
import { useContext } from 'react'

import { LanguageContext } from '../../../language/LanguageContext'

export default function GoBackButton() {
    const { t } = useContext(LanguageContext)
    const navigate = useNavigate()
    return (
        <button id="goBackButton" className="btn btn-link" title={t("goBack")} onClick={() => navigate(-1)} >{t("goBack")}</button>
    )
}