import { useContext } from 'react'

import File from './view'
import { FileType } from './FileType'
import { LanguageContext } from '../../../language/LanguageContext'
import useFreeSpace from '../../../hooks/useFreeSpace'

export default function FilesList({ files, freeSpace }: { files: FileType[], freeSpace?: number }) {
    
    const { t } = useContext(LanguageContext)

    const { getListStyle } = useFreeSpace()
    
    return (
        <div className="files-list" style={getListStyle(freeSpace)} >
            {files.length ? files.map((item: FileType) => <File key={item.id} {...item} />):<div className="alert alert-info">{t("noFiles")}</div>}
        </div>
    )
}