import { useContext, useEffect } from 'react'

import { FilterContext, FilterDispatchContext } from '../FilterProvider'
import { LanguageContext } from '../../../../language/LanguageContext'

type StatusFilterProps = {
    statuses: string[],    
}

export default function StatusFilter({ statuses }: StatusFilterProps) {

    const { t } = useContext(LanguageContext)

    const { setFilter } = useContext(FilterDispatchContext)

    const { status } = useContext(FilterContext) 

    useEffect(() => {
        if(!status || status.length===0) setFilter("status", statuses)        
    }, [status])

    const addStatus = (_status: string) => {
        setFilter("status", status ? [...status, _status] : [_status])
    }
    const removeStatus = (_status: string) => {
        setFilter("status", status ? status.filter(_ => _ !== _status) : [])
    }

    if (statuses.length === 0) return null

    return (
        <div className="filter-row wrapped">
            <div className="text-compact">{t("status") + ":"}</div>
            {statuses.map(_status => {
                const active = status?.indexOf(_status) !== -1
                const onClick = active ? () => removeStatus(_status) : () => addStatus(_status)
                return <button key={_status} className={`filter-button ${active ? "active" : ""}`} onClick={onClick} >{_status}</button>
            })}
        </div>
    )
}