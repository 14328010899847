import { useCallback, useContext, useState } from 'react'

import { LanguageContext } from '../language/LanguageContext'
import { AuthContext } from '../Auth'

 type PostResponseType = {
    status?: number,
    success?: boolean,
    data?: any, //todo?  mb UserType|MessageType etc
    errors?: any, //todo?
    message?: string,
}

export default function useHttp() {

    const [error, setError] = useState<string | undefined>(undefined)

    const { language } = useContext(LanguageContext)
    const { getToken, onLogout } = useContext(AuthContext)

    const defaultHeaders: () => HeadersInit = () => ({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Lang': `Lang ${language}`,
    })

    const checkIsAuthorized = (res: PostResponseType) => {
        if (res.status === 401) {
            onLogout()
            window.location.reload()
        }
    }

    const request = useCallback(async (url: string, method: string = 'GET', body: BodyInit | undefined = undefined, headers: any = defaultHeaders()) => {
        setError(undefined)

        const authHeader = {"Authorization": `Bearer ${getToken()}`}

        try {
            const response = await fetch(url, { method, body, headers: {...headers, ...authHeader} })
            if (!response.ok) {
                console.log("response", response) //todo - to log
                checkIsAuthorized(response)
                throw new Error(`status${response.status}`)
            }
            const result = await response.json()
            if (!result.success) {
                switch (result.status) {
                    case 1: //formErrors
                        let formErrors: any = {} //todo
                        for (let e of result.errors) {
                            for (let key in e) {
                                if (!formErrors[key]) {
                                    formErrors[key] = []
                                }
                                formErrors[key].push(e[key])
                            }
                        }
                        return formErrors
                    default:
                        //setError(result.message)
                        throw new Error(result.message)
                }

            }
            return result.data || undefined
        } catch (e: Response | any) {
            setError(e.message)
            throw e
        } 
    }, [])

    const download = useCallback(async (url: string, method: string = 'GET', body: BodyInit | undefined = undefined, headers: any = defaultHeaders()) => {
        let res = await fetch(url, { method, body, headers })
        if (!res.ok) {
            checkIsAuthorized(res)
            throw new Error("loadFileError")
        }
        return await res.blob()
    }, [])

    const clearError = useCallback(() => setError(undefined), [])

    return {
        error,
        request,
        download,
        clearError,
    }
}