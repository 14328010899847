import { useContext, useEffect, useState } from 'react'

import { Case } from './views/shortView'
import CasesPanel from './panel'
import { CaseType } from './CaseType'
import Loader from '../../pageElements/Loader/Loader'
import { Pa321Context, Pa321DispatchContext } from '../../../services/pa321Service/pa321Service'
import { LanguageContext } from '../../../language/LanguageContext'
import useFreeSpace from '../../../hooks/useFreeSpace'
import { FilterContext, FilterDispatchContext } from '../filter/FilterProvider'

export default function Cases() {
    const { t } = useContext(LanguageContext)

    const { getCases } = useContext(Pa321DispatchContext)
    const { cases } = useContext(Pa321Context)
    const { data, status } = cases
    const loading = status === "loading"
    const { containerRef, innerRefs, getFreeSpace, getListStyle } = useFreeSpace()

    const { filterApplied, filterData } = useContext(FilterDispatchContext)
    const { process } = useContext(FilterContext)
    const [filterredData, setFilterredData] = useState<CaseType[]>(data)

    const [stubData, setStubData] = useState<CaseType[]>(data) // todo remove stubData

    const applyStubOrders = (d: CaseType[]) => { //todo remove
        return d.map(_ => {
            if (_.order === undefined) {
                return { ..._, order: _.id % 10 }
            }
            return _
        })
    }

    useEffect(() => {
        getCases()
    }, [])

    useEffect(() => {
        setStubData(applyStubOrders(data))
        //setFilterredData(data) //todo uncomment
        //getFreeSpace()
    }, [data])
    useEffect(() => {    //todo remove
        setFilterredData(stubData)
        getFreeSpace()
    }, [stubData])

    useEffect(() => {
        if (process === "apply") {
            setFilterredData(stubData.filter(filterData))  //todo stubData to data
            filterApplied()
        }
    }, [process, stubData]) //todo stubData to data

    return (
        <div className="cases" ref={containerRef}>
            <CasesPanel ref={el => innerRefs.current[0] = el} loading={loading} total={data.length} />
            {loading ? <Loader elements={[1, 1, 1, 1, 1, 1, 1]} variant="fat" /> :
                (data.length ?
                    (filterredData.length ?
                        <div className="cases-list" style={getListStyle()}>
                            {filterredData.map((item: CaseType) =>
                                <Case key={item.id} {...item} />
                            )}
                        </div> :
                        <div className='alert alert-info'>{t("noFilteredCases")}</div>) :
                    <div className='alert alert-info'>{t("noCases")}</div>)
            }
        </div>
    )
}