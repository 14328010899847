import { Navigate } from 'react-router-dom'
import { createContext, useContext } from 'react'

import LoginPage from './pages/login/loginPage'
import useToken from './hooks/useToken'
import { ChildrenAsProps } from './ChildrenAsProps'

export type AuthContextType = {
  token: string,
  getToken: () => string | null,
  onLogin: (token: string) => void,
  onLogout: () => void,
}
export const AuthContext = createContext<AuthContextType>({} as AuthContextType)

export const AuthProvider = ({ children }: ChildrenAsProps) => {
  const { token, setToken, getToken } = useToken()//todo here was null

  const onLogin = async (token: string) => {
    setToken(token)
  }

  const onLogout = () => {
    setToken(null)
  }

  const value = {
    token,
    getToken,
    onLogin,
    onLogout,
  }

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  )
}

export function IsGuest({ children }: ChildrenAsProps) {
  const auth = useContext(AuthContext)
  if (auth && !auth.token) {
    return <>{children}</>
  }
  return <Navigate to={"/"} />
}

export function IsUser({ children }: ChildrenAsProps) {
  const auth = useContext(AuthContext)
  if (auth && auth.token) {
    return <>{children}</>
  }
  return <IsGuest><LoginPage /></IsGuest>
}