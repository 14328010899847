import { CartType } from "../../components/store/cart/CartType";

export default function reducer(state: any, action: { type: any; payload: CartType }) { //todo
    switch (action.type) {
        case "SET":
            localStorage.setItem("cart", JSON.stringify(action.payload))
            return {
                ...state,
                cart: action.payload
            }
        default:
            throw Error('Unknown action: ' + action.type);
    }
}