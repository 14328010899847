import 'bootstrap/dist/css/bootstrap.min.css'
import './scss/style.scss'

import ReactDOM from 'react-dom/client'
import React from 'react'

import { ServiceProvider } from './services/pa321Service/pa321Service'
import { LanguageProvider } from './language/LanguageContext'
import { AuthProvider } from './Auth'
import App from './App'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <LanguageProvider>
    <AuthProvider>
      <ServiceProvider>
        <App />
      </ServiceProvider>
    </AuthProvider>
  </LanguageProvider>
)