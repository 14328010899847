import { useState } from "react"
import TileIcon from "./icons/tileIcon"
import InlineIcon from "./icons/inlineIcon"

type ViewType = "tile" | "inline"
export default function useChangeView() {


    const getView: () => ViewType = () => {
        const defaultView : ViewType= "tile"
        const _viewJ = localStorage.getItem('view')
        if (_viewJ) {
            const _view = JSON.parse(_viewJ) as ViewType
            return _view ? _view : defaultView
        }
        return defaultView
    }

    const [view, setView] = useState<ViewType>(getView())

    const saveView = (view: "tile" | "inline") => {
        setView(view)
        localStorage.setItem("view", JSON.stringify(view))
    }

    const SelectView = () => {
        return (
            <div className="select-view" style={{display: "flex"}}>
                <button onClick={() => saveView("tile")}><TileIcon className={(view==="tile" ? "active" : "")}/></button>
                <button onClick={() => saveView("inline")}><InlineIcon className={(view==="inline" ? "active" : "")} /></button>
            </div>
        )
    }

    return {
        view,
        SelectView,
    }
}