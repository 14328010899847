import { NavLink } from 'react-router-dom'

import { VendorType } from './VendorType'

export default function VendorLink({id, name}: {id: VendorType["id"], name: VendorType["name"]}) {
    return (
        <div className="vendor-link">
            <NavLink to={`/store/catalog/vendor/${id}`}>{name}</NavLink>
        </div>
    )
}