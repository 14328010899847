export default function OrderIcon() {
  return (
    <svg
      height="2rem"
      width="2rem"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.8666 5.68387L21.9511 5.81893C24.4542 6.13068 26.3332 8.25813 26.3332 10.7806V24.7665C26.3332 27.2157 24.5088 29.2813 22.0784 29.584C18.0416 30.0868 13.9581 30.0868 9.92127 29.584C7.49085 29.2813 5.6665 27.2157 5.6665 24.7665V10.7806C5.6665 8.25812 7.54543 6.13068 10.0486 5.81893L11.133 5.68387C11.5626 4.13607 12.9819 3 14.6665 3H17.3332C19.0178 3 20.4371 4.13607 20.8666 5.68387ZM10.9998 7.71591L10.2957 7.8036C8.79386 7.99065 7.6665 9.26711 7.6665 10.7806V24.7665C7.6665 26.2067 8.73928 27.4214 10.1684 27.5994C14.0411 28.0817 17.9586 28.0817 21.8312 27.5994C23.2604 27.4214 24.3332 26.2067 24.3332 24.7665V10.7806C24.3332 9.26712 23.2058 7.99065 21.7039 7.8036L20.9998 7.71591V9.33333C20.9998 9.88562 20.5521 10.3333 19.9998 10.3333H11.9998C11.4476 10.3333 10.9998 9.88562 10.9998 9.33333V7.71591ZM12.9998 6.66667C12.9998 5.74619 13.746 5 14.6665 5H17.3332C18.2536 5 18.9998 5.74619 18.9998 6.66667V8.33333H12.9998V6.66667Z"
        fillRule="evenodd"
      />
      <path
        d="M20.9998 15.6667C20.9998 15.1144 20.5521 14.6667 19.9998 14.6667H11.9998C11.4476 14.6667 10.9998 15.1144 10.9998 15.6667C10.9998 16.219 11.4476 16.6667 11.9998 16.6667H19.9998C20.5521 16.6667 20.9998 16.219 20.9998 15.6667Z"
      />
      <path
        d="M19.6665 19.6667C19.6665 19.1144 19.2188 18.6667 18.6665 18.6667H11.9998C11.4476 18.6667 10.9998 19.1144 10.9998 19.6667C10.9998 20.219 11.4476 20.6667 11.9998 20.6667H18.6665C19.2188 20.6667 19.6665 20.219 19.6665 19.6667Z"
      />
    </svg>
  )
}