import { useContext, useEffect } from 'react'

import Loader from '../../../pageElements/Loader/Loader'
import { Pa321Context, Pa321DispatchContext } from '../../../../services/pa321Service/pa321Service'

type UserCardProps = {
    isOpened: boolean,
}

export default function UserCard({ isOpened }: UserCardProps) {

    const { getUser } = useContext(Pa321DispatchContext)
    const { user } = useContext(Pa321Context)
    const { data, status } = user

    const style = isOpened ? undefined : { display: "none" }

    useEffect(() => {
        getUser()
    }, [])

    return (
        <div className="user-info">
            <>
                <div className={`ellipse-user ${isOpened ? "scale-07" : ""}`} />
                {status==="loading" ? <Loader elements={[1]} /> : <div className="text-compact" style={style} >{data?.last_name + ' ' + data?.first_name}</div>}
            </>
        </div>
    )
}