import { useState } from 'react'

function useToken() {
    const getToken = () => {
        const tokenString = localStorage.getItem('token')
        if (tokenString) {
            const userToken = JSON.parse(tokenString)
            return userToken ? userToken : null
        }
        return null
    }

    const [token, setToken] = useState(getToken())

    const saveToken = (token?: string | null) => {
        if (token) {
            localStorage.setItem('token', JSON.stringify(token))
            setToken(token)
        } else {
            localStorage.removeItem('token')
            setToken(null)
        }
    }

    return {
        token,
        getToken,
        setToken: saveToken,
    }
}

export default useToken