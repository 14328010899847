const loginLabels = {
    login: "Вход",
    login_link: "Вход",
    welcome: "Добро пожаловать! Войдите в систему",
    password: "Пароль",
    password_required: "Пароль обязателен",
    email: "Электронная почта",
    email_required: "Электронная почта обязательна",
    userNotFound: "Пользователь не найден",
    passwordIncorrect: "Неправильный пароль",
}

export default loginLabels